import { defineStore, storeToRefs } from 'pinia'
import { ref, watch } from 'vue'
import api from "@/use/api";
import { useChatStore } from "./chat";

export const useChatMessagesStore = defineStore('chatMessages', () => {

    const is_loading = ref(true);
    const current_chat_messages = ref([]);
    const sendNewSMSFromMessage = ref({
        customer_id: 0,
        message: ''
    });
    const chatStore = useChatStore();
    const { all_items } = storeToRefs(chatStore);
    const { results, status, sendGetRequest } = api();

    async function loadChatMessagesList() {
        // if (is_loading.value) {
        current_chat_messages.value = [];
        if (chatStore.current_chat_id)
            await sendGetRequest('getChatMessages/' + chatStore.current_chat_id);
        else {
            is_loading.value = false;
        }
        // }
    }

    function getListChatMessages() {
        if (!is_loading.value)
            loadChatMessagesList();
        return current_chat_messages.value;
    }

    function addMessage(item) {
        current_chat_messages.value.push(item);
        chatStore.current_chat.last_message_time_ago = item.time_ago;
        chatStore.current_chat.last_message = item.message;
    }

    function addNewMessageFromNotification(message, chat_id) {
        if (chat_id == chatStore.current_chat_id) {
            current_chat_messages.value.push(message);
            chatStore.current_chat.last_message_time_ago = message.time_ago;
            chatStore.current_chat.last_message = message.message;
            chatStore.current_chat.new_messages_count++;
        } else { // if new message  in  another message
            const chat = all_items.value.find(chat => chat.id == chat_id)
            chat.last_message_time_ago = message.time_ago;
            chat.last_message = message.message;
            chat.new_messages_count++;
        }
    }

    watch(status, (currentStatus) => {
        if (currentStatus) { ///  if  success
            /// if loading chat messages
            current_chat_messages.value = results.value;
            is_loading.value = false;
        }
        window.showHideMainLoader(false);
    })

    // watch(chatStore.current_chat_id, () => {
    //
    //     is_loading.value =true;
    //      loadChatMessagesList();
    //
    //
    // })

    return {
        is_loading,
        addMessage,
        loadChatMessagesList,
        current_chat_messages,
        getListChatMessages,
        addNewMessageFromNotification,
        sendNewSMSFromMessage
    };


})