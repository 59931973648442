<template>

  <!-- setting-note -->
  <div class="setting-note">
    <div class="row">

      <div class="col-md-4 col-xl-4 mb-4">
        <div class="review-goal">
          <p>Review Goal</p>
          <div class="note">
            <p><span>Note :</span></p>
            <p>Input your review target for this month. We Automatically generate a corresponding invite goal to guide
              your team in reaching that target.</p>
          </div>
          <div class="save-goal">
            <div class="row">
              <div class="col-6">
                <div class="numergoal">
                  <div class="form-group">
                    <label for="Goal">Review Goal :</label>
                    <input type="text" class="form-control" id="Goal" placeholder="57">
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="numergoal">
                  <div class="form-group">
                    <label for="Invite">Invite Goal :</label>
                    <a class="invitegol" href="#">87</a>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="save-btn">
                  <button type="submit" class="save">Save Goal</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="col-md-8 col-xl-8 mb-4">
        <div class="review-goal">
          <p>Review Invite with Image</p>
          <div class="intive-cons">
            <p>With this settings turned on, review invitation text messages will be sent with an accompanying
              image</p>

            <button type="button" class="btn btn-toggle" data-toggle="button" aria-pressed="false" autocomplete="off">
              <div class="handle"></div>
            </button>

            <div class="thankyou">
              <div class="thanks-img">
                <ul>
                  <li>
                    <a class="crn" href="#"><img class="img-fluid" src="@/assets/img/thanks1.png"
                                                 alt="image title"></a>
                  </li>
                  <li>
                    <a href="#"><img class="img-fluid" src="@/assets/img/thanks2.png" alt="image title"></a>
                  </li>
                  <li>
                    <a href="#"><img class="img-fluid" src="@/assets/img/thanks3.png" alt="image title"></a>
                  </li>
                  <li>
                    <a href="#"><img class="img-fluid" src="@/assets/img/thanks4.png" alt="image title"></a>
                  </li>
                  <li>
                    <a href="#"><img class="img-fluid" src="@/assets/img/thanks5.png" alt="image title"></a>
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- setting-note -->


  <!-- review-table -->
  <div class="review-table sms-table">
    <p>SMS Content</p>
    <table class="setting-rable" id="dataTable">
      <thead>
      <tr>
        <th>Language</th>
        <th>SMS Content</th>
        <th>Fallback</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>English</td>
        <td>
          <p> Hi [{customer name}]! Thanks for choosing [{location name}].</p>
          <p> Can you leave us a quick review? The link below makes it easy!</p>
        </td>
        <td>
          <p>Hi there! Thanks for choosing South Tampa Dentistry.</p>
          <p> Can you leave us a quick review? The link below makes it easy!</p>
        </td>
        <td>
          <a href="#" data-toggle="modal" data-target="#exampleModal7"><img class="img-fluid"
                                                                            src="@/assets/img/edit.svg"
                                                                            alt="image title"></a>
          <a href="#"><img class="img-fluid" src="@/assets/img/delete.svg" alt="image title"></a>
        </td>
      </tr>
      <tr>
        <td>Spanish</td>
        <td>
          <p> Hi [{customer name}]! Thanks for choosing [{location name}].</p>
          <p> Can you leave us a quick review? The link below makes it easy!</p>
        </td>
        <td>
          <p>Please leave us a quick review by clicking the link below:</p>
        </td>
        <td>
          <a href="#" data-toggle="modal" data-target="#exampleModal7"><img class="img-fluid"
                                                                            src="@/assets/img/edit.svg"
                                                                            alt="image title"></a>
          <a href="#"><img class="img-fluid" src="@/assets/img/delete.svg" alt="image title"></a>
        </td>
      </tr>

      </tbody>
    </table>
  </div>
  <!-- review-table -->


  <!-- setting-note -->
  <div class="setting-note">
    <div class="row">

      <div class="col-md-8 col-xl-8 mb-4">
        <div class="review-goal">
          <p>Review Site Management</p>

          <div class="addsite">
            <div class="row">
              <div class="col-sm-6">
                <div class="addsite-text">
                  <h4>Included in Review Invites</h4>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="addsite-btn">
                  <a href="#"><img class="img-fluid" src="@/assets/img/plus.svg" alt="image title"> Add site</a>
                </div>
              </div>
            </div>
          </div>

          <!-- Monitoring -->
          <div class="monitoring">
            <ul>
              <li>
                <div class="monitor-card">
                  <a href="#"><img class="img-fluid" src="@/assets/img/google-site.svg" alt="image title"></a>
                </div>
                <div class="monitoring-bottom">
                  <p><span class="dot"></span> Monitoring</p>
                  <a href="#">Manage</a>
                </div>
              </li>
              <li>
                <div class="monitor-card">
                  <a href="#"><img class="img-fluid" src="@/assets/img/facebook-site.svg" alt="image title"></a>
                </div>
                <div class="monitoring-bottom">
                  <p><span class="dot"></span> Monitoring</p>
                  <a href="#">Manage</a>
                </div>
              </li>
              <li>
                <div class="monitor-card">
                  <a href="#"><img class="img-fluid" src="@/assets/img/linek-site.svg" alt="image title"></a>
                </div>
                <div class="monitoring-bottom">
                  <p><span class="dot"></span> Monitoring</p>
                  <a href="#">Manage</a>
                </div>
              </li>
              <li>
                <div class="monitor-card">
                  <a href="#"><img class="img-fluid" src="@/assets/img/twitter-site.svg" alt="image title"></a>
                </div>
                <div class="monitoring-bottom">
                  <p><span class="dot"></span> Monitoring</p>
                  <a href="#">Manage</a>
                </div>
              </li>
            </ul>
          </div>
          <!-- Monitoring -->
        </div>
      </div>


      <div class="col-md-4 col-xl-4 mb-4">
        <div class="review-goal">
          <p>Multi-User Mode</p>
          <div class="note">
            <p><span>Note :</span></p>
            <p>Multi-User Mode allows different employees to send from one computer.</p>

            <button type="button" class="btn btn-toggle" data-toggle="button" aria-pressed="false" autocomplete="off">
              <div class="handle"></div>
            </button>
          </div>
          <div class="setting-username">
            <h3>Username : s-46574</h3>
            <p>Log in with the above usernam to access Multi-User Mode</p>
            <a class="change" href="#">Change password?</a>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- setting-note -->

</template>
<script>
import $ from "jquery";
import {onMounted} from "vue";

export default {
  components: {},
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    // Open Modal
    const openModal = () => {
      $("#featureNotAvailable").modal("show");
    };

    // Close Modal
    const closeModal = () => {
      $("#featureNotAvailable").modal("hide");
    };
    const datatableInit = async () => {
      if ($.fn.DataTable.isDataTable("#dataTable")) {
        $("#dataTable").DataTable().clear().destroy();
      }
      $("#dataTable").addClass("nowrap").dataTable({
        order: [],
        columnDefs: [
          {targets: [1], orderable: false}
        ]
      });
    }
    onMounted(() => {
      datatableInit();
      window.showHideMainLoader(false);
    });

    return {openModal, closeModal, base_url};
  },
};
</script>