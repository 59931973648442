<template>

  <!-- search-top -->
  <div class="search-top">
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="search-box">
          <form>
            <div class="form-group">
              <input type="search" class="form-control" placeholder="Search" aria-label="search">
              <img class="img-fluid sea-icon" src="@/assets/img/search.svg" alt="image title">
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-6 col-lg-8">
        <div class="create-meeting">
          <router-link :to="{ name: 'TextCampaign' }"><img class="img-fluid" src="@/assets/img/pl.svg"
              alt="image title"> Back</router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- search-top -->

  <!-- review-table -->
  <div class="review-table">
    <table class="display" id="dataTable">
      <thead>
        <tr>
          <th>#</th>
          <th>Customer Name</th>
          <th>Opt-In Status</th>
          <th>Status</th>
          <th>Error</th>
          <th>Created</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(Item, key) in TextCampaignDetail" :key="Item">
          <td>{{ key+ 1 }}</td>
          <td>{{ Item.first_name+' '+Item.last_name }}</td>
          <td><a class="invites" href="javascript:;">{{ (Item.optin_status == 1)?'Active':'Inactive' }}</a></td>
          <td>{{ Item.message_status }}</td>
          <td>{{ Item.error }}</td>
          <td>{{ Item.created_at }}</td>
          <td>
            <ul class="actionbtn">
              <li><a href="javascript:;" @click="messageDeailPopup(Item)"> View</a></li>
            </ul>
          </td>
        </tr>

      </tbody>
    </table>
  </div>
  <!-- review-table -->
<!-- newroom modal -->
<div class="modal fade newroom-modal" id="messageDeailPopup" tabindex="-1" role="dialog" aria-labelledby="messageDeailPopupLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="chatlist-area modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="messageDeailPopupLabel">SMS Detail</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="@/assets/img/close.svg" class="img-fluid" alt="image title">
                </button>
            </div>
            <div class="modal-body">
                <div class="contact-form">
                    <form>
                        <div class="row">
                            <div class="form-group col-md-12">
                              <p>
                                <span class="locaname">Customer Name: </span>
                                {{ messageDetails.first_name+' '+messageDetails.last_name }}
                              </p>
                              <p>
                                <span class="locaname">Date & Time: </span>
                                {{ messageDetails.datettime }}
                              </p>
                              <p>
                                <span class="locaname">Message Status: </span>
                                {{ messageDetails.status }}
                              </p>
                              <p>
                                <span class="locaname">Message: </span>
                                {{ messageDetails.message }}
                              </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- newroom modal -->
</template>
<style>
.form-group .locaname {
  font-weight: 600;
  font-size: 16px;
}
</style>
<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";

export default {
  components: {

  },
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    const Params = useRoute();
    let TextCampaignDetail = ref([]);
    let messageDetails = ref({
      first_name: '',
      last_name: '',
      message: '',
      datettime: '',
      status: ''
    });

    const getCampaignDetail = async (Id) => {
      const getFormData = new FormData();
      getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
      getFormData.append('location_id', localStorage.getItem('location_id'));
      await axios
        .post(axios.defaults.baseUrl + "campaigns/text/" + Id,
          getFormData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem('auth_token'),
            },
          }
        )
        .then((response) => {
          // console.log(response);
          TextCampaignDetail.value = response.data.data.campaignDetail;
          // datatableInit();
        })
    }

    // Open Modal
    const openModal = () => {
      $("#featureNotAvailable").modal("show");
    };

    // Close Modal
    const closeModal = () => {
      $("#featureNotAvailable").modal("hide");
    };
    const datatableInit = async () => {
      if ($.fn.DataTable.isDataTable("#dataTable")) {
        $("#dataTable").DataTable().clear().destroy();
      }
      $("#dataTable").addClass("nowrap").dataTable({
        order: [],
        columnDefs: [
          { targets: [1], orderable: false }
        ]
      });
    }
    const messageDeailPopup = (Item = null) => {
      messageDetails.value.first_name = Item.first_name;
      messageDetails.value.last_name = Item.last_name;
      messageDetails.value.message = Item.message;
      messageDetails.value.datettime = Item.sent_at;
      messageDetails.value.status = Item.message_status;
      $("#messageDeailPopup").modal("show");
    }
    const getCampaignDetails = async (Id = null) => {
      if ($.fn.DataTable.isDataTable("#dataTable")) {
        $("#dataTable").DataTable().clear().destroy();
      }
      $('#dataTable').DataTable({
          "processing": true,
          "serverSide": true,
          language: {
            emptyTable: "No matching records found",
            lengthMenu: "Show _MENU_ records per page",
            zeroRecords: "",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            infoEmpty: "No records available",
            infoFiltered: "(filtered from _MAX_ total records)",
          },
          "ajax": {
              "url": axios.defaults.baseUrl+"campaigns/text/"+Id+"/dt",
              "type": "POST", // Assuming your API endpoint expects a POST request
              "headers": {
                Authorization: "Bearer " + localStorage.getItem('auth_token'),
              },
              "data": function(d) {
                  // You can add additional parameters or modify the request data here
                  return $.extend({}, d, {
                    'dealer_id' : localStorage.getItem('dealer_id'),
                    'location_id' : localStorage.getItem('location_id'),
                    // 'list_id' : list_id.value,
                  });
              },        
              dataSrc: function(response) {
                // Transform the data here before DataTables processes it
                TextCampaignDetail.value = response.data;
                window.showHideMainLoader(false);
                return [];
              },
          },
          "searching": true,
          "order": [],
          "columns" : [],
      });
      $(".display").addClass("nowrap").dataTable();
    }
    onMounted(() => {
      // getCampaignDetail(Params.params.id);
      // datatableInit();
      getCampaignDetails(Params.params.id);
      // window.showHideMainLoader(false);
    });

    return {
      openModal,
      closeModal,
      base_url,
      TextCampaignDetail,
      getCampaignDetail,
      datatableInit,
      messageDeailPopup,
      getCampaignDetails,
      messageDetails,
    };
  },
};
</script>