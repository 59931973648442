<template>
<!-- search-top -->
<div class="search-top">
    <div class="row">
        <div class="col-md-6 col-lg-4">
            <!-- <div class="search-box">
          <div class="todayselect">
            <select class="form-control" aria-label="agents">
              <option>Select agents</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
        </div> -->
        </div>
        <div class="col-md-6 col-lg-8">
            <div class="export-right">
                <div class="export">
                    <router-link :to="'/admin/clients'">Go Back</router-link>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- search-top -->

<!-- all-task -->
<div class="all-task">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="tasks">
                <h2>Add New Tag</h2>
                <form>
                    <div class="row g-3">
                        <div class="col-md-6">
                            <div class="form-floating">
                                <input type="text" class="form-control" id="Tagname" placeholder="Tag Name" v-model="tag_form.tag_name" @click="removeClass('#Tagname', 'red-border')">
                                <label for="Tagname">Tag Name</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <button type="button" id="register-btn" class="cus-btn" @click="AddTag">Add Tag</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import $ from "jquery";
import {
    ref,
    onMounted
} from "vue";
import axios from "axios";
// import { useRoute } from "vue-router";
// import TwilioTrackingForm from "./../../../components/TwilioTrackingNumbers/TwilioTrackingForm.vue";
// import vueMask from 'vue-jquery-mask';
export default {
    components: {
        // vueMask 
    },
    setup() {
        let base_url = "http://api.streamly.com/uploads/";
        // const Params = useRoute();
        // console.log(Params.params.id);
        let tag_form = ref({
            tag_name: ''
        });
        const AddTag = async () => {
            removeClass('.form-control', 'red-border');
            let validate = 1;
            console.log(validate);
            // console.log(register_form.value);
            if (tag_form.value.tag_name == '') {
                validate = 0;
                addClass('#Tagname', 'red-border');
                showToast('error', 'Tag name is required');
                return false;
            }
            if (validate == 1) {
                let TagFormData = new FormData();
                TagFormData.append('name', tag_form.value.tag_name);
                window.showHideMainLoader(true);
                await axios
                    .post(
                        axios.defaults.baseUrl + "admin/tags/add",
                        TagFormData, {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem('auth_token'),
                            },
                        }
                    )
                    .then((response) => {
                        window.showHideMainLoader(false);
                        showToast(response.data.status, response.data.message);
                        tag_form.value = '';
                        window.location.href = '/admin/tags';
                    })
                    .catch((error) => {
                        window.showHideMainLoader(false);
                        showToast(error.response.data.status, error.response.data.message);
                        return false;
                    });
            }
        }
        const addClass = (elementName, className) => {
            $(elementName).addClass(className);
        }

        const removeClass = (elementName, className) => {
            $(elementName).removeClass(className);
        }
        const showToast = (toastIcon, toastMessage) => {
            window.toast.fire({
                icon: toastIcon,
                title: toastMessage,
            });
        }
        // Open Modal
        const openModal = () => {

        };

        // Close Modal
        const closeModal = () => {

        };
        onMounted(() => {

            // // console.log(Params);
            // datatableInit();
            window.showHideMainLoader(false);
        });

        return {
            openModal,
            closeModal,
            base_url,
            tag_form,
            AddTag,
            removeClass,
            addClass,
            showToast,
        };
    },
};
</script>
