<template>
    <div>
      <!-- 2FA -->
      <section class="login-area">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="form-area">
              <div class="login-form">
                <div class="form-logo">
            <a href="#"><img class="img-fluid" src="@/assets/img/logo.svg" alt="logo"></a>
            <h3>Let’s verify!</h3>
            <p>OTP sent to +1<input type="text" v-model="otpNumber" class="two-factor-number-input" readonly /></p>
        </div>
        <form name="verifyForm" class="verify">
            <div class="inputs">
                <input type="text" name="n1" maxLength="1" class="2fa-code n1" v-model="twoFA.n1" data-no="1" />
                <input type="text" name="n2" maxLength="1" class="2fa-code n2" v-model="twoFA.n2" data-no="2" />
                <input type="text" name="n3" maxLength="1" class="2fa-code n3" v-model="twoFA.n3" data-no="3" />
                <input type="text" name="n4" maxLength="1" class="2fa-code n4" v-model="twoFA.n4" data-no="4" />
                <input type="text" name="n5" maxLength="1" class="2fa-code n5" v-model="twoFA.n5" data-no="5" />
                <input type="text" name="n6" maxLength="1" class="2fa-code n6" v-model="twoFA.n6" data-no="6" />
            </div>
            <button class="login-btns" type="button" @click="twoFactorCheck">Verify </button>
        </form>
        <p class="dont-acc">Didn't receive it? <a href="javascript:;" @click="resendOTP">Resend code </a></p>
        <p class="dont-acc"> <a href="javascript:;" @click="goToNumber">Change Phone number </a></p>
              </div>
              </div>
              </div>
            </div>
          </div>
      </section>
      <!-- login-area -->
    </div>
  </template>
  <style>
  .two-factor-number-input {
    border: none;
    background: transparent;
  }
  </style>
  <script>
  import $ from "jquery";
  import { ref, onMounted } from "vue";
  import axios from "axios";
//   import Loader from "@/components/Loader";
  // import { useRoute } from "vue-router";
  // import vueMask from 'vue-jquery-mask';
  // console.log(localStorage);
  export default {
    components: {},
    setup() {
      let base_url = "http://api.streamly.com/uploads/";
      // const router = useRoute();
      let otpNumber = ref('xxxxxxxxxx');
      let twoFA = ref({
        n1: "",
        n2: "",
        n3: "",
        n4: "",
        n5: "",
        n6: ""
      });
  // console.log(localStorage.getItem('auth_token'));
    $(document).on('keydown', '.2fa-code', function (event) {
        let keycode = (event.keyCode ? event.keyCode : event.which);
        // console.log(keycode);
        let currentIndex = $(this).data('no');
        // console.log(currentIndex);
        if(keycode == 8){
            currentIndex--;
        }else{
            currentIndex++;
        }
        setTimeout(function() {
            $('.n'+currentIndex).focus();
        }, 100);
        
    });
        const twoFactorCheck = async () => {
            window.showHideMainLoader(true);
            // console.log(twoFA.value);
            let twoFAValue = twoFA.value.n1+twoFA.value.n2+twoFA.value.n3+twoFA.value.n4+twoFA.value.n5+twoFA.value.n6;
            // console.log(twoFAValue);
            if (twoFAValue.length < 6) {
                showToast('error','Invalid OTP!');
                return false;
            }
            // console.log('here');
            let otpFormData = new FormData();
            otpFormData.append('otp', twoFAValue);
            otpFormData.append('user_id', localStorage.getItem('user_id'));
            await axios
                .post(
                    axios.defaults.baseUrl + "twoFAcheck",
                    otpFormData
                )
                .then((response) => {
                    // console.log(response.data);
                    window.showHideMainLoader(false);
                    showToast(response.data.status, response.data.message);
                    let auth_token = sessionStorage.getItem('auth_token');
                    // console.log(auth_token);
                    localStorage.setItem("auth_token", auth_token);
                    sessionStorage.removeItem("auth_token");
                    sessionStorage.removeItem("otp_number");
                    // console.log(localStorage.getItem('auth_token'));
                    
                    // console.log(localStorage);
                    location.assign('/inbox/all');
                    // router.push("/inbox/all");
                })
                .catch((error) => {
                    // console.log(error);
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }

      const resendOTP = async () => {
          window.showHideMainLoader(true);
          let otpFormData = new FormData();
          otpFormData.append('user_id', localStorage.getItem('user_id'));
          await axios
              .post(
                  axios.defaults.baseUrl + "twoFAresendOTP",
                  otpFormData
              )
              .then((response) => {
                  window.showHideMainLoader(false);
                  showToast(response.data.status, response.data.message);
              })
              .catch((error) => {
                  // console.log(error);
                  window.showHideMainLoader(false);
                  showToast(error.response.data.status, error.response.data.message);
                  return false;
              });
      }
      const goToNumber = async () => {
        // sessionStorage.removeItem("auth_token");
        // sessionStorage.clear();
        // localStorage.clear();
        // showToast('error','Please Login');
        location.assign('/login/phoneNumber');
        // router.push('/login/phoneNumber');
      }
      const addClass = (elementName, className) => {
        $(elementName).addClass(className);
      }
  
      const removeClass = (elementName, className) => {
        $(elementName).removeClass(className);
      }
      const showToast = (toastIcon, toastMessage) => {
        window.toast.fire({
          icon: toastIcon,
          title: toastMessage,
        });
      }
      if (!sessionStorage.getItem("auth_token")) {
        showToast('error','Please Login');
        sessionStorage.clear();
        localStorage.clear();
        location.assign('/login');
      }
      onMounted(() => {
        otpNumber.value = sessionStorage.getItem('otp_number');
        // this.otpNumber = sessionStorage.getItem('otp_number');
        // console.log(otpNumber.value);
        // datatableInit();
        // getCustomers();
        // sessionStorage.removeItem('auth_token');
        window.showHideMainLoader(false);
      });
  
      return {
        base_url,
        twoFA,
        twoFactorCheck,
        resendOTP,
        removeClass,
        addClass,
        showToast,
        goToNumber,
        otpNumber
      };
    },
  };
  </script>