<template>
  <div>


    <!-- search-top -->
    <div class="search-top">
      <div class="row">
        <!--<div class="col-md-6 col-lg-4">
          <div class="search-box">
            <form>
              <div class="form-group">
                <input type="search" class="form-control" aria-label="search" placeholder="Search">
                <img class="img-fluid sea-icon" src="@/assets/img/search.svg" alt="image title">
              </div>
            </form>
          </div>
        </div>-->
        <!-- <div class="col-md-6 col-lg-4">
          <div class="search-box">
            <form>
              <div class="form-group">
                <select class="form-control" aria-label="list-id" @change="changeListId" id="listId" v-model="listId">
                  <option value="" selected>All Customers</option>
                  <option value="0">Without List</option>
                  <option v-for="listOption in AllCustomersLists" v-bind:key="listOption.id" v-bind:value="listOption.id">
                    {{ listOption.title }}
                  </option>
                </select>
              </div>
            </form>
          </div>
        </div> -->
        <!-- <div class="col-md-6 col-lg-8">
          <div class="export-right">
            <div class="export">
              <a href="#"> <img class="img-fluid" src="@/assets/img/export.svg" alt="image title"> Export</a>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- search-top -->

    <!-- review-table -->
    <div class="review-table">
      <table class="display" id="dataTable">
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Location</th>
            <th>Client</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="Customer in AllCustomers" :key="Customer">
            <td>
              <a class="manname" href="javascript:">
                <img class="img-fluid" v-if="Customer.user_image" :src="base_url + 'user/' + Customer.user_image"
                  alt="User Image">
                <img class="img-fluid" v-else src="@/assets/img/man.png" alt="image title">
                {{ Customer.first_name }} {{ Customer.last_name }}
              </a>
            </td>
            <td>+{{ Customer.country_code }}{{ Customer.phone }}</td>
            <td>{{ Customer.email }}</td>
            <td>{{ Customer.company }}
              <p>{{ Customer.address }}</p>
            </td>
            <td>{{ Customer.client_business_name }}</td>
            <td>
              Status
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- review-table -->
  </div>
</template>
<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import axios from "axios";
// import { useRoute } from "vue-router";
// import vueMask from 'vue-jquery-mask';
// console.log(localStorage);
export default {
  components: {},
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    // const Params = useRoute();
    // let AllCustomersLists = ref([]);
    let AllCustomers = ref([]);
// console.log(localStorage.getItem('auth_token'));

    const getCustomers = async () => {
      await axios
        .get(
          axios.defaults.baseUrl + "admin/customers",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem('auth_token'),
            },
          }
        )
        .then((response) => {
          AllCustomers.value = response.data.data.allCustomers;
          // datatableInit();
        })
        .catch((error) => {
          window.showHideMainLoader(false);
          showToast(error.response.data.status, error.response.data.message);
          return false;
        });
    }
    const addClass = (elementName, className) => {
      $(elementName).addClass(className);
    }

    const removeClass = (elementName, className) => {
      $(elementName).removeClass(className);
    }
    const showToast = (toastIcon, toastMessage) => {
      window.toast.fire({
        icon: toastIcon,
        title: toastMessage,
      });
    }
    
    // Open Modal
    const openModal = () => {
      $("#featureNotAvailable").modal("show");
    };

    // Close Modal
    const closeModal = () => {
      $("#create_contact").modal("hide");
      $("#upload_contacts").modal("hide");
      $("#disply_csv_uploaded_contact").modal("hide");
    };
    const datatableInit = async () => {
      if ($.fn.DataTable.isDataTable("#dataTable")) {
        $("#dataTable").DataTable().clear().destroy();
      }
      $("#dataTable").addClass("nowrap").dataTable({
        order: [],
        columnDefs: [
          { targets: [0], orderable: false },
          { targets: [1], orderable: false },
          { targets: [2], orderable: false },
          { targets: [3], orderable: false },
          { targets: [4], orderable: false },
          { targets: [5], orderable: false }
        ]
      });
      $(".display").addClass("nowrap").dataTable();

    }
    onMounted(() => {

      // console.log(Params);
      datatableInit();
      getCustomers();
      window.showHideMainLoader(false);
    });

    return {
      openModal,
      closeModal,
      base_url,
      AllCustomers,
      removeClass,
      addClass,
      showToast,
    };
  },
};
</script>