import $ from "jquery";

export  const loadInput =() => {


        $(".input").focus(function () {
            $(this).parent().find(".label-txt").addClass("label-active");
            $(this).addClass("input-active");
        });
        $(".input").focusout(function () {
            if ($(this).val() == "") {
                $(this).parent().find(".label-txt").removeClass("label-active");
                $(this).removeClass("input-active");
            }
        })




}

