import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import api from "@/use/api";
import { useChatMessagesStore } from "./chatMessages";


export const useChatStore = defineStore('chat', () => {
    const closed_items = ref([]);
    const items = ref([]);
    const all_items = ref([]);
    const is_loading = ref(true)
    const current_chat_id = ref(null);
    // const current_team_id = ref(null);
    const current_chat = ref(null);
    const chatMessagesStore = useChatMessagesStore();

    // const is_loading = ref(false);
    // const doubleCount = computed(() => count.value * 2)

    const { results, status, sendGetRequest } = api();

    function fillChatList(type = null) {
        // if (type) {
        items.value = all_items.value.filter(item => {
            return !item.is_closed;
        });
        closed_items.value = all_items.value.filter(item => {
            return item.is_closed;
        });
        // }
        console.log(type)
        // return items.value;
    }

    async function loadChatList() {
        if (is_loading.value) {
            await sendGetRequest('getChats');
        }
    }

    watch(status, (currentStatus) => {

        if (currentStatus) { ///  if  success
            all_items.value = results.value;
            fillChatList();
            if (current_chat_id.value)
                current_chat.value = all_items.value.find(chat => chat.id == current_chat_id.value)
        }
        window.showHideMainLoader(false);
    })

    watch(current_chat_id, (new_current_chat_id) => {
        current_chat.value = all_items.value.find(chat => chat.id == new_current_chat_id)
        // is_loading.value =true;
        // loadChatMessagesList()
        chatMessagesStore.loadChatMessagesList()
        chatMessagesStore.is_loading = true
    })


    return {
        items,
        closed_items,
        is_loading,
        loadChatList,
        fillChatList,
        current_chat_id,
        current_chat,
    };


})