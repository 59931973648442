<template>
<!-- <a href="javascript:;" @click="testSMS">SMS Test</a> -->
<!-- search-top -->
<div class="search-top">
    <div class="row">
        <div class="col-md-6 col-lg-4">
            <div class="search-box">
                <form>
                    <div class="form-group">
                        <input type="search" class="form-control" placeholder="Search" aria-label="search">
                        <img class="img-fluid sea-icon" src="@/assets/img/search.svg" alt="image title">
                    </div>
                </form>
            </div>
        </div>
        <div class="col-md-6 col-lg-8">
            <div class="create-meeting">
                <!-- <router-link :to="{ name: 'AddTextCampaign' }"><img class="img-fluid" src="@/assets/img/pl.svg"
              alt="image title"> Add New
            Campaign</router-link> -->
                <a href="/text-campaign/add"><img class="img-fluid" src="@/assets/img/pl.svg" alt="image title"> Add New Campaign</a>
            </div>
        </div>
    </div>
</div>
<!-- search-top -->

<!-- review-table -->
<div class="review-table">
    <table class="display" id="dataTable">
        <thead>
            <tr>
                <th>#</th>
                <th>Title</th>
                <th>Sending To</th>
                <th>Total Recipients</th>
                <th>Sent at</th>
                <th>Status</th>
                <th>Created</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(Campaign, key) in AllTextCampaigns" :key="Campaign">
                <td>{{ key+ 1 }}</td>
                <td v-if="Campaign.is_draft == 0">
                    <router-link :to="{ name: 'TextCampaignDetail', params: { id: Campaign.id } }">{{ Campaign.title }}</router-link>
                </td>
                <td v-else>
                    <router-link :to="{ name: 'TextCampaignDetail', params: { id: Campaign.id } }">{{ Campaign.title }}</router-link>
                </td>
                <td v-if="Campaign.campaign_recipient_type == 'list'">
                    <a class="capitalized" href="javascript:" @click="viewCampaignListDetailModal(Campaign.id)">{{ Campaign.campaign_recipient_type }}</a>
                </td>
                <td v-else-if="Campaign.campaign_recipient_type == 'individual'">
                    <router-link class="capitalized" :to="{ name: 'TextCampaignDetail', params: { id: Campaign.id } }">{{ Campaign.campaign_recipient_type }}</router-link>
                </td>
                <td v-else>-</td>
                <td>{{ Campaign.total_recepients }}</td>
                <td v-if="Campaign.is_draft == 0">{{ Campaign.sent_at }}</td>
                <td v-else>-</td>
                <td v-if="Campaign.is_draft == 0"><a class="invites capitalized" href="javascript:;"> {{ Campaign.campaign_status }}</a></td>
                <td v-else>-</td>
                <td>{{ Campaign.created_on }}</td>
                <td>
                    <ul class="actionbtn">
                        <li v-if="Campaign.is_draft == 1">
                            <a :href="'/text-campaign/edit/'+Campaign.id"> <i class="fa fa-edit"></i> Edit</a>
                        </li>
                        <li v-if="Campaign.is_draft == 0"><a href="javascript:;" @click="viewMessageModal(Campaign)"> View Message</a></li>
                        <li><a href="javascript:;" @click="deleteCampaign(Campaign.id)"> <i class="fa fa-trash"></i></a></li>
                    </ul>
                </td>
            </tr>

        </tbody>
    </table>
</div>
<!-- review-table -->
<!-- view-Modal -->
<div class="modal fade" id="viewmodal" tabindex="-1" aria-labelledby="viewmodal1" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="viewmodal1">Campaign Message</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="img-fluid" src="@/assets/img/close.png" alt="image title">
                </button>
            </div>
            <div class="modal-body">
                <div class="view-campain" v-if="CampaignImage">
                    <h3>Image</h3>
                    <div class="view-campaign-image">
                        <img class="img-fluid" :src="base_url + 'template/' + CampaignImagePath" alt="image title" />
                    </div>
                </div>
                <div class="view-campain">
                    <h3>Message</h3>
                    <div class="view-templates" v-html="CampaignMessage" style="height: 200px; margin: 15px; padding: 25px; line-height: 35px;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- view-Modal -->
<!-- view-List-Modal -->
<div class="modal fade" id="viewCampaignListModal" tabindex="-1" aria-labelledby="viewmodal1" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="viewmodal1"> Campaign Lists</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="img-fluid" src="@/assets/img/close.png" alt="image title">
                </button>
            </div>
            <div class="modal-body">
                <div class="view-campain">
                    <table class="table table-striped">
                        <tr v-for="(List, key) in AllTextCampaignsListDetail" :key="key">
                            <td>
                                <a :href="'/customers/'+List.customer_list.id">
                                    {{ List.customer_list.title }}
                                </a>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- view-List-Modal -->
</template>

<style>
.capitalized {
    text-transform: capitalize;
}

.view-campaign-image {
    width: 150px;
    border: 5px solid #f1f1f1;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow: 1px 1px 1px 1px #333;
}
</style>

<script>
import $ from "jquery";
import {
    ref,
    onMounted
} from "vue";
import axios from "axios";

export default {
    components: {

    },
    setup() {
        let base_url = "https://api.getstreamly.com/uploads/";
        let AllTextCampaigns = ref([]);
        let AllTextCampaignsListDetail = ref([]);
        let CampaignMessage = ref('');
        let CampaignImage = ref(0);
        let CampaignImagePath = ref('');
        /*
            const getCampaignList = async () => {
              const getFormData = new FormData();
              getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
              getFormData.append('location_id', localStorage.getItem('location_id'));
              await axios
                .post(axios.defaults.baseUrl + "campaigns/text",
                  getFormData,
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem('auth_token'),
                    },
                  }
                )
                .then((response) => {
                  AllTextCampaigns.value = response.data.data.allCampaigns;
                  setTimeout(() => {
                    datatableInit();
                  }, 500);
                })
            }
        */
        const testSMS = async () => {
            await axios
                .get(axios.defaults.baseUrl + "test_sms", {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem('auth_token'),
                    },
                })
                .then((response) => {
                    console.log(response);
                })
        }
        const deleteCampaign = async (Id = null) => {
            window.showHideMainLoader(true);
            // if(confirm('Are you sure?')){
            await axios
                .delete(axios.defaults.baseUrl + "deletecampaign/text/" + Id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem('auth_token'),
                    },
                })
                .then((response) => {
                    console.log(response);
                    window.showHideMainLoader(false);
                    getAllCampaigns();
                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    window.toast.fire({
                        icon: 'error',
                        title: error.response.data.message,
                    });
                    return false;
                });
            // }

        }
        const viewMessageModal = async (Campaign = null) => {
            // console.log(Campaign);
            CampaignImage.value = Campaign.is_image;
            CampaignImagePath.value = Campaign.campaign_image;
            CampaignMessage.value = Campaign.campaign_template;
            $('#viewmodal').modal('show');
        }
        const viewCampaignListDetailModal = async (Id = null) => {
            await axios
                .get(axios.defaults.baseUrl + "campaigns/text/list-detail/" + Id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem('auth_token'),
                    },
                })
                .then((response) => {
                    AllTextCampaignsListDetail.value = response.data.data.allCampaignsListDetail;
                })
            $('#viewCampaignListModal').modal('show');
        }
        // Open Modal
        const openModal = () => {
            $("#featureNotAvailable").modal("show");
        };

        // Close Modal
        const closeModal = () => {
            $("#featureNotAvailable").modal("hide");
        };
        /*
            const datatableInit = async () => {
              if ($.fn.DataTable.isDataTable("#dataTable")) {
                $("#dataTable").DataTable().clear().destroy();
              }
              $("#dataTable").addClass("nowrap").dataTable({
                "searching": true,
                order: [],
                columnDefs: [
                  { targets: [0], orderable: true },
                  { targets: [1], orderable: true },
                  { targets: [2], orderable: true },
                  { targets: [3], orderable: true },
                  { targets: [4], orderable: true },
                  { targets: [5], orderable: true },
                  { targets: [6], orderable: true },
                  { targets: [7], orderable: false, searchable: false }
                ]
              });
              $(".display").addClass("nowrap").dataTable();
            }
        */
        const getAllCampaigns = async () => {
            if ($.fn.DataTable.isDataTable("#dataTable")) {
                $("#dataTable").DataTable().clear().destroy();
            }
            $('#dataTable').DataTable({
                "processing": true,
                "serverSide": true,
                language: {
                    emptyTable: "No matching records found",
                    lengthMenu: "Show _MENU_ records per page",
                    zeroRecords: "",
                    info: "Showing _START_ to _END_ of _TOTAL_ records",
                    infoEmpty: "No records available",
                    infoFiltered: "(filtered from _MAX_ total records)",
                },
                "ajax": {
                    "url": axios.defaults.baseUrl + "campaigns/text/dt",
                    "type": "POST", // Assuming your API endpoint expects a POST request
                    "headers": {
                        Authorization: "Bearer " + localStorage.getItem('auth_token'),
                    },
                    "data": function (d) {
                        // You can add additional parameters or modify the request data here
                        return $.extend({}, d, {
                            'dealer_id': localStorage.getItem('dealer_id'),
                            'location_id': localStorage.getItem('location_id'),
                            // 'list_id' : list_id.value,
                        });
                    },
                    dataSrc: function (response) {
                        // Transform the data here before DataTables processes it
                        AllTextCampaigns.value = response.data;
                        window.showHideMainLoader(false);
                        return [];
                    },
                },
                "searching": true,
                "order": [],
                "columns": [],
            });
            $(".display").addClass("nowrap").dataTable();
        }
        onMounted(() => {
            // getCampaignList();
            // datatableInit();

            getAllCampaigns();
            // window.showHideMainLoader(false);
        });

        return {
            openModal,
            closeModal,
            base_url,
            AllTextCampaigns,
            AllTextCampaignsListDetail,
            // getCampaignList,
            viewMessageModal,
            CampaignMessage,
            CampaignImage,
            CampaignImagePath,
            deleteCampaign,
            testSMS,
            viewCampaignListDetailModal,

            getAllCampaigns,
        };
    },
};
</script>
