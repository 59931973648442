<template>
        <!-- Solutions -->
        <section class="solitions-area" id="solitions">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="title">
                            <h2>Streamly Solutions for You </h2>
                            <p class="bar"></p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="solution">
                            <img class="img-fluid" src="@/assets/landing/img/solution1.png" alt="solution">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="solution-con">
                            <div class="solution-name lefts">
                                <h4>CRM </h4>
                                <img class="img-fluid" src="@/assets/landing/img/icon1.png" alt="icon">
                            </div>
                            <p>Streamline your sales process with Streamly's integrated CRM. Effortlessly manage leads, nurture relationships, and close deals—all while staying connected through powerful text and email marketing. Gain valuable insights, automate tasks, and watch your sales pipeline flourish.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 mobile-view">
                        <div class="solution">
                            <img class="img-fluid" src="@/assets/landing/img/solution2.png" alt="solution">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="solution-con">
                            <div class="solution-name rights">
                                <img class="img-fluid" src="@/assets/landing/img/icon2.png" alt="icon">
                                <h4>Email Marketing</h4>
                            </div>
                            <p>Craft captivating emails that convert with Streamly. Design stunning newsletters, automate personalized campaigns, and track performance in real-time. Engage your audience, drive conversions, and build lasting relationships—all within our user-friendly platform.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 desktop-view">
                        <div class="solution">
                            <img class="img-fluid" src="@/assets/landing/img/solution2.png" alt="solution">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="solution">
                            <img class="img-fluid" src="@/assets/landing/img/solution5.png" alt="solution">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="solution-con">
                            <div class="solution-name lefts">
                                <h4>Text Marketing</h4>
                                <img class="img-fluid" src="@/assets/landing/img/icon3.png" alt="icon">
                            </div>
                            <p>Text marketing that gets results. Reach your customers directly, drive engagement, and boost sales with Streamly. Send targeted campaigns, promotions, and updates straight to their phones. It's fast, effective, and keeps you connected.</p>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <!-- Solutions -->

        <!-- Complete-banner -->
        <section class="complate-banner">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 offset-lg-3">
                        <div class="complate">
                            <h3>Streamly</h3>
                            <h2>Your Complete Business Solutions</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Complete-banner -->

        <!-- Solutions -->
        <section class="solitions-area">
            <div class="container">
                <div class="row">

                    <div class="col-lg-6 mobile-view">
                        <div class="solution">
                            <img class="img-fluid" src="@/assets/landing/img/solution4.png" alt="solution">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="solution-con">
                            <div class="solution-name rights">
                                <img class="img-fluid" src="@/assets/landing/img/icon4.png" alt="icon">
                                <h4>Reputation Management </h4>
                            </div>
                            <p>Safeguard your brand reputation. Streamly empowers you to monitor online mentions, respond to reviews, and protect your brand image. Build trust, address concerns, and foster positive customer experiences – all in one place.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 desktop-view">
                        <div class="solution">
                            <img class="img-fluid" src="@/assets/landing/img/solution4.png" alt="solution">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="solution">
                            <img class="img-fluid" src="@/assets/landing/img/solution3.png" alt="solution">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="solution-con">
                            <div class="solution-name lefts">
                                <h4>Marketing Integrations</h4>
                                <img class="img-fluid" src="@/assets/landing/img/icon5.png" alt="icon">
                            </div>
                            <p>Connect the dots. Streamly integrates seamlessly with your favorite marketing tools. Simplify workflows, automate tasks, and gain a unified view of your customer data. Streamline your marketing efforts and watch your results soar.</p>
                        </div>
                    </div>

                    <!-- <div class="col-lg-6 mobile-view">
                        <div class="solution">
                            <img class="img-fluid" src="@/assets/landing/img/solution6.png" alt="solution">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="solution-con">
                            <div class="solution-name rights">
                                <img class="img-fluid" src="@/assets/landing/img/icon6.png" alt="icon">
                                <h4>Text Campaigns</h4>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore. </p>
                        </div>
                    </div>

                    <div class="col-lg-6 desktop-view">
                        <div class="solution">
                            <img class="img-fluid" src="@/assets/landing/img/solution6.png" alt="solution">
                        </div>
                    </div> -->

                </div>
            </div>
        </section>
        <!-- Solutions -->
        <!-- marketing-area -->
        <section class="marketing-area">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="title">
                            <h2>Drive, convert, and re-engage leads.</h2>
                            <p>Learn about every advantage you get when you sign up for Streamly.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="marketing-lists">
                            <i class="bi bi-star-fill"></i>
                            <h3>Google Review Management</h3>
                            <p>Improve your online presence with positive Google Reviews.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="marketing-lists">
                            <i class="bi bi-megaphone-fill"></i>
                            <h3>Bulk Marketing</h3>
                            <p>Win back customers: Personalized Offers that Drive Sales.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="marketing-lists">
                            <i class="bi bi-people-fill"></i>
                            <h3>Team Management</h3>
                            <p>Manage team members, track their appointments, and simplify scheduling with ease.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="marketing-lists">
                            <i class="bi bi-rocket-takeoff-fill"></i>
                            <h3>Centralized Communication</h3>
                            <p>Track campaign responses in one place from Streamly’s integrated inbox.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="marketing-lists">
                            <i class="bi bi-lightning-charge-fill"></i>
                            <h3>Instant Insights</h3>
                            <p>Streamlined data easily accessible from Streamly’s Dashboard.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="marketing-lists">
                            <i class="bi bi-person-video3"></i>
                            <h3>Customer Management</h3>
                            <p>Effortlessly Opt-In/Out, Create Appointments and Customer Notes - All in One Place.</p>
                        </div>
                    </div>


                </div>
            </div>
        </section>
        <!-- marketing-area -->
        <!-- brands-area -->
        <section class="brands-area">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="title">
                            <h2>Connect the tools you already use</h2>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 col-xl-3">
                        <div class="brands">
                            <img class="img-fluid" src="@/assets/landing/img/CDK_Logo.png" alt="brands">
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 col-xl-3">
                        <div class="brands">
                            <img class="img-fluid" src="@/assets/landing/img/CarGurus_Logo.png" alt="brands">
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 col-xl-3">
                        <div class="brands">
                            <img class="img-fluid" src="@/assets/landing/img/Ford_Direct_Logo.png" alt="brands">
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 col-xl-3">
                        <div class="brands">
                            <img class="img-fluid" src="@/assets/landing/img/VinSolutions_Logo.png" alt="brands">
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="butns">
                            <a href="#" class="cusbtn" @click="removeLoader">Browse all integrations</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- brands-area -->
        <!-- about-area -->
        <section class="about-area" id="about">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="title">
                            <h3>About</h3>
                            <h2>Streamly</h2>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="about-img">
                            <img class="img-fluid" src="@/assets/landing/img/about.png" alt="about">
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="about-con">
                            <img class="img-fluid" src="@/assets/landing/img/logo-blue.png" alt="logo-blue">
                            <p>Streamly was founded with a passion for helping small businesses thrive in today's digital landscape. We understand the challenges you face: staying connected with customers, managing marketing campaigns, and building a strong brand reputation. That's why we created an all-in-one platform that simplifies communication, automates tasks, and empowers you to nurture meaningful customer relationships.</p>
                            <p>Streamly goes beyond just text and email marketing. We provide a powerful suite of tools that integrates seamlessly with your existing workflows, giving you a holistic view of your customer data. This allows you to send targeted campaigns, manage online reputation, and gain valuable insights to optimize your marketing efforts.</p>
                            <p>Our team is dedicated to your success. We offer exceptional customer support and educational resources to help you navigate the ever-evolving marketing landscape. With Streamly by your side, you can focus on what matters most – running your business and achieving your goals.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- about-area -->
        <!-- demo-banner -->
        <section class="demo-banner">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 offset-lg-3">
                        <div class="title text-center">
                            <h2>Get started today.</h2>
                            <p>Grow and scale your business with an all-in-one lead management platform.</p>
                            <a href="#" class="cusbtn" @click="removeLoader">Request a Demo</a>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <!-- demo-banner -->
        <!-- faq-area -->
        <section class="faq-area" id="faq">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="faq">
                            <div class="title">
                                <img class="img-fluid" src="@/assets/landing/img/faq.png" alt="faq">
                                <h2>Frequently Asked Questions</h2>
                                <p class="bar"></p>
                            </div>

                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <span>01.</span> What is Streamly?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" data-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Streamly is an all-in-one marketing platform designed to empower small businesses. We offer features like text and email marketing, CRM integration, and reputation management tools, all in one user-friendly platform.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <span>02.</span> How can Streamly help my business?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" data-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Streamly helps you connect with customers, build relationships, and grow your business. Our tools allow you to:</p>
                                            <p>
                                                <ul>
                                                    <li>Send targeted marketing campaigns via text and email.</li>
                                                    <li>Manage your customer interactions and relationships through CRM integration.</li>
                                                    <li>Monitor and improve your online reputation.</li>
                                                    <li>Gain valuable insights to optimize your marketing efforts.</li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <span>03.</span> Is Streamly easy to use?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" data-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Absolutely! Streamly is designed with small businesses in mind, offering a user-friendly interface and intuitive features. No technical expertise is required.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                                            <span>04.</span> Do streamly offer any support?
                                        </button>
                                    </h2>
                                    <div id="collapsefour" class="accordion-collapse collapse" data-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Yes, we are committed to your success! Streamly provides exceptional customer support through various channels, including live chat, email, and phone. We also offer a comprehensive library of helpful resources and tutorials.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button" data-toggle="collapse" data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
                                            <span>05.</span> What are the pricing options?
                                        </button>
                                    </h2>
                                    <div id="collapsefive" class="accordion-collapse collapse" data-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Streamly offers flexible pricing plans to suit your business needs. Visit our pricing page or contact our sales team for more information.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- faq-area -->
</template>

<style>
</style>

<script>
// import $ from "jquery";
import {
    // ref,
    onMounted
} from "vue";
// import axios from "axios";

export default {
    components: {},
    setup() {
        let base_url = "http://api.getstreamly.com/uploads/";
       
        // Open Modal
        const openModal = () => {
            // $("#featureNotAvailable").modal("show");
        };

        // Close Modal
        const closeModal = () => {
            // $("#featureNotAvailable").modal("hide");
            // $("#create-appointment").modal("hide");
        };
        const showToast = (toastIcon, toastMessage) => {
            window.toast.fire({
                icon: toastIcon,
                title: toastMessage,
            });
        }
        const removeLoader = () => {
            window.showHideMainLoader(false);
        };
        onMounted(() => {
            window.showHideMainLoader(false);
        });

        return {
            openModal,
            closeModal,
            base_url,
            showToast,
            removeLoader,
        };
    },
};
</script>