<template>
<div>

    <!-- search-top -->
    <div class="search-top">
        <div class="row">
            <!--<div class="col-md-6 col-lg-4">
          <div class="search-box">
            <form>
              <div class="form-group">
                <input type="search" class="form-control" aria-label="search" placeholder="Search">
                <img class="img-fluid sea-icon" src="@/assets/img/search.svg" alt="image title">
              </div>
            </form>
          </div>
        </div>-->
            <div class="col-md-6 col-lg-4">
                <div class="search-box">
                    <form>
                        <div class="form-group">
                            <select class="form-control" aria-label="list-id" @change="changeListId" id="listId" v-model="listId">
                                <option value="" selected>All Customers</option>
                                <option value="0">Without List</option>
                                <option v-for="listOption in AllCustomersLists" v-bind:key="listOption.id" v-bind:value="listOption.id">
                                    {{ listOption.title }}
                                </option>
                            </select>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-6 col-lg-8">
                <div class="export-right">
                    <div class="export">
                        <!-- <a href="#"> <img class="img-fluid" src="@/assets/img/export.svg" alt="image title"> Export</a> -->
                        <a class="uploadcontact" href="#" data-toggle="modal" data-target="#upload_contacts" data-backdrop="static" data-keyboard="false">Upload customers</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- search-top -->

    <!-- review-table -->
    <div class="review-table">
        <table class="display" id="dataTable">
            <thead>
                <tr>
                    <th>Customer Name</th>
                    <th>Information</th>
                    <th>Opt-In Status</th>
                    <th>Message Received</th>
                    <th>Message Sent</th>
                    <th>Tags</th>
                    <th>Invitation</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="Customer in AllCustomers" :key="Customer">
                    <td>
                        <a class="manname" href="javascript:">
                            <img class="img-fluid" v-if="Customer.user_image" :src="base_url + 'user/' + Customer.user_image" alt="User Image">
                            <img class="img-fluid" v-else src="@/assets/img/man.png" alt="image title">
                            {{ Customer.first_name }} {{ Customer.last_name }}
                        </a>
                    </td>
                    <td>
                        <b style="font-weight: bold !important;">Phone: </b>
                        <br>
                        +{{ Customer.country_code }}{{ Customer.phone }}
                        <br>
                        <b style="font-weight: bold !important;">Email: </b>
                        <br>
                        {{ Customer.email }}
                    </td>
                    <td>
                        <a v-if="Customer.optin_status == 1" :class="'invites opt-user-'+Customer.id" @click="optUser(Customer.id, 'out')" href="javascript:;">Opt-Out</a>
                        <a v-if="Customer.optin_status == 0" :class="'invites opt-user-'+Customer.id" @click="optUser(Customer.id, 'in')" href="javascript:;">Opt-In</a>
                    </td>
                    <td>{{ Customer.messages_received }}</td>
                    <td>{{ Customer.messages_sent }}</td>
                    <td v-html="Customer.user_tags"></td>
                    <!-- <td>{{ Customer.customer_last_invite }}</td> -->
                    <td>
                        <!--
                        <a class="invites" href="javascript:;" @click="sendSMSpopup(Customer)"><img class="img-fluid" src="@/assets/img/paper-plane.svg" alt="image title"> Send SMS</a>
                        <a class="invites" href="javascript:" @click="editCustomer(Customer)">Edit</a>
                        <a class="invites" href="javascript:" @click="deleteCustomer(Customer.id)">Delete</a>
                        <br>
                        <a class="invites" href="javascript:" @click="createAppointment(Customer)">Create appointment</a>
-->
                        <div class="navbar btn-group">
                            <button type="button" class="dropbtn dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                Action
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="javascript:;" @click="sendSMSpopup(Customer)"><img class="img-fluid" src="@/assets/img/send3.svg" alt="download"> Send SMS</a>
                                <a class="dropdown-item" href="javascript:;" @click="editCustomer(Customer)"><img class="img-fluid" src="@/assets/img/edit-3.svg" alt="download"> Edit</a>
                                <a class="dropdown-item" href="javascript:;" @click="deleteCustomer(Customer.id)"><img class="img-fluid" src="@/assets/img/delete2.svg" alt="download"> Delete</a>
                                <a class="dropdown-item" href="javascript:;" @click="createAppointment(Customer)"><img class="img-fluid" src="@/assets/img/plus.svg" alt="download"> Create Appointment</a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- review-table -->

    <!-- Create-Modal -->
    <div class="modal fade" id="create_contact" tabindex="-1" aria-labelledby="createCustomer" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 v-if="editMode" class="modal-title" id="createCustomer">Update customer</h5>
                    <h5 v-else class="modal-title" id="createCustomer">Create a customer</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <img class="img-fluid" src="@/assets/img/close.png" alt="image title">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="contact-form">
                        <form v-on:submit.prevent="submitForm" id="addEditCustomerForm">
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <label for="First">
                                        <p class="label-txt label-active">List Name (Optional)</p>
                                        <select class="form-control" aria-label="list-id" v-model="create_contact_form.create_contact_list_id">
                                            <option value="" selected>Select List</option>
                                            <option v-for="listOption in AllCustomersLists" v-bind:key="listOption.id" v-bind:value="listOption.id">
                                                {{ listOption.title }}
                                            </option>
                                        </select>
                                    </label>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="First">
                                        <p class="label-txt label-active">First Name</p>
                                        <input type="text" class=" input" id="create_contact_first_name" v-model="create_contact_form.create_contact_first_name" @click="removeClass('#create_contact_first_name', 'red-border')">
                                    </label>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="Last">
                                        <p class="label-txt label-active">Last Name</p>
                                        <input type="text" class=" input" id="create_contact_last_name" v-model="create_contact_form.create_contact_last_name" @click="removeClass('#create_contact_last_name', 'red-border')">
                                    </label>
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="Email">
                                        <p class="label-txt label-active">Email Address</p>
                                        <input type="email" class=" input" id="create_contact_email" v-model="create_contact_form.create_contact_email" @click="removeClass('#create_contact_email', 'red-border')">
                                    </label>
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="Phone">
                                        <p class="label-txt label-active">Phone Number</p>
                                        <!-- <input type="text" class=" input" id="create_contact_phone"
                        v-model="create_contact_form.create_contact_phone"> -->
                                        <vue-mask class="form-control" mask="(000)000-0000" :options="options" id="create_contact_phone" placeholder="" v-model="create_contact_form.create_contact_phone" @click="removeClass('#create_contact_phone', 'red-border')"></vue-mask>
                                    </label>
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="Interest">
                                        <p class="label-txt label-active">Company Name</p>
                                        <input type="text" class=" input" id="create_contact_company" v-model="create_contact_form.create_contact_company">
                                    </label>
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="Trad">
                                        <p class="label-txt label-active">Full Address</p>
                                        <input type="text" class=" input" id="create_contact_address" v-model="create_contact_form.create_contact_address" @click="removeClass('#create_contact_address', 'red-border')">
                                    </label>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="text-center">
                        <button v-if="editMode" type="button" class="send-btn" @click="updateCustomer(create_contact_form.Id)">Update</button>
                        <button v-else type="button" class="send-btn" @click="addCustomer">Create</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Upload contacts-Modal -->
    <div class="modal fade" id="upload_contacts" tabindex="-1" aria-labelledby="UploadCustomer" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="UploadCustomer">Add your customers to Streamly</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <img class="img-fluid" src="@/assets/img/close.png" alt="image title">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="upload-contacts">
                        <div class="row">
                            <div class="col-4">
                                <div class="nav flex-column nav-pills" id="v-pills-tab" aria-orientation="vertical">
                                    <button class="nav-link active disabled" id="v-pills-disclaimer-tab" data-toggle="pill" data-target="#v-pills-disclaimer" type="button" aria-controls="v-pills-disclaimer" aria-selected="true">Disclaimer
                                        <span>Terms of Services</span>
                                    </button>
                                    <button class="nav-link disabled" id="v-pills-upload-tab" data-toggle="pill" data-target="#v-pills-upload" type="button" role="tab" aria-controls="v-pills-upload" aria-selected="true" @click="importButton">Upload spreadsheet
                                        <span>Import contact from a CSV file</span>
                                    </button>
                                    <button class="nav-link disabled" id="v-pills-copy-tab" data-toggle="pill" data-target="#v-pills-copy" type="button" role="tab" aria-controls="v-pills-copy" aria-selected="false" @click="copyButton">Copy & paste spreadsheet
                                        <span>Paste a list of contacts</span>
                                    </button>
                                </div>
                            </div>
                            <div class="col-8">
                                <div class="tab-content" id="v-pills-tabContent">

                                    <div class="tab-pane fade show active" id="v-pills-disclaimer" role="tabpanel" aria-labelledby="v-pills-disclaimer-tab">
                                        <!-- upload -->
                                        <div class="uploadfile">
                                            <h2>Terms of Services - Disclaimer</h2>
                                            <div class="file">
                                                <div class="upload-contact-disclaimer">
                                                    <p>You agree to use Streamly's services in full compliance with Streamly's Terms of Service, including but not limited to Sections 5.1 (Warranty) and 5.2 (Customer Consent), as well as all applicable laws and regulations.</p>
                                                    <p>You warrant that any Customer information you upload or submit for use with Streamly's services was collected in compliance with all relevant federal, state, provincial, local, and international laws, regulations, and rules governing electronic communication, including but not limited to the Telephone Consumer Protection Act (TCPA), the Telemarketing and Consumer Fraud and Abuse Prevention Act, the FTC’s Telemarketing Sales Rule, the CAN-SPAM Act, Canada’s Anti-Spam Legislation (CASL), and any state and local equivalents, as well as applicable industry guidelines and best practices.</p>
                                                    <p>You further confirm that any Customer lists submitted to Streamly exclude numbers that have opted out or unsubscribed from communications.</p>
                                                    <p>You acknowledge and agree that you are solely responsible for ensuring your compliance with all applicable laws and regulations. Streamly's services are provided to assist with your communication needs but do not guarantee compliance with legal requirements. Streamly expressly disclaims any liability arising from your non-compliance.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <label><input type="checkbox" id="iAgreeCustomer" /> I have read and agree to Streamly's Terms of Services and Additional Terms</label>
                                    </div>
                                    <div class="tab-pane fade" id="v-pills-upload" role="tabpanel" aria-labelledby="v-pills-upload-tab">
                                        <!-- upload -->
                                        <div class="uploadfile">
                                            <h2>Select your CSV file to upload</h2>
                                            <p>Before uploading, convert or export your spreadsheet as a CSV file.
                                                Each contact must have a phone number attached. <a href="#">Learn
                                                    more about file upload.</a></p>
                                            <h3>Make sure the first row of your spreadsheet has labels for each
                                                column (i.e. First Name, Last Name, Email, Phone Number, etc). </h3>
                                            <div class="file">
                                                <div class="form-group">
                                                    <select class="form-control" aria-label="list-id" @change="selectListIdUpload" id="uploadListId" style="background-color: #fff; padding:0 10px;" v-model="select_list_id_upload">
                                                        <option value="" selected>Select List</option>
                                                        <option value="new">Create New List</option>
                                                        <option v-for="listOption in AllCustomersLists" v-bind:key="listOption.id" v-bind:value="listOption.id">
                                                            {{ listOption.title }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="form-group select-list-id-upload" style="display:none;">
                                                    <input type="text" class="form-control" id="uploadListName" v-model="selectListNameUpload" placeholder="List Name" style="background-color: #fff; padding:0 10px;" @click="removeClass('#uploadListName', 'red-border')" />
                                                </div>
                                                <h4>Select your file here </h4>
                                                <!-- <p class="or">or</p> -->
                                                <!-- <br> -->
                                                <div class="button-wrapper upload-csv-btn-wrapper">
                                                    <form v-on:submit.prevent="submitForm" id="uploadCustomerForm">
                                                        <span class="label">
                                                            <img class="img-fluid" src="@/assets/img/upload.svg" alt="image title" style="width:15px; height:auto; border-radius: 0px;"> Choose file
                                                        </span>
                                                        <input type="file" name="upload" id="upload" class="upload-box" placeholder="Upload File" @change="user_csvInput" ref="file" accept=".csv" />
                                                    </form>
                                                </div>
                                                <div class="csv_upload_file_name" style="display:none;">
                                                    <strong>File Selected:</strong> {{ customer_csv_upload_file_name }}
                                                </div>
                                                <p>File size is limited to 5 MB and 100,000 rows. </p>
                                            </div>
                                        </div>
                                        <!-- upload -->
                                    </div>

                                    <div class="tab-pane fade" id="v-pills-copy" role="tabpanel" aria-labelledby="v-pills-copy-tab">
                                        <!-- upload -->
                                        <div class="uploadfile">
                                            <h2>Copy and paste your spreadsheet of contacts </h2>
                                            <p>Paste your contact list in the textbox. If you have multiple columns
                                                of information (i.e. First Name, Last Name, etc.), Streamly will
                                                automatically organize them for you. </p>
                                            <h3>Make sure the first row of your spreadsheet has labels for each
                                                column (i.e. First Name, Last Name, Phone Number, etc). Paste your contacts
                                                here. </h3>
                                            <div class="file">
                                                <div class="form-group">
                                                    <select class="form-control" aria-label="list-id" @change="selectListIdCopy" id="copyListId" style="background-color: #fff; padding:0 10px;" v-model="select_list_id_copy">
                                                        <option value="" selected>Select List</option>
                                                        <option value="new">Create New List</option>
                                                        <option v-for="listOption in AllCustomersLists" v-bind:key="listOption.id" v-bind:value="listOption.id">
                                                            {{ listOption.title }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="form-group select-list-id-copy" style="display:none;">
                                                    <input type="text" class="form-control" id="copyListName" v-model="selectListNameCopy" placeholder="List Name" style="background-color: #fff; padding:0 10px;" @click="removeClass('#uploadListCopy', 'red-border')" />
                                                </div>
                                                <div id="copypaste_csv_textarea">
                                                    <textarea class="form-control" rows="6" placeholder="Paste your contacts here..." @paste="onPaste"></textarea>
                                                </div>
                                                <div style="display: none; position:relative;" id="copypaste_csv_input">
                                                    <input class="form-control" disabled value="Data is Pasted. Click 'Parse CSV' button to continue." />
                                                    <a href="javascript:" style="position: absolute; right: 0; top: 8px;" @click="removeCopiedTextaraeaValue">Remove</a>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- upload -->
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer contact-footer">
                    <button type="button" class="cancel-btn" data-dismiss="modal" @click="uploadCustomerCancel">Cancel</button>
                    <button type="submit" class="send-btn disclaimer-btn" @click="disclaimerNext">Next</button>
                    <button type="submit" class="send-btn import-csv-btn" style="display: none;" @click="uploadCustomerCSV">Import CSV</button>
                    <button type="button" class="send-btn copy-csv-btn" style="display: none;" @click="parseCustomerCSV">Parse CSV</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Upload contacts-Modal -->
    <div class="modal fade" id="disply_csv_uploaded_contact" tabindex="-1" aria-labelledby="csvUploadedContact" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="csvUploadedContact">Add your contacts to Streamly</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <img class="img-fluid" src="@/assets/img/close.png" alt="image title">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="upload-contacts">
                        <p>This preview only shows records which have required data. Any unrelated columns will be automatically
                            excluded. Empty columns are hidden. </p>
                        <!-- review-table -->
                        <div class="review-table">
                            <table class="display-csv-records" id="csv-records-table">
                                <thead>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email Address</th>
                                        <th>Phone Number</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="csvCustomer in csvCustomers" :key="csvCustomer">
                                        <td>{{ csvCustomer.first_name }}</td>
                                        <td>{{ csvCustomer.last_name }}</td>
                                        <td>{{ csvCustomer.email }}</td>
                                        <td>{{ csvCustomer.phone }}</td>
                                        <td><a class="invites" href="javascript:" @click="handleMDeleteCsvUploadedUserRequest(csvCustomer.id)">Delete</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- review-table -->
                    </div>
                </div>
                <div class="modal-footer contact-footer">
                    <button type="button" class="cancel-btn" data-dismiss="modal">Cancel</button>
                    <button type="button" class="send-btn" @click="importCsvUsersFromTemp">Continue</button>
                </div>
            </div>
        </div>
    </div>
    <!-- invite-Modal -->
    <div class="modal fade" id="sendSMSpopup" tabindex="-1" aria-labelledby="sendSMSpopupLabel" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="sendSMSpopupLabel">Send SMS</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <img class="img-fluid" src="@/assets/img/close.png" alt="image title">
                    </button>
                </div>
                <div class="modal-body">

                    <div class="contact-form">
                        <form action="#">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="customer-field">
                                        <p><span>Name:</span> {{ sendSMSValues.sms_customer_name }}</p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="customer-field">
                                        <p><span>Phone:</span> {{ sendSMSValues.sms_customer_phone }}</p>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="customer-field">
                                        <p><span> Email:</span> {{ sendSMSValues.sms_customer_email }}</p>
                                    </div>
                                </div>

                                <div class="form-group col-md-12">
                                    <label for="message">
                                        <p class="label-txt label-active">Message</p>
                                        <textarea class="input" rows="5" v-model="sendSMSValues.sms_message"></textarea>
                                    </label>
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="button" class="send-btn" @click="sendMessageToCustomer"><img class="img-fluid" src="@/assets/img/plane.svg" alt="image title"> Send SMS</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- invite-Modal -->
    <div class="modal fade" id="create-appointment" tabindex="-1" aria-labelledby="exampleModalLabel4" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel4">
                        Create an Appointment
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <img class="img-fluid" src="@/assets/img/close.png" alt="image title" />
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-8">
                            <!-- create-meetings -->
                            <div class="create-meetings">
                                <div class="add-title">
                                    <input v-model="newAppointment.title" type="text" class="form-control" placeholder="Title of appointment" />
                                </div>
                                <div class="add-title" style="position: relative;">
                                    <input v-model="newAppointment.customer_name" type="text" class="form-control" placeholder="Customer..." disabled />
                                    <input type="hidden" v-model="newAppointment.customer_id" />
                                    <div class="appointment-customers-list-box">
                                        <ul class="appointment-customers-list">
                                            <li class="appointment-customers-list-item" v-for="Customer in AllCustomers" v-bind:key="Customer.id" v-bind:value="Customer.id">
                                                <a class="appointment-customers-item" href="javascript:;" :data-id="Customer.id" :data-name="Customer.first_name+' '+Customer.last_name" @click="customerSelectId(Customer.first_name+' '+Customer.last_name, Customer.id)">{{ Customer.first_name+' '+Customer.last_name }}</a>
                                            </li>
                                        </ul>
                                        <span class="appointment-customers-searching" style="display: none;">Searching...</span>
                                        <span class="appointment-customers-notfound" style="display: none;">No Customer found.</span>
                                    </div>
                                </div>

                                <div class="one-group">
                                    <p>Type of conference</p>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <label class="containers active"><span class="label">In Person</span>
                                                    <img class="img-fluid" src="@/assets/img/one.svg" alt="image title" />
                                                    <input type="radio" name="radio" v-model="newAppointment.type" checked value="0" />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <label class="containers"><span class="label">Virtual</span>
                                                    <img class="img-fluid" src="@/assets/img/Virtual.svg" alt="image title" />
                                                    <input type="radio" name="radio" v-model="newAppointment.type" value="1" />
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="calanders">
                                    <p>Select a date</p>
                                    <div class="calendar calendar-first" id="calendar_first">
                                        <div class="calendar_header">
                                            <h2></h2>
                                            <button class="switch-month switch-right">
                                                <img class="img-fluid" src="@/assets/img/arrowright.svg" alt="image title" />
                                            </button>
                                            <button class="switch-month switch-left">
                                                <img class="img-fluid" src="@/assets/img/arrowleft.svg" alt="image title" />
                                            </button>
                                        </div>
                                        <div class="add-title">
                                            <span style="position: absolute; z-index: 100; right: 8px; font-size: 20px; top: 8px; opacity: 0.8;"><i class="fa fa-calendar"></i></span>
                                            <input v-model="newAppointment.appointment_date" type="date" class="form-control datepicker" placeholder="Date of Appointment" min="1997-01-01" max="2030-12-31" />
                                        </div>
                                        <!--<div class="calendar_weekdays"></div>
                    <div class="calendar_content"></div>-->
                                    </div>

                                    <!-- time-box -->
                                    <div class="time-box mobile-view">
                                        <div class="times">
                                            <h4>Select time</h4>
                                            <h6>Time you’re available</h6>
                                            <h6>Current Time {{ getCurrentTime('time') }}</h6>
                                        </div>

                                        <div class="custom-control custom-radio custom-control-inline">
                                            <label class="containers active"><span class="label">Now ({{ getCurrentTime('now') }})</span>
                                                <input type="radio" name="radio1" checked />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>

                                        <div class="custom-control custom-radio custom-control-inline">
                                            <label class="containers"><span class="label">Custom time</span>
                                                <input type="radio" name="radio1" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <form>
                                            <div class="form-row">
                                                <div class="col">
                                                    <select class="form-control">
                                                        <option>01</option>
                                                        <option>02</option>
                                                        <option>03</option>
                                                        <option>04</option>
                                                        <option>05</option>
                                                        <option>06</option>
                                                        <option>07</option>
                                                        <option>08</option>
                                                        <option>09</option>
                                                        <option>10</option>
                                                        <option>11</option>
                                                        <option>12</option>
                                                    </select>
                                                </div>
                                                <div class="col">
                                                    <select class="form-control">
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                    </select>
                                                </div>
                                                <div class="col">
                                                    <select class="form-control">
                                                        <option>AM</option>
                                                        <option>PM</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </form>

                                        <ul>
                                            <li>
                                                <a class="act" href="#"><span></span> 08:00 am</a>
                                            </li>
                                            <li>
                                                <a href="#"><span></span> 08:00 am</a>
                                            </li>
                                            <li>
                                                <a href="#"><span></span> 08:00 am</a>
                                            </li>
                                            <li>
                                                <a href="#"><span></span> 08:00 am</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- time-box -->
                                </div>
                                <!--
                <div class="duration">
                  <p>Duration schedule</p>
                  <ul>
                    <li>
                      <a class="act" href="#"
                        >15 <span class="mins">mins</span></a
                      >
                    </li>
                    <li>
                      <a href="#">30 <span class="mins">mins</span></a>
                    </li>
                    <li>
                      <a href="#">45 <span class="mins">mins</span></a>
                    </li>
                    <li>
                      <a href="#"
                        ><input
                          type="text"
                          class="form-control"
                          placeholder="--"
                        /><span class="mins">custom mins</span></a
                      >
                    </li>
                  </ul>
                </div>

                <div class="person">
                  <p>Participants</p>
                  <a href="#"><span class="show">Add</span></a>
                </div>
-->
                            </div>

                            <!-- create-btms -->

                            <div class="create-btn">
                                <a href="#" data-dismiss="modal" aria-label="Close">Close</a>
                                <a class="create" href="javascript:;" @click="addAppointmentRequest">create</a>
                            </div>
                            <!-- create-btms -->
                        </div>

                        <div class="col-lg-4 desktop-view">
                            <!-- time-box -->
                            <div class="time-box">
                                <div class="times">
                                    <h4>Select time</h4>
                                    <h6>Time you’re available</h6>
                                    <h6>Current Time {{ getCurrentTime('time') }}</h6>
                                </div>

                                <div class="custom-control custom-radio custom-control-inline">
                                    <label class="containers active"><span class="label">Now ({{ getCurrentTime('now') }})</span>
                                        <input type="radio" name="radio1" checked v-model="newAppointment.timeType" value="now" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="custom-control custom-radio custom-control-inline">
                                    <label class="containers"><span class="label">Custom time</span>
                                        <input type="radio" name="radio1" v-model="newAppointment.timeType" value="custom" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <form>
                                    <div class="form-row">
                                        <div class="col">
                                            <span style="position: absolute; z-index: 100; right: 8px; font-size: 20px; top: 3px; opacity: 0.8;"><i class="fa fa-angle-down"></i></span>
                                            <select class="form-control" v-model="newAppointment.customTimeHours">
                                                <option value="" selected>Hours</option>
                                                <option value="01">01</option>
                                                <option value="01">02</option>
                                                <option value="01">03</option>
                                                <option value="01">04</option>
                                                <option value="01">05</option>
                                                <option value="01">06</option>
                                                <option value="01">07</option>
                                                <option value="01">08</option>
                                                <option value="01">09</option>
                                                <option value="01">10</option>
                                                <option value="01">11</option>
                                                <option value="01">12</option>
                                            </select>
                                        </div>
                                        <div class="col">
                                            <span style="position: absolute; z-index: 100; right: 8px; font-size: 20px; top: 3px; opacity: 0.8;"><i class="fa fa-angle-down"></i></span>
                                            <select class="form-control" v-model="newAppointment.customTimeMinutes">
                                                <!-- <option v-for="i in 59" :key="i">{{ i+15 }}</option> -->
                                                <option value="" selected>Min</option>
                                                <option value="00">00</option>
                                                <option value="15">15</option>
                                                <option value="30">30</option>
                                                <option value="45">45</option>
                                            </select>
                                        </div>
                                        <div class="col">
                                            <span style="position: absolute; z-index: 100; right: 8px; font-size: 20px; top: 3px; opacity: 0.8;"><i class="fa fa-angle-down"></i></span>
                                            <select class="form-control" v-model="newAppointment.customTimeAmPm">
                                                <option value="AM/PM" selected>AM/PM</option>
                                                <option value="AM">AM</option>
                                                <option value="PM">PM</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                                <!--
                <ul>
                  <li>
                    <a class="act" href="#"><span></span> 08:00 am</a>
                  </li>
                  <li>
                    <a href="#"><span></span> 08:00 am</a>
                  </li>
                  <li>
                    <a href="#"><span></span> 08:00 am</a>
                  </li>
                  <li>
                    <a href="#"><span></span> 08:00 am</a>
                  </li>
                </ul>
-->
                            </div>
                            <!-- time-box -->
                        </div>
                    </div>

                    <!-- create-meetings -->
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style>
.csv_upload_file_name strong {
    font-size: 16px;
}

.csv_upload_file_name {
    background: rgba(96, 70, 254, 0.4);
    min-width: 200px;
    width: auto;
    display: inline-block;
    padding: 2px 10px;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
}
</style>

<script>
import $ from "jquery";
import {
    ref,
    onMounted
} from "vue";
import axios from "axios";
import {
    useRoute
} from "vue-router";
import vueMask from 'vue-jquery-mask';
// console.log(localStorage);
export default {
    components: {
        vueMask
    },
    setup() {
        let base_url = "http://api.streamly.com/uploads/";
        const Params = useRoute();
        let AllCustomersLists = ref([]);
        let AllCustomers = ref([]);
        let csvCustomers = ref([]);
        let select_list_id_upload = ref('');
        let select_list_id_copy = ref('');
        let selectListNameUpload = ref('');
        let selectListNameCopy = ref('');
        let listId = ref('');
        let editMode = ref(false);
        let customer_csv = '';
        let customer_csv_upload_file_name = ref('');
        let csv_copied = '';
        let tabType = ref('');
        let create_contact_form = ref({
            Id: 0,
            create_contact_list_id: '',
            create_contact_first_name: '',
            create_contact_last_name: '',
            create_contact_email: '',
            create_contact_phone: '',
            create_contact_company: '',
            create_contact_address: ''
        });
        let copy_contact_form = ref({
            textarea_value: null,
        });
        let sendSMSValues = ref({
            sms_customer_id: 0,
            sms_customer_name: '',
            sms_customer_phone: '',
            sms_customer_email: '',
            sms_message: '',
        });
        let newAppointment = ref({
            Id: null,
            title: null,
            type: null,
            customer_id: null,
            customer_name: null,
            appointment_date: null,
            appointment_time: null,
            notes: null,
            timeType: null,
            customTimeHours: null,
            customTimeMinutes: null,
            customTimeAmPm: null,
        });
        const createAppointment = (Customer = null) => {
            // editMode.value = false;
            newAppointment.value.Id = null;
            newAppointment.value.title = null;
            newAppointment.value.type = null;
            newAppointment.value.customer_id = Customer.id;
            newAppointment.value.customer_name = Customer.first_name + ' ' + Customer.last_name;
            newAppointment.value.appointment_date = null;
            newAppointment.value.timeType = null;
            newAppointment.value.customTimeHours = "";
            newAppointment.value.customTimeMinutes = "";
            newAppointment.value.customTimeAmPm = "";
            newAppointment.value.notes = null;
            $("#create-appointment").modal("show");
        };
        const getCurrentTime = (timetype = null) => {
            var todayDate = new Date();
            if (timetype == 'now') {
                var hours = todayDate.getHours();
                var minutes = todayDate.getMinutes();
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0' + minutes : minutes;
                var ampm = todayDate.getHours() >= 12 ? 'pm' : 'am';
                return hours + ':' + minutes + ' ' + ampm;
            } else {
                return todayDate.getHours() + ":" + todayDate.getMinutes() + ":" + todayDate.getSeconds();
            }
        };
        const addAppointmentRequest = async () => {
            let addFormData = new FormData()
            addFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            addFormData.append('location_id', localStorage.getItem('location_id'));
            addFormData.append('title', newAppointment.value.title);
            addFormData.append('type', newAppointment.value.type);
            addFormData.append('customer_id', newAppointment.value.customer_id);
            addFormData.append('appointment_date', newAppointment.value.appointment_date);
            addFormData.append('timeType', newAppointment.value.timeType);
            addFormData.append('customTimeHours', newAppointment.value.customTimeHours);
            addFormData.append('customTimeMinutes', newAppointment.value.customTimeMinutes);
            addFormData.append('customTimeAmPm', newAppointment.value.customTimeAmPm);
            addFormData.append('notes', newAppointment.value.notes);
            // console.log(teamuser_image);
            await axios
                .post(
                    axios.defaults.baseUrl + "appointments/add",
                    addFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
                .then((response) => {
                    window.toast.fire({
                        icon: response.data.status,
                        title: response.data.message,
                    });
                    // getAppointmentList();
                    closeModal();
                    newAppointment.value = '';
                    location.assign('/appointments');
                });
        }
        const getCustomersList = async () => {
            // window.showHideMainLoader(true);
            const getFormData = new FormData();
            getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            getFormData.append('location_id', localStorage.getItem('location_id'));
            await axios
                .post(
                    axios.defaults.baseUrl + "customers/lists",
                    getFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    AllCustomersLists.value = response.data.data.allCustomersLists;
                    // datatableInit();
                })
                .catch((error) => {
                    // window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }

        const addCustomer = async () => {
            removeClass('.form-control', 'red-border');
            let validate = 1;
            // console.log(validate);
            if (create_contact_form.value.create_contact_first_name == '') {
                validate = 0;
                addClass('#create_contact_first_name', 'red-border');
                showToast('error', 'First name is required');
                return false;
            }
            if (create_contact_form.value.create_contact_last_name == '') {
                validate = 0;
                addClass('#create_contact_last_name', 'red-border');
                showToast('error', 'Last name is required');
                return false;
            }
            if (create_contact_form.value.create_contact_email == '') {
                validate = 0;
                addClass('#create_contact_email', 'red-border');
                showToast('error', 'Email address is required');
                return false;
            }
            if (create_contact_form.value.create_contact_phone == '') {
                validate = 0;
                addClass('#create_contact_phone', 'red-border');
                showToast('error', 'Phone no. is required');
                return false;
            } else if (create_contact_form.value.create_contact_phone.length < 10) {
                validate = 0;
                addClass('#create_contact_phone', 'red-border');
                showToast('error', 'Phone number is invalid2');
                return false;
            } else if (!isPhoneValid(create_contact_form.value.create_contact_phone)) {
                validate = 0;
                addClass('#create_contact_phone', 'red-border');
                showToast('error', 'Phone number is invalid3');
                return false;
            }
            if (create_contact_form.value.create_contact_address == '') {
                validate = 0;
                addClass('#create_contact_address', 'red-border');
                showToast('error', 'Address is required');
                return false;
            }
            if (validate == 1) {
                window.showHideMainLoader(true);
                let addFormData = new FormData();
                // addFormData.append('image', teamuser_image);
                addFormData.append('dealer_id', localStorage.getItem('dealer_id'));
                addFormData.append('location_id', localStorage.getItem('location_id'));
                addFormData.append('list_id', create_contact_form.value.create_contact_list_id);
                addFormData.append('first_name', create_contact_form.value.create_contact_first_name);
                addFormData.append('last_name', create_contact_form.value.create_contact_last_name);
                addFormData.append('email', create_contact_form.value.create_contact_email);
                addFormData.append('phone', create_contact_form.value.create_contact_phone);
                addFormData.append('company', create_contact_form.value.create_contact_company);
                addFormData.append('address', create_contact_form.value.create_contact_address);
                // console.log(teamuser_image);
                await axios
                    .post(
                        axios.defaults.baseUrl + "customers/add",
                        addFormData, {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("auth_token"),
                            },
                        }
                    )
                    .then((response) => {
                        showToast(response.data.status, response.data.message);
                        getAllCustomers($('#listId').val());
                        closeModal();
                        create_contact_form.value = '';
                        location.reload(); //-----remove later
                        window.showHideMainLoader(false);

                    })
                    .catch((error) => {
                        window.showHideMainLoader(false);
                        showToast(error.response.data.status, error.response.data.message);
                        return false;
                    });
            }

        }
        const editCustomer = async (Customer) => {
            // console.log(Customer);
            editMode.value = true;
            create_contact_form.value.Id = Customer.id;
            create_contact_form.value.create_contact_list_id = Customer.list_id;
            create_contact_form.value.create_contact_first_name = Customer.first_name;
            create_contact_form.value.create_contact_last_name = Customer.last_name;
            create_contact_form.value.create_contact_email = Customer.email;
            create_contact_form.value.create_contact_phone = Customer.phone;
            create_contact_form.value.create_contact_company = Customer.company;
            create_contact_form.value.create_contact_address = Customer.address;
            removeClass('.form-control', 'red-border');
            $('#create_contact').modal({
                backdrop: 'static',
                keyboard: false
            });
        }
        const updateCustomer = async (Id) => {
            window.showHideMainLoader(true);
            removeClass('.form-control', 'red-border');
            let validate = 1;
            // console.log(validate);
            if (create_contact_form.value.create_contact_first_name == '') {
                validate = 0;
                addClass('#create_contact_first_name', 'red-border');
                showToast('error', 'First name is required');
                return false;
            }
            if (create_contact_form.value.create_contact_last_name == '') {
                validate = 0;
                addClass('#create_contact_last_name', 'red-border');
                showToast('error', 'Last name is required');
                return false;
            }
            if (create_contact_form.value.create_contact_email == '') {
                validate = 0;
                addClass('#create_contact_email', 'red-border');
                showToast('error', 'Email address is required');
                return false;
            }
            if (create_contact_form.value.create_contact_phone == '') {
                validate = 0;
                addClass('#create_contact_phone', 'red-border');
                showToast('error', 'Phone no. is required');
                return false;
            } else if (create_contact_form.value.create_contact_phone.length < 10) {
                validate = 0;
                addClass('#create_contact_phone', 'red-border');
                showToast('error', 'Phone number is invalid2');
                return false;
            } else if (!isPhoneValid(create_contact_form.value.create_contact_phone)) {
                validate = 0;
                addClass('#create_contact_phone', 'red-border');
                showToast('error', 'Phone number is invalid3');
                return false;
            }
            if (create_contact_form.value.create_contact_address == '') {
                validate = 0;
                addClass('#create_contact_address', 'red-border');
                showToast('error', 'Address is required');
                return false;
            }
            if (validate == 1) {
                let addFormData = new FormData();
                // addFormData.append('image', teamuser_image);
                addFormData.append('dealer_id', localStorage.getItem('dealer_id'));
                addFormData.append('location_id', localStorage.getItem('location_id'));
                addFormData.append('list_id', create_contact_form.value.create_contact_list_id);
                addFormData.append('first_name', create_contact_form.value.create_contact_first_name);
                addFormData.append('last_name', create_contact_form.value.create_contact_last_name);
                addFormData.append('email', create_contact_form.value.create_contact_email);
                addFormData.append('phone', create_contact_form.value.create_contact_phone);
                addFormData.append('company', create_contact_form.value.create_contact_company);
                addFormData.append('address', create_contact_form.value.create_contact_address);
                // console.log(teamuser_image);
                await axios
                    .post(
                        axios.defaults.baseUrl + "customers/update/" + Id,
                        addFormData, {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("auth_token"),
                            },
                        }
                    )
                    .then((response) => {
                        showToast(response.data.status, response.data.message);
                        getAllCustomers($('#listId').val());
                        closeModal();
                        create_contact_form.value = '';
                        location.reload(); //-----remove later
                        window.showHideMainLoader(false);

                    })
                    .catch((error) => {
                        window.showHideMainLoader(false);
                        showToast(error.response.data.status, error.response.data.message);
                        return false;
                    });
            }

        }
        
        const uploadCustomerCancel = async () => {
            $('.disclaimer-btn').show();
            $('.import-csv-btn').hide();
            $('.copy-csv-btn').hide();
            $('#v-pills-disclaimer-tab').removeClass('disabled');
            $('#v-pills-upload-tab').addClass('disabled');
            $('#v-pills-copy-tab').addClass('disabled');
            $('#v-pills-disclaimer-tab').click();
            $('#v-pills-disclaimer-tab').addClass('disabled');
            $('#iAgreeCustomer').prop('checked',false)
        }
        const disclaimerNext = async () => {
            if($('#iAgreeCustomer').is(':checked')){
                $('#v-pills-upload-tab').removeClass('disabled');
                $('#v-pills-copy-tab').removeClass('disabled');
                $('.disclaimer-btn').hide();
                $('.import-csv-btn').show();
                $('.copy-csv-btn').hide();
                $('#v-pills-upload-tab').click();
            }else{
                showToast('error', 'Please agree to terms of services to continue!');
            }
        }
        const uploadCustomerCSV = async () => {
            let validate = 1;
            // console.log(validate);
            if (select_list_id_upload.value == 'new') {
                if (selectListNameUpload.value == '') {
                    validate = 0;
                    addClass('#uploadListName', 'red-border');
                    showToast('error', 'List Name is required');
                    return false;
                }
            }
            if (customer_csv == '') {
                validate = 0;
                addClass('.upload-csv-btn-wrapper', 'red-border');
                showToast('error', 'Customer CSV file is required');
                return false;
            }
            // console.log(customer_csv.name);
            if (validate == 1) {
                window.showHideMainLoader(true);
                let addFormData = new FormData();
                addFormData.append('dealer_id', localStorage.getItem('dealer_id'));
                addFormData.append('location_id', localStorage.getItem('location_id'));
                addFormData.append('csv_file', customer_csv);
                if (select_list_id_upload.value == 'new') {
                    addFormData.append('list_id', 'new');
                    addFormData.append('list_title', selectListNameUpload.value);
                } else if (select_list_id_upload.value > 0) {
                    addFormData.append('list_id', select_list_id_upload.value);
                }
                // console.log(customer_csv);
                await axios
                    .post(
                        axios.defaults.baseUrl + "customers/csv_upload",
                        addFormData, {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("auth_token"),
                            },
                        }
                    )
                    .then((response) => {
                        showToast(response.data.status, response.data.message);
                        csvCustomers.value = response.data.data.csvCustomers;
                        select_list_id_upload.value = response.data.data.list_id;
                        $('#disply_csv_uploaded_contact').modal('show');
                        setTimeout(() => {
                            $("#csv-records-table").dataTable({
                                columnDefs: [{
                                        targets: [0],
                                        orderable: true
                                    },
                                    {
                                        targets: [1],
                                        orderable: true
                                    },
                                    {
                                        targets: [2],
                                        orderable: true
                                    },
                                    {
                                        targets: [3],
                                        orderable: true
                                    },
                                    {
                                        targets: [4],
                                        orderable: false,
                                        searchable: false
                                    }
                                ]
                            });
                        }, 500);
                        // getAllCustomers();
                        closeModal();
                        customer_csv = '';
                        tabType.value = 'upload';
                        window.showHideMainLoader(false);
                    })
                    .catch((error) => {
                        window.showHideMainLoader(false);
                        showToast(error.response.data.status, error.response.data.message);
                        return false;
                    });
            }
        }
        const parseCustomerCSV = async () => {
            let validate = 1;
            // console.log(validate);
            if (select_list_id_copy.value == 'new') {
                if (selectListNameCopy.value == '') {
                    validate = 0;
                    addClass('#copyListId', 'red-border');
                    showToast('error', 'List Name is required');
                    return false;
                }
            }
            if (csv_copied == '') {
                validate = 0;
                // addClass('.upload-csv-btn-wrapper', 'red-border');
                showToast('error', 'No record found');
                return false;
            }
            if (validate == 1) {
                // console.log(customer_csv);
                let copyFormData = new FormData();
                copyFormData.append('dealer_id', localStorage.getItem('dealer_id'));
                copyFormData.append('location_id', localStorage.getItem('location_id'));
                copyFormData.append('csv_file', csv_copied);
                if (select_list_id_copy.value == 'new') {
                    copyFormData.append('list_id', 'new');
                    copyFormData.append('list_title', selectListNameCopy.value);
                } else if (select_list_id_copy.value > 0) {
                    copyFormData.append('list_id', select_list_id_copy.value);
                }
                // console.log(csv_copied);
                await axios
                    .post(
                        axios.defaults.baseUrl + "customers/csv_copy",
                        copyFormData, {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("auth_token"),
                            },
                        }
                    )
                    .then((response) => {
                        showToast(response.data.status, response.data.message);
                        csvCustomers.value = response.data.data.csvCustomers;
                        $('#disply_csv_uploaded_contact').modal('show');
                        setTimeout(() => {
                            $("#csv-records-table").dataTable({
                                columnDefs: [{
                                        targets: [0],
                                        orderable: true
                                    },
                                    {
                                        targets: [1],
                                        orderable: true
                                    },
                                    {
                                        targets: [2],
                                        orderable: true
                                    },
                                    {
                                        targets: [3],
                                        orderable: true
                                    },
                                    {
                                        targets: [4],
                                        orderable: false,
                                        searchable: false
                                    }
                                ]
                            });
                        }, 500);
                        closeModal();
                        customer_csv = '';
                        window.showHideMainLoader(false);
                    })
                    .catch((error) => {
                        window.showHideMainLoader(false);
                        showToast(error.response.data.status, error.response.data.message);
                        return false;
                    });
            }
        }
        const onPaste = async (event) => {
            const thisval = event.clipboardData.getData('Text');
            if (thisval.trim().length > 0) {
                csv_copied = thisval.trim();
                $('#copypaste_csv_textarea').hide();
                $('#copypaste_csv_input').show();
            }
        }
        const removeCopiedTextaraeaValue = async () => {
            csv_copied = '';
            $('#copypaste_csv_textarea').show();
            $('#copypaste_csv_input').hide();
        }
        const handleMDeleteCsvUploadedUserRequest = async (Id) => {
            // console.log(id);
            let addFormData = new FormData()
            addFormData.append('record_id', Id);
            // console.log(customer_csv);
            await axios
                .post(
                    axios.defaults.baseUrl + "customers/delete_csv_upload",
                    addFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
                .then((response) => {
                    window.toast.fire({
                        icon: response.data.status,
                        title: response.data.message,
                    });
                    // console.log(response.data.data.csvCustomers);
                    csvCustomers.value = response.data.data.csvCustomers;
                    $(".display-csv-records").addClass("nowrap").dataTable();
                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        const importCsvUsersFromTemp = async () => {
            // console.log(select_list_id_upload.value);
            // console.log(select_list_id_copy.value);
            // console.log(id);
            // console.log(tabType);
            // console.log(tabType.value);
            let SelectFormData = new FormData;
            SelectFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            SelectFormData.append('location_id', localStorage.getItem('location_id'));
            // SelectFormData.append('list_id', 0);
            let list_id_from = tabType.value;
            if (list_id_from == 'upload') {
                SelectFormData.append('list_id', select_list_id_upload.value);
                // if (select_list_id_upload.value == 'new') {
                //     SelectFormData.append('list_id', 'new');
                //     SelectFormData.append('list_title', selectListNameUpload.value);
                // } else if (select_list_id_upload.value > 0) {
                //     SelectFormData.append('list_id', select_list_id_upload.value);
                // }
            } else {
                SelectFormData.append('list_id', select_list_id_copy.value);
                // if (select_list_id_copy.value == 'new') {
                //     SelectFormData.append('list_id', 'new');
                //     SelectFormData.append('list_title', selectListNameCopy.value);
                // } else if (select_list_id_copy.value > 0) {
                //     SelectFormData.append('list_id', select_list_id_copy.value);
                // }
            }
            window.showHideMainLoader(true);
            await axios
                .post(
                    axios.defaults.baseUrl + "customers/copy_from_temp",
                    SelectFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
                .then((response) => {
                    showToast(response.data.status, response.data.message);
                    // getCustomersList();
                    getAllCustomers(listId.value);
                    closeModal();
                    // location.reload(); //-----remove later
                    window.showHideMainLoader(false);
                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        const user_csvInput = async (event) => {
            // alert();
            $('.csv_upload_file_name').hide();
            removeClass('.upload-csv-btn-wrapper', 'red-border');
            if (event.target.files && event.target.files[0]) {
                console.log(event.target.files[0].name);
                customer_csv = event.target.files[0];
                customer_csv_upload_file_name.value = customer_csv.name;
                // alert();
                $('.csv_upload_file_name').show();
            }
        }
        const deleteCustomer = async (Id) => {
            await axios
                .delete(axios.defaults.baseUrl + "customers/delete/" + Id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem('auth_token'),
                    },
                })
                .then((response) => {
                    window.toast.fire({
                        icon: response.data.status,
                        title: response.data.message,
                    });
                    getAllCustomers($('#listId').val());

                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        const changeListId = async (event) => {
            // const listFormData = new FormData();
            // listFormData.append('list_id', event.target.value);
            // console.log(event.target.value);
            // console.log($('#listId').val());
            listId.value = event.target.value;
            getAllCustomers(event.target.value);
        }
        const selectListIdUpload = async (event) => {
            // const listFormData = new FormData();
            // listFormData.append('list_id', event.target.value);
            // console.log(event.target.value);
            // console.log($('#listId').val());
            let list_id = event.target.value;
            if (list_id == 'new') {
                $('.select-list-id-upload').show();
            } else {
                $('.select-list-id-upload').hide();
                selectListNameUpload.value = '';
            }
        }
        const selectListIdCopy = async (event) => {
            // const listFormData = new FormData();
            // listFormData.append('list_id', event.target.value);
            // console.log(event.target.value);
            // console.log($('#listId').val());
            let list_id = event.target.value;
            if (list_id == 'new') {
                $('.select-list-id-copy').show();
            } else {
                $('.select-list-id-copy').hide();
                selectListNameCopy.value = '';
            }
        }
        const importButton = () => {
            if(!$('#iAgreeCustomer').is(':checked')){
                showToast('error', 'Please agree to terms of services to continue!');
                return false;
            }
            $(".import-csv-btn").show();
            $(".copy-csv-btn").hide();
        };
        const copyButton = () => {
            $(".import-csv-btn").hide();
            $(".copy-csv-btn ").show();
        };
        const addClass = (elementName, className) => {
            $(elementName).addClass(className);
        }
        const removeClass = (elementName, className) => {
            $(elementName).removeClass(className);
        }
        const showToast = (toastIcon, toastMessage) => {
            window.toast.fire({
                icon: toastIcon,
                title: toastMessage,
            });
        }
        const isPhoneValid = (phone) => {
            let regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            return regex.test(phone);
        }
        const sendSMSpopup = (CustomerData) => {
            console.log(CustomerData);
            sendSMSValues.value.sms_customer_id = CustomerData.customer_id;
            sendSMSValues.value.sms_customer_name = CustomerData.first_name + ' ' + CustomerData.last_name;
            sendSMSValues.value.sms_customer_phone = '+' + CustomerData.country_code + ' ' + CustomerData.phone;
            sendSMSValues.value.sms_customer_email = CustomerData.email;
            sendSMSValues.value.sms_message = '';
            $("#sendSMSpopup").modal("show");
        }
        const sendMessageToCustomer = async () => {
            if (sendSMSValues.value.sms_message == '') {
                showToast('error', 'Message cannot be empty');
                return false;
            }
            window.showHideMainLoader(true);
            const postFormData = new FormData();
            postFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            postFormData.append('location_id', localStorage.getItem('location_id'));
            postFormData.append('customer_id', sendSMSValues.value.sms_customer_id);
            postFormData.append('message', sendSMSValues.value.sms_message);
            await axios
                .post(
                    axios.defaults.baseUrl + "sendMessageToCustomer",
                    postFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    window.showHideMainLoader(false);
                    showToast(response.data.status, response.data.message);
                    location.assign('/inbox/all/' + response.data.data.chat_id);
                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        const optUser = async (user_id = null, opt_type = null) => {
            window.showHideMainLoader(true);
            // console.log(formData);
            if (user_id != null && opt_type != null) {
                // console.log('here');
                let optUserFormData = new FormData();
                optUserFormData.append('user_id', user_id);
                optUserFormData.append('opt_type', opt_type);
                optUserFormData.append('dealer_id', localStorage.getItem('dealer_id'));
                optUserFormData.append('location_id', localStorage.getItem('location_id'));
                await axios
                    .post(
                        axios.defaults.baseUrl + "customers/optUser",
                        optUserFormData, {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("auth_token"),
                            },
                        }
                    )
                    .then((response) => {
                        console.log(response.data);
                        window.showHideMainLoader(false);
                        showToast(response.data.status, response.data.message);
                        location.reload(); //-----remove later
                    })
                    .catch((error) => {
                        // console.log(error);
                        window.showHideMainLoader(false);
                        showToast(error.response.data.status, error.response.data.message);
                        return false;
                    });
            }
        }
        // Open Modal
        const openModal = () => {
            $("#featureNotAvailable").modal("show");
        };
        // Close Modal
        const closeModal = () => {
            $("#create_contact").modal("hide");
            $("#upload_contacts").modal("hide");
            $("#disply_csv_uploaded_contact").modal("hide");
            $("#create-appointment").modal("hide");
        };
        const getAllCustomers = async (list_id = '') => {
            if ($.fn.DataTable.isDataTable("#dataTable")) {
                $("#dataTable").DataTable().clear().destroy();
            }
            $('#dataTable').DataTable({
                "processing": true,
                "serverSide": true,
                language: {
                    emptyTable: "No matching records found",
                    lengthMenu: "Show _MENU_ records per page",
                    zeroRecords: "",
                    info: "Showing _START_ to _END_ of _TOTAL_ records",
                    infoEmpty: "No records available",
                    infoFiltered: "(filtered from _MAX_ total records)",
                },
                "ajax": {
                    "url": axios.defaults.baseUrl + "customers/dt",
                    "type": "POST", // Assuming your API endpoint expects a POST request
                    "headers": {
                        Authorization: "Bearer " + localStorage.getItem('auth_token'),
                    },
                    "data": function (d) {
                        // You can add additional parameters or modify the request data here
                        return $.extend({}, d, {
                            'dealer_id': localStorage.getItem('dealer_id'),
                            'location_id': localStorage.getItem('location_id'),
                            'list_id': list_id.value,
                        });
                    },
                    dataSrc: function (response) {
                        // Transform the data here before DataTables processes it
                        AllCustomers.value = response.data;
                        return [];
                    },
                },
                "searching": true,
                "order": [],
                "columns": [],
            });
            $(".display").addClass("nowrap").dataTable();
        }
        onMounted(() => {

            // console.log(Params);

            getCustomersList();
            // getAllCustomers(Params.params.id);
            if (Params.params.id != null) {
                listId.value = Params.params.id;
                select_list_id_upload.value = Params.params.id;
                select_list_id_copy.value = Params.params.id;
                if (typeof Params.params.new !== "undefined" && Params.params.new != null && Params.params.new == 'new') {
                    $("#upload_contacts").modal("show");
                }
            }
            getAllCustomers(listId);
            // console.log('param'+Params.params.new);

            window.showHideMainLoader(false);
        });

        return {
            openModal,
            closeModal,
            base_url,
            AllCustomers,
            AllCustomersLists,
            create_contact_form,
            addCustomer,
            editCustomer,
            updateCustomer,
            user_csvInput,
            disclaimerNext,
            uploadCustomerCSV,
            csvCustomers,
            uploadCustomerCancel,
            handleMDeleteCsvUploadedUserRequest,
            deleteCustomer,
            importCsvUsersFromTemp,
            importButton,
            copyButton,
            parseCustomerCSV,
            copy_contact_form,
            onPaste,
            removeCopiedTextaraeaValue,
            // listId,
            changeListId,
            selectListIdUpload,
            selectListIdCopy,
            select_list_id_upload,
            select_list_id_copy,
            selectListNameUpload,
            selectListNameCopy,
            listId,
            removeClass,
            addClass,
            showToast,
            editMode,
            tabType,
            sendSMSpopup,
            sendSMSValues,
            sendMessageToCustomer,
            optUser,

            createAppointment,
            newAppointment,
            addAppointmentRequest,
            getCurrentTime,

            getAllCustomers,
            customer_csv_upload_file_name,
        };
    },
};
</script>
