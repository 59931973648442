<template>
<div>
    <!-- loader -->
    <Loader />
    <!-- loader -->

    <!-- login-area -->
    <section class="login-area">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="form-area">
                        <div class="login-form">
                            <div class="form-logo">
                                <router-link to="/"><img class="img-fluid" src="@/assets/img/logo.svg" alt="logo"></router-link>
                                <h3>Login to your account</h3>
                            </div>
                            <form @submit.prevent="loginSubmit" class="wow fadeInRight" data-wow-duration="1s" data-wow-delay="1s">
                                <label for="email">
                                    <p class="label-txt label-active">Email address</p>
                                    <svg class="log-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <title>icons/stroke/email</title>
                                        <defs>
                                            <path d="M19,18 L5,18 C4.449,18 4,17.552 4,17 L4,7.25 L11.4,12.8 C11.578,12.934 11.789,13 12,13 C12.211,13 12.422,12.934 12.6,12.8 L20,7.25 L20,17 C20,17.552 19.551,18 19,18 L19,18 Z M18.333,6 L12,10.75 L5.667,6 L18.333,6 Z M19,4 L5,4 C3.346,4 2,5.346 2,7 L2,17 C2,18.654 3.346,20 5,20 L19,20 C20.654,20 22,18.654 22,17 L22,7 C22,5.346 20.654,4 19,4 L19,4 Z" id="path-1" />
                                        </defs>
                                        <g id="icons/stroke/email" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <mask id="mask-2" fill="white">
                                                <use xlink:href="#path-1" />
                                            </mask>
                                            <use id="🎨-Icon-Сolor" fill="#180660" xlink:href="#path-1" />
                                        </g>
                                    </svg>
                                    <input type="email" v-model="loginParams.email" class="input input-active" required />
                                </label>
                                <label for="password">
                                    <p class="label-txt label-active">Password</p>
                                    <img class="img-fluid log-icon" src="@/assets/img/lock.svg" alt="image title">
                                    <input :type="pType" v-model="loginParams.password" class="input input-active" required />
                                    <img class="img-fluid show-eye" src="@/assets/img/eye.svg" alt="" @click="showPass" />
                                    <img class="img-fluid hide-eye" src="@/assets/img/eyeclose.svg" alt="" @click="hidePass" style="display: none" />
                                </label>
                                <label class="checkboxss">Remember me
                                    <input type="checkbox" v-model="loginParams.remember_me" value="1" />
                                    <span class="checkmark"></span>
                                    <!--<a class="forgot" href="forgot.html">Forgot Password</a>-->
                                </label>
                                <button type="submit" class="login-btns" :disabled="loading">Login
                                    <font-awesome-icon icon="fa-solid fa-spinner" spin v-if="loading" />
                                </button>
                            </form>
                            <p class="dont-acc">Don't have an account? <router-link to="/register">Sign up</router-link>
                            </p>
                            <div class="other-login">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- login-area -->
</div>
</template>

<script>
import $ from "jquery";
import {
    ref,
    onMounted
} from "vue";
import {
    useRouter
} from 'vue-router';
import Loader from "@/components/Loader";
import axios from "axios";
// console.log(localStorage);
export default {
    components: {
        Loader
    },
    setup() {
        let base_url = "http://api.streamly.com/uploads/";
        const router = useRouter();
        const pType = ref('password');
        let loginParams = ref({
            email: '',
            password: '',
            remember_me: 0
        });

        const loginSubmit = async () => {
            $('.login-btns').attr('disabled', true);
            const postFormData = new FormData();
            postFormData.append('email', loginParams.value.email);
            postFormData.append('password', loginParams.value.password);
            postFormData.append('remember_me', loginParams.value.remember_me);
            await axios
                .post(axios.defaults.baseUrl + "login",
                    postFormData
                )
                .then((response) => {
                    // console.log(response);
                    localStorage.setItem("user_id", response.data.data.id);
                    localStorage.setItem("first_name", response.data.data.first_name);
                    localStorage.setItem("last_name", response.data.data.last_name);
                    localStorage.setItem("user_type", response.data.data.user_type);
                    localStorage.setItem("dealer_id", response.data.data.dealer_id);
                    localStorage.setItem("location_id", response.data.data.location_id);
                    localStorage.setItem("location", response.data.data.location);
                    localStorage.setItem("location_address", response.data.data.location_address);
                    localStorage.setItem("business_name", response.data.data.business_name);
                    if (response.data.data.login_status == 'superadmin') {
                        localStorage.setItem("auth_token", response.data.data.access_token);
                        console.log(localStorage);
                        router.push("/admin/dashboard");
                    } else if (response.data.data.login_status == 'otp') {
                        sessionStorage.setItem("auth_token", response.data.data.access_token);
                        sessionStorage.setItem("otp_number", response.data.data.two_factor_number);
                        router.push('/login/two-factor-authentication-check');
                    } else if (response.data.data.login_status == 'number') {
                        sessionStorage.setItem("auth_token", response.data.data.access_token);
                        router.push('/login/phoneNumber');
                    } else if (response.data.data.login_status == 'okay') {
                        localStorage.setItem("auth_token", response.data.data.access_token);
                        router.push('/inbox/all');
                    }
                    window.showHideMainLoader(false);
                    // $('.login-btns').removeAttr('disabled');
                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    $('.login-btns').removeAttr('disabled');
                    return false;
                });
        }
        const showPass = () => {
            pType.value = "text";
            $(".hide-eye").show();
            $(".show-eye").hide();
        };
        const hidePass = () => {
            pType.value = "password";
            $(".hide-eye").hide();
            $(".show-eye").show();
        };
        const addClass = (elementName, className) => {
            $(elementName).addClass(className);
        }
        const removeClass = (elementName, className) => {
            $(elementName).removeClass(className);
        }
        const showToast = (toastIcon, toastMessage) => {
            window.toast.fire({
                icon: toastIcon,
                title: toastMessage,
            });
        }
        const openModal = () => {};
        const closeModal = () => {};
        onMounted(() => {
            setTimeout(() => {
                window.showHideMainLoader(false);
            }, 500);
        });

        return {
            openModal,
            closeModal,
            base_url,
            loginSubmit,
            addClass,
            removeClass,
            showToast,

            showPass,
            hidePass,
            loginParams,
            pType
        };
    },
};
</script>
