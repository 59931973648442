<template>

    <!-- search-top -->
    <div class="search-top">
      <div class="row">
        <div class="col-md-6 col-lg-4">
          <div class="search-box">
            <form>
              <div class="form-group">
                <input type="search" class="form-control" v-model="search" @keyup="filterDataTable"
                       placeholder="Search" aria-label="search">
                <img class="img-fluid sea-icon" src="@/assets/img/search.svg" alt="image title">
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6 col-lg-8">
          <div class="export-right">
            <div class="export">
              <a href="#"> <img class="img-fluid" src="@/assets/img/export.svg" alt="image title"> Export</a>
            </div>
            <CreateLocation/>

            <!--            <div class="todayselect">-->
            <!--              <div class="reportrange">-->
            <!--                This Month <i class="fa fa-angle-down" aria-hidden="true"></i>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <!-- search-top -->

    <!-- review-table -->
    <div class="review-table">

      <table class="display" id="dataTable">
        <thead>
        <tr>
          <th>Location</th>
          <th>Rating</th>
          <th>Reviews</th>
          <th>Invites: <span class="mul"> Sent / Clicked / CTR %</span></th>
          <th>Monthly Review Goal: <span class="mul"> Goal / Received / Goal %</span></th>
        </tr>
        </thead>
        <tbody>


        </tbody>
      </table>
    </div>
    <!-- review-table -->

</template>


<script setup>

// import apiRequest from "@/use/api";
import $ from "jquery";
import {onMounted, watch  ,ref} from "vue";
import CreateLocation from "@/components/Location/CreateLocation"
import {useLocationsStore} from "@/stores/location"
import {storeToRefs} from 'pinia'
import {starsTemplate} from "@/components/analytics/StarsTemplate";


const store = useLocationsStore();
const {locations, is_loading, is_updated_or_created_loading} = storeToRefs(store)

const search = ref("");

watch(is_loading, (is_loading) => {


  if (is_loading) { ///  if  success login
    datatableInit();
  }

})
watch(is_updated_or_created_loading, (is_updated_or_created_loading) => {


  if (!is_updated_or_created_loading) { ///  if  success login
    datatableInit();
  }

})

const datatableInit = () => {


  if ($.fn.DataTable.isDataTable("#dataTable")) {
    // $("#dataTable").DataTable().clear().destroy();
    // $('#dataTable').dataTable().fnClearTable();
    $('#dataTable').dataTable().fnDestroy();
    // $("#dataTable").DataTable().draw();
    // $("#dataTable").dataTable().draw();
    // $('#dataTable').DataTable().draw();


  }




  $("#dataTable").addClass("nowrap").dataTable({
    order: [],
    columnDefs: [
      {targets: [0, 1, 2, 3, 4], orderable: false}
    ],

    data: locations.value,

    columns: [
      {
        data: 'title', "render": function (data, type, row) {
          return `${row.title} <p>${row.address}</p>`;
        }
      },
      {
        data: 'rate', "render": function (data, type, row) {
          //    return //` <span class="mul">${ row.rate }</span>  `  +
          return starsTemplate(row.rate);
        }
      },
      {data: 'count_reviews'},
      {
        data: 'invite_clicked', "render": function (data, type, item) {
          //    return //` <span class="mul">${ row.rate }</span>  `  +
          return `
           <div class="scc">
              <p>${item.invite_sends}</p>
              <p>${item.invite_clicked}</p>
              <p>${item.invite_clicked === 0 ? 0 : ((item.invite_clicked / item.invite_sends) * 100).toFixed(2)}%</p>
            </div>
          `;
        }
      },
      {
        data: 'monthly_goals', "render": function (data, type, item) {
          //    return //` <span class="mul">${ row.rate }</span>  `  +
          return `
           <div class="scc">
              <p>${item.monthly_goals}</p>
              <p>${item.monthly_goals_received}</p>
              <p>${item.monthly_goals === 0 ? 0 : ((item.monthly_goals_received / item.monthly_goals) * 100).toFixed(2)}%</p>
            </div>
          `;
        }
      },
    ]

  });
}


const filterDataTable = () => {

  $("#dataTable").DataTable().search( search.value ).draw()

}

onMounted(() => {

  store.getListLocations();
  if (is_loading)
    datatableInit();


  window.showHideMainLoader(false);

});
</script>


