<template>

  <!-- search-top -->
  <div class="search-top">
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="search-box">
          <form>
            <div class="form-group">
              <input type="search" class="form-control" placeholder="Search" aria-label="search">
              <img class="img-fluid sea-icon" src="@/assets/img/search.svg" alt="image title">
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-6 col-lg-8">
        <div class="export-right">
          <div class="export">
            <a href="#"> <img class="img-fluid" src="@/assets/img/export.svg" alt="image title"> Export</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- search-top -->


  <!-- reviews-area -->
  <div class="reviews-area">
    <div class="row">
      <div class="col-md-8 col-lg-8 col-xl-9">
        <!-- reviews -->
        <div class="reviews">
          <div class="media">
            <img src="@/assets/img/man.png" class="mr-3 img-fluid" alt="image title">
            <div class="media-body">
              <h5 class="mt-0">Nusaiba Shah
                <ul class="ratestar">
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                </ul>
                <span class="rate">5.0</span>
                <div class="assign">
                  <ul>
                    <li>
                      <a href="#" data-toggle="modal" data-target="#exampleModal6"><img class="img-fluid"
                                                                                        src="@/assets/img/man.png"
                                                                                        alt="image title">Ameri Vanu
                      </a>
                    </li>
                    <li>
                      <a href="#"> <span class="unassign"> </span> <img class="img-fluid" src="@/assets/img/plus1.svg"
                                                                        alt="image title"> </a>
                    </li>
                  </ul>
                </div>
              </h5>
              <h6>2 Days ago from <a href="#">Google</a></h6>
              <p>Make ni,w nyoba2 tp gabgt di komuk lgsg jerawatan. di emak gw mah fine2 aja ga ngpa2 gw suru ganti
                produk yg laen dia gamao yauda lah sekarepnya bae drpd ngelawan durhaka</p>

              <div class="reviewreply">
                <div class="reply-herd">
                  <h4><img class="img-fluid" src="@/assets/img/reply-message.svg" alt="image title"> Replied <span
                      class="time">Yesterday</span> <a class="edit" href="#">Edit</a></h4>
                  <p class="rereply-con"> Thank you so much!</p>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!-- reviews -->

        <!-- reviews -->
        <div class="reviews">
          <div class="media">
            <img src="@/assets/img/man.png" class="mr-3 img-fluid" alt="image title">
            <div class="media-body">
              <h5 class="mt-0">Nusaiba Shah
                <ul class="ratestar">
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                </ul>
                <span class="rate">5.0</span>
                <div class="assign">
                  <ul>
                    <li>
                      <a href="#" data-toggle="modal" data-target="#exampleModal6"> <span
                          class="unassign"> Unassign</span> <img class="img-fluid" src="@/assets/img/plus1.svg"
                                                                 alt="image title"> </a>
                    </li>
                  </ul>
                </div>
              </h5>
              <h6>2 Days ago from <a href="#">Google</a></h6>
              <p>Make ni,w nyoba2 tp gabgt di komuk lgsg jerawatan. di emak gw mah fine2 aja ga ngpa2 gw suru ganti
                produk yg laen dia gamao yauda lah sekarepnya bae drpd ngelawan durhaka</p>

              <div class="reviewreply">
                <h6 class="reply">Reply
                  <div class="reply-box">
                    <form>
                      <div class="form-row">
                        <div class="col-8">
                          <input type="text" class="form-control" placeholder="Type.....">
                        </div>
                        <div class="col-4">
                          <button type="submit" class="send-btn"> Submit</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </h6>
              </div>

            </div>
          </div>
        </div>
        <!-- reviews -->

        <!-- reviews -->
        <div class="reviews">
          <div class="media">
            <img src="@/assets/img/man.png" class="mr-3 img-fluid" alt="image title">
            <div class="media-body">
              <h5 class="mt-0">Nusaiba Shah
                <ul class="ratestar">
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                </ul>
                <span class="rate">5.0</span>
                <div class="assign">
                  <ul>
                    <li>
                      <a href="#" data-toggle="modal" data-target="#exampleModal6"> <span
                          class="unassign"> Unassign</span> <img class="img-fluid" src="@/assets/img/plus1.svg"
                                                                 alt="image title"> </a>
                    </li>
                  </ul>
                </div>
              </h5>
              <h6>2 Days ago from <a href="#">Google</a></h6>
              <p>Make ni,w nyoba2 tp gabgt di komuk lgsg jerawatan. di emak gw mah fine2 aja ga ngpa2 gw suru ganti
                produk yg laen dia gamao yauda lah sekarepnya bae drpd ngelawan durhaka</p>

              <div class="reviewreply">
                <h6 class="reply">Reply
                  <div class="reply-box">
                    <form>
                      <div class="form-row">
                        <div class="col-8">
                          <input type="text" class="form-control" placeholder="Type.....">
                        </div>
                        <div class="col-4">
                          <button type="submit" class="send-btn"> Submit</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </h6>
              </div>

            </div>
          </div>
        </div>
        <!-- reviews -->

        <!-- reviews -->
        <div class="reviews">
          <div class="media">
            <img src="@/assets/img/man.png" class="mr-3 img-fluid" alt="image title">
            <div class="media-body">
              <h5 class="mt-0">Nusaiba Shah
                <ul class="ratestar">
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                </ul>
                <span class="rate">5.0</span>
                <div class="assign">
                  <ul>
                    <li>
                      <a href="#" data-toggle="modal" data-target="#exampleModal6"> <span
                          class="unassign"> Unassign</span> <img class="img-fluid" src="@/assets/img/plus1.svg"
                                                                 alt="image title"> </a>
                    </li>
                  </ul>
                </div>
              </h5>
              <h6>2 Days ago from <a href="#">Google</a></h6>
              <p>Make ni,w nyoba2 tp gabgt di komuk lgsg jerawatan. di emak gw mah fine2 aja ga ngpa2 gw suru ganti
                produk yg laen dia gamao yauda lah sekarepnya bae drpd ngelawan durhaka</p>

              <div class="reviewreply">
                <h6 class="reply">Reply
                  <div class="reply-box">
                    <form>
                      <div class="form-row">
                        <div class="col-8">
                          <input type="text" class="form-control" placeholder="Type.....">
                        </div>
                        <div class="col-4">
                          <button type="submit" class="send-btn"> Submit</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </h6>
              </div>

            </div>
          </div>
        </div>
        <!-- reviews -->

        <!-- reviews -->
        <div class="reviews">
          <div class="media">
            <img src="@/assets/img/man.png" class="mr-3 img-fluid" alt="image title">
            <div class="media-body">
              <h5 class="mt-0">Nusaiba Shah
                <ul class="ratestar">
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                </ul>
                <span class="rate">5.0</span>
                <div class="assign">
                  <ul>
                    <li>
                      <a href="#" data-toggle="modal" data-target="#exampleModal6"> <span
                          class="unassign"> Unassign</span> <img class="img-fluid" src="@/assets/img/plus1.svg"
                                                                 alt="image title"> </a>
                    </li>
                  </ul>
                </div>
              </h5>
              <h6>2 Days ago from <a href="#">Google</a></h6>
              <p>Make ni,w nyoba2 tp gabgt di komuk lgsg jerawatan. di emak gw mah fine2 aja ga ngpa2 gw suru ganti
                produk yg laen dia gamao yauda lah sekarepnya bae drpd ngelawan durhaka</p>

              <div class="reviewreply">
                <h6 class="reply">Reply
                  <div class="reply-box">
                    <form>
                      <div class="form-row">
                        <div class="col-8">
                          <input type="text" class="form-control" placeholder="Type.....">
                        </div>
                        <div class="col-4">
                          <button type="submit" class="send-btn"> Submit</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </h6>
              </div>

            </div>
          </div>
        </div>
        <!-- reviews -->

      </div>


      <div class="col-md-4 col-lg-4 col-xl-3">
        <div class="review-filter">
          <p>Filter</p>

          <h5><img class="img-fluid" src="@/assets/img/location-pin.svg" alt="image title"> Location</h5>
          <div class="form-group">
            <select class="form-control tags2">
              <option value="AL">AL</option>
              <option value="AK">AK</option>
              <option value="AZ">AZ</option>
              <option value="AR">AR</option>
              <option value="CA">CA</option>
              <option value="CO">CO</option>
              <option value="CT">CT</option>
              <option value="DE">DE</option>
              <option value="FL">FL</option>
              <option value="GA">GA</option>
              <option value="HI">HI</option>
              <option value="ID">ID</option>
              <option value="IL">IL</option>
              <option value="IN">IN</option>
              <option value="IA">IA</option>
              <option value="KS">KS</option>
              <option value="KY">KY</option>
              <option value="LA">LA</option>
              <option value="ME">ME</option>
              <option value="MD">MD</option>
              <option value="MA">MA</option>
              <option value="MI">MI</option>
              <option value="MN">MN</option>
              <option value="MS">MS</option>
              <option value="MO">MO</option>
              <option value="MT">MT</option>
              <option value="NB">NB</option>
              <option value="NE">NE</option>
              <option value="NS">NS</option>
              <option value="NV">NV</option>
              <option value="NH">NH</option>
              <option value="NJ">NJ</option>
              <option value="NM">NM</option>
              <option value="NY">NY</option>
              <option value="NC">NC</option>
              <option value="ND">ND</option>
              <option value="OH">OH</option>
              <option value="OK">OK</option>
              <option value="ON">ON</option>
              <option value="OR">OR</option>
              <option value="PA">PA</option>
              <option value="PE">PE</option>
              <option value="RI">RI</option>
              <option value="SC">SC</option>
              <option value="SD">SD</option>
              <option value="TN">TN</option>
              <option value="TX">TX</option>
              <option value="UT">UT</option>
              <option value="VT">VT</option>
              <option value="VA">VA</option>
              <option value="WA">WA</option>
              <option value="WV">WV</option>
              <option value="WI">WI</option>
              <option value="WY">WY</option>
            </select>
          </div>

          <h5><img class="img-fluid" src="@/assets/img/review.svg" alt="image title"> Reviews</h5>
          <div class="form-group">
            <select class="form-control">
              <option>All Reviews</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>

          <h5><img class="img-fluid" src="@/assets/img/calendar.svg" alt="image title"> Date Range</h5>
          <div class="form-group">
            <select class="form-control">
              <option>All Time</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>

          <h5><img class="img-fluid" src="@/assets/img/star.svg" alt="image title"> Ratings</h5>
          <div class="form-row">
            <div class="form-group col-6">
              <select class="form-control">
                <option>Min Rating</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>

            <div class="form-group col-6">
              <select class="form-control">
                <option>Max Rating</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
          </div>

          <h5><img class="img-fluid" src="@/assets/img/favorite.svg" alt="image title"> Review Site</h5>
          <div class="form-group">
            <select class="form-control">
              <option>Google</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>

          <h5><img class="img-fluid" src="@/assets/img/checkmark.svg" alt="image title"> Assignation</h5>
          <div class="form-group">
            <select class="form-control">
              <option>All reviews</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>


        </div>
      </div>
    </div>
  </div>
  <!-- reviews-area -->

</template>
<script>
import $ from "jquery";
import {onMounted} from "vue";

export default {
  components: {},
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    // Open Modal
    const openModal = () => {
      $("#featureNotAvailable").modal("show");
    };

    // Close Modal
    const closeModal = () => {
      $("#featureNotAvailable").modal("hide");
    };

    onMounted(() => {
      window.showHideMainLoader(false);
    });

    return {openModal, closeModal, base_url};
  },
};
</script>