<template>

  <!-- search-top -->
  <div class="search-top">
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="search-box">
          <form>
            <div class="form-group">
              <input type="search" class="form-control" placeholder="Search" aria-label="search">
              <img class="img-fluid sea-icon" src="@/assets/img/search.svg" alt="image title">
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-6 col-lg-8">
        <div class="create-meeting">
          <router-link :to="{ name: 'EmailCampaign' }"><img class="img-fluid" src="@/assets/img/pl.svg"
              alt="image title"> Back</router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- search-top -->

  <!-- review-table -->
  <div class="review-table">
    <table class="display" id="dataTable">
      <thead>
        <tr>
          <th>#</th>
          <th>Customer Name</th>
          <th>Opt-In Status</th>
          <th>Status</th>
          <th>Error</th>
          <th>Created</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(Item, key) in EmailCampaignDetail" :key="Item">
          <td>{{ key+ 1 }}</td>
          <td>{{ Item.first_name+' '+Item.last_name }}</td>
          <td><a class="invites" href="javascript:;">{{ (Item.optin_status == 1)?'Active':'Inactive' }}</a></td>
          <td>{{ Item.message_status }}s</td>
          <td>{{ Item.error }}</td>
          <td>{{ Item.created_at }}</td>
          <td>
            <ul class="actionbtn">
              <li><a href="javascript:;" data-toggle="modal" data-target="#viewmodal"> View</a></li>
            </ul>
          </td>
        </tr>

      </tbody>
    </table>
  </div>
  <!-- review-table -->

</template>
<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";

export default {
  components: {

  },
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    const Params = useRoute();
    let EmailCampaignDetail = ref([]);

    const getCampaignDetail = async (Id) => {
      const getFormData = new FormData();
      getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
      getFormData.append('location_id', localStorage.getItem('location_id'));
      await axios
        .post(axios.defaults.baseUrl + "campaigns/email/" + Id,
          getFormData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem('auth_token'),
            },
          }
        )
        .then((response) => {
          // console.log(response);
          EmailCampaignDetail.value = response.data.data.campaignDetail;
          // datatableInit();
        })
    }

    // Open Modal
    const openModal = () => {
      $("#featureNotAvailable").modal("show");
    };

    // Close Modal
    const closeModal = () => {
      $("#featureNotAvailable").modal("hide");
    };
    const datatableInit = async () => {
      if ($.fn.DataTable.isDataTable("#dataTable")) {
        $("#dataTable").DataTable().clear().destroy();
      }
      $("#dataTable").addClass("nowrap").dataTable({
        order: [],
        columnDefs: [
          { targets: [1], orderable: false }
        ]
      });
    }
    onMounted(() => {
      getCampaignDetail(Params.params.id);
      datatableInit();
      window.showHideMainLoader(false);
    });

    return {
      openModal,
      closeModal,
      base_url,
      EmailCampaignDetail,
      getCampaignDetail,
    };
  },
};
</script>