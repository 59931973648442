<template>

  <!-- search-top -->
  <div class="search-top">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div class="export-right">
          <div class="todayselect call-sort">
            <div class="reportrange">
              This Month <i class="fa fa-angle-down" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- search-top -->


  <!-- call-tracking  -->
  <div class="call-tracking">
    <nav>
      <div class="nav nav-tabs" id="nav-tab" role="tablist">
        <button class="nav-link active" id="nav-Facebook-tab" data-toggle="tab" data-target="#nav-Facebook"
                type="button" role="tab" aria-controls="nav-Facebook" aria-selected="true">Facebook
        </button>
        <button class="nav-link" id="nav-Google-tab" data-toggle="tab" data-target="#nav-Google" type="button"
                role="tab" aria-controls="nav-Google" aria-selected="false">Google
        </button>
        <button class="nav-link" id="nav-Website-tab" data-toggle="tab" data-target="#nav-Website" type="button"
                role="tab" aria-controls="nav-Website" aria-selected="false">Website
        </button>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="nav-Facebook" role="tabpanel" aria-labelledby="nav-Facebook-tab">
        <!-- review-table -->
        <div class="review-table">
          <table class="display">
            <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Phone</th>
              <th>Call Recording</th>
              <th>Duration</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>06-02-2022</td>
              <td>10:58</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-incoming.svg"
                                                       alt="image title"> +19095190565 <p
                  class="incoming">Incomming</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>0.45 min</td>
            </tr>
            <tr>
              <td>06-02-2022</td>
              <td>08:45</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-outgoing.svg"
                                                       alt="image title"> +19095190565 <p
                  class="outgoing">Outgoing</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>02:45 min</td>
            </tr>


            <tr>
              <td>06-02-2022</td>
              <td>08:45</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-missed.svg"
                                                       alt="image title"> +19095190565 <p
                  class="misedcall">Misedcall</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>12:10 min</td>
            </tr>
            <tr>
              <td>06-02-2022</td>
              <td>10:58</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-incoming.svg"
                                                       alt="image title"> +19095190565 <p
                  class="incoming">Incomming</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>0.45 min</td>
            </tr>
            <tr>
              <td>06-02-2022</td>
              <td>08:45</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-outgoing.svg"
                                                       alt="image title"> +19095190565 <p
                  class="outgoing">Outgoing</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>02:45 min</td>
            </tr>


            <tr>
              <td>06-02-2022</td>
              <td>08:45</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-missed.svg"
                                                       alt="image title"> +19095190565 <p
                  class="misedcall">Misedcall</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>12:10 min</td>
            </tr>
            <tr>
              <td>06-02-2022</td>
              <td>10:58</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-incoming.svg"
                                                       alt="image title"> +19095190565 <p
                  class="incoming">Incomming</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>0.45 min</td>
            </tr>
            <tr>
              <td>06-02-2022</td>
              <td>08:45</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-outgoing.svg"
                                                       alt="image title"> +19095190565 <p
                  class="outgoing">Outgoing</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>02:45 min</td>
            </tr>


            <tr>
              <td>06-02-2022</td>
              <td>08:45</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-missed.svg"
                                                       alt="image title"> +19095190565 <p
                  class="misedcall">Misedcall</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>12:10 min</td>
            </tr>


            </tbody>
          </table>
        </div>
        <!-- review-table -->
      </div>
      <div class="tab-pane fade" id="nav-Google" role="tabpanel" aria-labelledby="nav-Google-tab">

        <!-- review-table -->
        <div class="review-table">
          <table class="display">
            <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Phone</th>
              <th>Call Recording</th>
              <th>Duration</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>06-02-2022</td>
              <td>10:58</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-incoming.svg"
                                                       alt="image title"> +19095190565 <p
                  class="incoming">Incomming</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>0.45 min</td>
            </tr>
            <tr>
              <td>06-02-2022</td>
              <td>08:45</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-outgoing.svg"
                                                       alt="image title"> +19095190565 <p
                  class="outgoing">Outgoing</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>02:45 min</td>
            </tr>


            <tr>
              <td>06-02-2022</td>
              <td>08:45</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-missed.svg"
                                                       alt="image title"> +19095190565 <p
                  class="misedcall">Misedcall</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>12:10 min</td>
            </tr>
            <tr>
              <td>06-02-2022</td>
              <td>10:58</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-incoming.svg"
                                                       alt="image title"> +19095190565 <p
                  class="incoming">Incomming</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>0.45 min</td>
            </tr>
            <tr>
              <td>06-02-2022</td>
              <td>08:45</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-outgoing.svg"
                                                       alt="image title"> +19095190565 <p
                  class="outgoing">Outgoing</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>02:45 min</td>
            </tr>


            <tr>
              <td>06-02-2022</td>
              <td>08:45</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-missed.svg"
                                                       alt="image title"> +19095190565 <p
                  class="misedcall">Misedcall</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>12:10 min</td>
            </tr>
            <tr>
              <td>06-02-2022</td>
              <td>10:58</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-incoming.svg"
                                                       alt="image title"> +19095190565 <p
                  class="incoming">Incomming</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>0.45 min</td>
            </tr>
            <tr>
              <td>06-02-2022</td>
              <td>08:45</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-outgoing.svg"
                                                       alt="image title"> +19095190565 <p
                  class="outgoing">Outgoing</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>02:45 min</td>
            </tr>


            <tr>
              <td>06-02-2022</td>
              <td>08:45</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-missed.svg"
                                                       alt="image title"> +19095190565 <p
                  class="misedcall">Misedcall</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>12:10 min</td>
            </tr>


            </tbody>
          </table>
        </div>
        <!-- review-table -->
      </div>
      <div class="tab-pane fade" id="nav-Website" role="tabpanel" aria-labelledby="nav-Website-tab">

        <!-- review-table -->
        <div class="review-table">
          <table class="display">
            <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Phone</th>
              <th>Call Recording</th>
              <th>Duration</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>06-02-2022</td>
              <td>10:58</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-incoming.svg"
                                                       alt="image title"> +19095190565 <p
                  class="incoming">Incomming</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>0.45 min</td>
            </tr>
            <tr>
              <td>06-02-2022</td>
              <td>08:45</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-outgoing.svg"
                                                       alt="image title"> +19095190565 <p
                  class="outgoing">Outgoing</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>02:45 min</td>
            </tr>


            <tr>
              <td>06-02-2022</td>
              <td>08:45</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-missed.svg"
                                                       alt="image title"> +19095190565 <p
                  class="misedcall">Misedcall</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>12:10 min</td>
            </tr>
            <tr>
              <td>06-02-2022</td>
              <td>10:58</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-incoming.svg"
                                                       alt="image title"> +19095190565 <p
                  class="incoming">Incomming</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>0.45 min</td>
            </tr>
            <tr>
              <td>06-02-2022</td>
              <td>08:45</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-outgoing.svg"
                                                       alt="image title"> +19095190565 <p
                  class="outgoing">Outgoing</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>02:45 min</td>
            </tr>


            <tr>
              <td>06-02-2022</td>
              <td>08:45</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-missed.svg"
                                                       alt="image title"> +19095190565 <p
                  class="misedcall">Misedcall</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>12:10 min</td>
            </tr>
            <tr>
              <td>06-02-2022</td>
              <td>10:58</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-incoming.svg"
                                                       alt="image title"> +19095190565 <p
                  class="incoming">Incomming</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>0.45 min</td>
            </tr>
            <tr>
              <td>06-02-2022</td>
              <td>08:45</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-outgoing.svg"
                                                       alt="image title"> +19095190565 <p
                  class="outgoing">Outgoing</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>02:45 min</td>
            </tr>


            <tr>
              <td>06-02-2022</td>
              <td>08:45</td>
              <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-missed.svg"
                                                       alt="image title"> +19095190565 <p
                  class="misedcall">Misedcall</p></span>
              </td>

              <td>
                <a class="playbtn" href="#">
                  <img class="img-fluid play" src="@/assets/img/paly.svg" alt="paly">
                  <img class="img-fluid push" src="@/assets/img/push.svg" alt="push">
                </a>
              </td>
              <td>12:10 min</td>
            </tr>


            </tbody>
          </table>
        </div>
        <!-- review-table -->
      </div>
    </div>
  </div>
  <!-- call-tracking  -->

</template>
<script>
import $ from "jquery";
import {onMounted} from "vue";

export default {
  components: {},
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    // Open Modal
    const openModal = () => {
      $("#featureNotAvailable").modal("show");
    };

    // Close Modal
    const closeModal = () => {
      $("#featureNotAvailable").modal("hide");
    };
    const datatableInit = async () => {
      if ($.fn.DataTable.isDataTable(".display")) {
        $(".display").DataTable().clear().destroy();
      }
      $(".display").addClass("nowrap").dataTable({
        order: [],
        columnDefs: [
          {targets: [1], orderable: false}
        ]
      });
    }
    onMounted(() => {
      datatableInit();
      window.showHideMainLoader(false);
    });

    return {openModal, closeModal, base_url};
  },
};
</script>