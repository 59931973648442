<template>
     <!-- Monthly Goal -->
    <div class="monthly-goal">
      <div class="row">

        <!-- goal-box1 -->
        <div class="col-md-6 col-lg-6 col-xl-3">
          <div class="goal-box1">
            <div class="goal-header">
              <h5>Avarage Rating</h5>
              <p><img class="img-fluid" src="@/assets/img/up.svg" alt="image title"> +07% Inc</p>
            </div>
            <div class="rating">
              <img class="img-fluid" src="@/assets/img/stars.svg" alt="image title">
              <h2>4.0 / 5</h2>
              <ul class="ratestar">
                <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
              </ul>
            </div>

            <p class="samarytext">Avarage on <span> 45 Reviews</span></p>
          </div>
        </div>
        <!-- goal-box1 -->

        <!-- goal-box1 -->
        <div class="col-md-6 col-lg-6 col-xl-3">
          <div class="goal-box1">
            <div class="goal-header">
              <h5>Reviews</h5>
              <p><img class="img-fluid" src="@/assets/img/down.svg" alt="image title"> -09% Dec</p>
            </div>
            <div class="rating">
              <img class="img-fluid" src="@/assets/img/002-review.svg" alt="image title">
              <h2>50</h2>
            </div>

            <p class="samarytext">Sent on <span> 99 Invites</span></p>
          </div>
        </div>
        <!-- goal-box1 -->

        <!-- goal-box1 -->
        <div class="col-md-6 col-lg-6 col-xl-3">
          <div class="goal-box1">
            <div class="goal-header">
              <h5>Monthly Goal</h5>
              <p><img class="img-fluid" src="@/assets/img/down.svg" alt="image title"> -09% Dec</p>
            </div>
            <div class="circle_percent circle1" data-percent="75">
              <div class="circle_inner">
                <div class="round_per"></div>
              </div>
            </div>
            <p class="receved">45 Receieve</p>

            <p class="samarytext">Your goal in this month <span> 60 Reviews</span></p>
          </div>
        </div>
        <!-- goal-box1 -->

        <!-- goal-box1 -->
        <div class="col-md-6 col-lg-6 col-xl-3">
          <div class="goal-box1">
            <div class="goal-header">
              <h5>Invites This month</h5>
              <p><img class="img-fluid" src="@/assets/img/up.svg" alt="image title"> +11% Inc</p>
            </div>
            <div class="circle_percent circle2" data-percent="50">
              <div class="circle_inner">
                <div class="round_per"></div>
              </div>
            </div>
            <p class="receved">99 Sent</p>

            <p class="samarytext">Reach your goal, send <span>198 Invites</span></p>
          </div>
        </div>
        <!-- goal-box1 -->

      </div>
    </div>
    <!-- Monthly Goal -->

    <!-- reviewby-site -->
    <div class="reviewby-site">
      <div class="row">
        <div class="col-md-6 col-xl-8">
          <div class="review-site">
            <p>Review by Site</p>
            <ul>
              <li><img class="img-fluid" src="@/assets/img/googleIcon.svg" alt="image title">
                <div class="animated-progress google">
                  <p>google</p>
                  <span data-progress="45"></span>
                </div>
              </li>
              <li><img class="img-fluid" src="@/assets/img/fbIcon.svg" alt="image title">
                <div class="animated-progress facebook">
                  <p>facebook</p>
                  <span data-progress="60"></span>
                </div>
              </li>
              <li><img class="img-fluid" src="@/assets/img/twitteriocn.svg" alt="image title">
                <div class="animated-progress twitter">
                  <p>twitter</p>
                  <span data-progress="70"></span>
                </div>
              </li>
              <li><img class="img-fluid" src="@/assets/img/yelpicon.svg" alt="image title">
                <div class="animated-progress yelp">
                  <p>yelp</p>
                  <span data-progress="15"></span>
                </div>
              </li>
              <li><img class="img-fluid" src="@/assets/img/linkedInicon.svg" alt="image title">
                <div class="animated-progress linkedin">
                  <p>linkedin</p>
                  <span data-progress="35"></span>
                </div>
              </li>

            </ul>

          </div>
        </div>
        <div class="col-md-6 col-xl-4">
          <!-- ratingny-site -->
          <div class="rating-bysite">
            <p>Rating by Site</p>
            <ul>
              <li><span class="sitename"><img class="img-fluid" src="@/assets/img/googleIcon.svg" alt="image title"> google</span>
                <span class="ratingss"><ul class="ratestar">
                                    <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
                                  </ul>
                                  <h6>4.0 <span class="ranum">(632)</span></h6>
                                                </span>
              </li>
              <li><span class="sitename"><img class="img-fluid" src="@/assets/img/fbIcon.svg" alt="image title"> facebook</span>
                <span class="ratingss"><ul class="ratestar">
                                    <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li class="yellow"><i class="fa fa-star-half" aria-hidden="true"></i></li>
                                  </ul>
                                  <h6>4.9 <span class="ranum">(232)</span></h6>
                                                </span>
              </li>
              <li><span class="sitename"><img class="img-fluid" src="@/assets/img/twitteriocn.svg" alt="image title"> twitter</span>
                <span class="ratingss"><ul class="ratestar">
                                    <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li class="yellow"><i class="fa fa-star-half" aria-hidden="true"></i></li>
                                    <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
                                  </ul>
                                  <h6>3.5 <span class="ranum">(244)</span></h6>
                                                </span>
              </li>
              <li><span class="sitename"><img class="img-fluid" src="@/assets/img/yelpicon.svg" alt="image title"> yelp</span>
                <span class="ratingss"><ul class="ratestar">
                                    <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li class="yellow"><i class="fa fa-star-half" aria-hidden="true"></i></li>
                                    <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
                                    <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
                                  </ul>
                                  <h6>2.7 <span class="ranum">(222)</span></h6>
                                                </span>
              </li>
              <li><span class="sitename"><img class="img-fluid" src="@/assets/img/linkedInicon.svg" alt="image title"> linkedin</span>
                <span class="ratingss"><ul class="ratestar">
                                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                                  <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
                                  <li class="yellow"><i class="fa fa-star-half" aria-hidden="true"></i></li>
                                  <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
                                  <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
                                </ul>
                                <h6>3.9 <span class="ranum">(214)</span></h6>
                                                </span>
              </li>
            </ul>
          </div>
          <!-- ratingny-site -->
        </div>
      </div>
    </div>
    <!-- reviewby-site -->

    <!-- recent-reviews -->
    <div class="recent-reviews">
      <div class="row">
        <!-- recent-left -->
        <div class="col-md-8 col-lg-9">

          <!-- review-table -->
          <div class="review-table">
            <p>Recent Invites</p>
            <!-- search-top -->
            <div class="search-top">
              <div class="row">
                <div class="col-lg-6">
                  <div class="search-box search-box2">
                    <form>
                      <div class="form-group">
                        <input type="search" class="form-control" aria-label="search" placeholder="Search">
                        <img class="img-fluid sea-icon" src="@/assets/img/search.svg" alt="image title">
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="export-see">
                    <div class="export">
                      <a href="#"> <img class="img-fluid" src="@/assets/img/export.svg" alt="image title"> Export</a>
                    </div>
                    <div class="see-all">
                      <a href="#">See All Reviews</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- search-top -->

            <table class="display" id="dataTable">
              <thead>
              <tr>
                <th>Name</th>
                <th>Phone/Email</th>
                <th>Sent by</th>
                <th>Sent Time</th>
                <th>Followed Link</th>
                <th>Review</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
              </tr>

              <tr>
                <td>
                  <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
                    Hawkins </a>
                </td>
                <td>+180 545 0333</td>
                <td>Ahmad Sobhan</td>
                <td>Mar 16, 2021 <br> 01:32 pm</td>
                <td><img class="img-fluid" src="@/assets/img/Line.svg" alt="image title"></td>
                <td><img class="img-fluid" src="@/assets/img/check.svg" alt="image title"></td>
              </tr>

              </tbody>
            </table>
          </div>
          <!-- review-table -->
        </div>
        <!-- recent-left -->

        <!-- recent-right -->
        <div class="col-md-4 col-lg-3">
          <div class="recent-right">

            <!-- total-message -->
            <div class="total-message">
              <p>Total Messages</p>
              <div class="media">
                <img src="@/assets/img/sendmss.svg" class="mr-3 img-fluid" alt="...">
                <div class="media-body">
                  <h5 class="mt-0">2,876</h5>
                  <p>Sent</p>
                </div>
              </div>
              <div class="media">
                <img src="@/assets/img/recived.svg" class="mr-3 img-fluid" alt="...">
                <div class="media-body">
                  <h5 class="mt-0">3,545</h5>
                  <p>Received</p>
                </div>
              </div>
            </div>
            <!-- total-message -->

            <!-- Summary -->
            <div class="summary version3">
              <p>Invite Sent </p>
              <div class="row">
                <div class="col-12">
                  <div class="send-icons">
                    <img class="img-fluid" src="@/assets/img/paper-plane2.svg" alt="image title">
                  </div>
                </div>
                <div class="col-6 border-right">
                  <div class="total-sned">
                    <h5 class="mt-0">27</h5>
                    <p>This Month</p>
                  </div>
                </div>
                <div class="col-6">
                  <div class="total-sned">
                    <h5 class="mt-0">65</h5>
                    <p>Last Month</p>
                  </div>
                </div>


              </div>
            </div>
            <!-- Summary -->
          </div>
        </div>
        <!-- recent-right -->
      </div>

    </div>
    <!-- recent-reviews -->

</template>
<script>
import $ from "jquery";
 import {onMounted} from "vue";

export default {
  components: {
   },
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    // Open Modal
    const openModal = () => {
      $("#featureNotAvailable").modal("show");
    };

    // Close Modal
    const closeModal = () => {
      $("#featureNotAvailable").modal("hide");
    };
    const datatableInit = async () => {
      if ($.fn.DataTable.isDataTable("#dataTable")) {
        $("#dataTable").DataTable().clear().destroy();
      }
      $("#dataTable").addClass("nowrap").dataTable({
        order: [],
        columnDefs: [
          {targets: [1], orderable: false}
        ]
      });
    }
    onMounted(() => {
      datatableInit();
      window.showHideMainLoader(false);
    });

    return {openModal, closeModal, base_url};
  },
};
</script>