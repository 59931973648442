<template>

  <!-- search-top -->
  <div class="search-top">
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <div class="search-box">
          <form>
            <div class="form-group">
              <input type="search" class="form-control" placeholder="Search" aria-label="search">
              <img class="img-fluid sea-icon" src="@/assets/img/search.svg" alt="image title">
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-6 col-lg-8">
        <div class="export-right">
          <div class="create-meeting">
            <a href="#"><img class="img-fluid" src="@/assets/img/pl.svg" alt="image title"> Create a New Number</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- search-top -->


  <!-- review-table -->
  <div class="review-table call-setting">
    <table class="display" id="dataTable">
      <thead>
      <tr>
        <th>Source</th>
        <th>Phone</th>
        <th>Type</th>
        <th>Forward to</th>
        <th>Action</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Google</td>
        <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-incoming.svg"
                                                 alt="image title"> +19095190565 </span>
        </td>

        <td>Sales</td>
        <td>
          <a class="share" href="#"><img class="img-fluid" src="@/assets/img/share1.svg" alt="image title">
            +19095190565</a>
        </td>
        <td>
          <a class="invites" href="#"><img class="img-fluid" src="@/assets/img/edit-3.svg" alt="image title"> Edit</a>
        </td>
      </tr>

      <tr>
        <td>Google</td>
        <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-outgoing.svg"
                                                 alt="image title"> +19095190565 </span>
        </td>

        <td>Sales</td>
        <td>
          <a class="share" href="#"><img class="img-fluid" src="@/assets/img/share1.svg" alt="image title">
            +19095190565</a>
        </td>
        <td>
          <a class="invites" href="#"><img class="img-fluid" src="@/assets/img/edit-3.svg" alt="image title"> Edit</a>
        </td>
      </tr>

      <tr>
        <td>Google</td>
        <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-missed.svg"
                                                 alt="image title"> +19095190565 </span>
        </td>

        <td>Sales</td>
        <td>
          <a class="share" href="#"><img class="img-fluid" src="@/assets/img/share1.svg" alt="image title">
            +19095190565</a>
        </td>
        <td>
          <a class="invites" href="#"><img class="img-fluid" src="@/assets/img/edit-3.svg" alt="image title"> Edit</a>
        </td>
      </tr>

      <tr>
        <td>Google</td>
        <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-incoming.svg"
                                                 alt="image title"> +19095190565 </span>
        </td>

        <td>Sales</td>
        <td>
          <a class="share" href="#"><img class="img-fluid" src="@/assets/img/share1.svg" alt="image title">
            +19095190565</a>
        </td>
        <td>
          <a class="invites" href="#"><img class="img-fluid" src="@/assets/img/edit-3.svg" alt="image title"> Edit</a>
        </td>
      </tr>

      <tr>
        <td>Google</td>
        <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-outgoing.svg"
                                                 alt="image title"> +19095190565 </span>
        </td>

        <td>Sales</td>
        <td>
          <a class="share" href="#"><img class="img-fluid" src="@/assets/img/share1.svg" alt="image title">
            +19095190565</a>
        </td>
        <td>
          <a class="invites" href="#"><img class="img-fluid" src="@/assets/img/edit-3.svg" alt="image title"> Edit</a>
        </td>
      </tr>

      <tr>
        <td>Google</td>
        <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-missed.svg"
                                                 alt="image title"> +19095190565 </span>
        </td>

        <td>Sales</td>
        <td>
          <a class="share" href="#"><img class="img-fluid" src="@/assets/img/share1.svg" alt="image title">
            +19095190565</a>
        </td>
        <td>
          <a class="invites" href="#"><img class="img-fluid" src="@/assets/img/edit-3.svg" alt="image title"> Edit</a>
        </td>
      </tr>

      <tr>
        <td>Google</td>
        <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-incoming.svg"
                                                 alt="image title"> +19095190565 </span>
        </td>

        <td>Sales</td>
        <td>
          <a class="share" href="#"><img class="img-fluid" src="@/assets/img/share1.svg" alt="image title">
            +19095190565</a>
        </td>
        <td>
          <a class="invites" href="#"><img class="img-fluid" src="@/assets/img/edit-3.svg" alt="image title"> Edit</a>
        </td>
      </tr>

      <tr>
        <td>Google</td>
        <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-outgoing.svg"
                                                 alt="image title"> +19095190565 </span>
        </td>

        <td>Sales</td>
        <td>
          <a class="share" href="#"><img class="img-fluid" src="@/assets/img/share1.svg" alt="image title">
            +19095190565</a>
        </td>
        <td>
          <a class="invites" href="#"><img class="img-fluid" src="@/assets/img/edit-3.svg" alt="image title"> Edit</a>
        </td>
      </tr>

      <tr>
        <td>Google</td>
        <td class="chs dt-body-right"><span><img class="img-fluid" src="@/assets/img/phone-missed.svg"
                                                 alt="image title"> +19095190565 </span>
        </td>

        <td>Sales</td>
        <td>
          <a class="share" href="#"><img class="img-fluid" src="@/assets/img/share1.svg" alt="image title">
            +19095190565</a>
        </td>
        <td>
          <a class="invites" href="#"><img class="img-fluid" src="@/assets/img/edit-3.svg" alt="image title"> Edit</a>
        </td>
      </tr>

      </tbody>
    </table>
  </div>
  <!-- review-table -->

</template>
<script>
import $ from "jquery";

import {onMounted} from "vue";

export default {
  components: {},
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    // Open Modal
    const openModal = () => {
      $("#featureNotAvailable").modal("show");
    };

    // Close Modal
    const closeModal = () => {
      $("#featureNotAvailable").modal("hide");
    };
    const datatableInit = async () => {
      if ($.fn.DataTable.isDataTable("#dataTable")) {
        $("#dataTable").DataTable().clear().destroy();
      }
      $("#dataTable").addClass("nowrap").dataTable({
        order: [],
        columnDefs: [
          {targets: [1], orderable: false}
        ]
      });
    }
    onMounted(() => {
      datatableInit();
      window.showHideMainLoader(false);
    });

    return {openModal, closeModal, base_url};
  },
};
</script>