import {defineStore} from 'pinia'
import {reactive, ref, watch} from 'vue'
import api from "@/use/api";

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useAuthUserStore = defineStore('auth_user', () => {
    const auth = reactive({user:null})
    const authToken = ref('')
    const is_loading = ref(false)

    // const doubleCount = computed(() => count.value * 2)
    const {results, status, sendGetRequest} = api();
    function getUser() {
        return auth.user;
    }
    async function loadUserInfo() {

        if (!is_loading.value) {


            await  sendGetRequest('profile');

        }


    }


    watch(status, (currentStatus) => {

        if (currentStatus){
            auth.user = results.value
            is_loading.value =true;


        }





    })



    function setUser(newInfo) {
        auth.user  = newInfo;
        is_loading.value =true;
    }

    return {auth, name, getUser ,setUser , authToken , loadUserInfo};

})