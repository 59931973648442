<template>
  <!-- search-top -->
  <div class="search-top">
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <!-- <div class="search-box">
          <div class="todayselect">
            <select class="form-control" aria-label="agents">
              <option>Select agents</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
        </div> -->
      </div>
      <div class="col-md-6 col-lg-8">
        <div class="export-right">
          <div class="export">
            <router-link :to="'/admin/clients'">Go Back</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- search-top -->

  <!-- all-task -->
  <div class="all-task">
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="tasks">
          <h2>Add New Client</h2>
          <form>
            <div class="row g-3">
              <div class="col-md-6">
                <div class="form-floating">
                  <input type="text" class="form-control" id="Firstname" placeholder="Firstname"
                    v-model="register_form.register_first_name" @click="removeClass('#Firstname', 'red-border')">
                  <label for="Firstname">First name</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input type="text" class="form-control" id="lastname" placeholder="lastname"
                    v-model="register_form.register_last_name" @click="removeClass('#lastname', 'red-border')">
                  <label for="lastname">Last name</label>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-floating">
                  <input type="text" class="form-control" id="businessname" placeholder="businessname"
                    v-model="register_form.register_business" @click="removeClass('#businessname', 'red-border')">
                  <label for="email">Business Name</label>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-floating">
                  <input type="email" class="form-control" id="email" placeholder="email"
                    v-model="register_form.register_email" @click="removeClass('#email', 'red-border')">
                  <label for="email">Email address</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input type="password" class="form-control" id="password1" placeholder="password"
                    v-model="register_form.register_password" @click="removeClass('#password1', 'red-border')">
                  <label for="password1">Password</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input type="password" class="form-control" id="password2" placeholder="Confirm password"
                    @click="removeClass('#password2', 'red-border')">
                  <label for="password2">Confirm password</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <!-- <input type="number" class="form-control" id="Phone" placeholder="Phone"
                              v-model="register_form.register_phone" @click="removeClass('#Phone', 'red-border')"> -->
                  <vue-mask class="form-control" mask="(000)000-0000" :options="options" id="Phone" placeholder="Phone"
                    v-model="register_form.register_phone" @click="removeClass('#Phone', 'red-border')"></vue-mask>
                  <label for="Phone">Phone no</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input type="text" class="form-control" id="Zip" placeholder="Zip code"
                    v-model="register_form.register_zip">
                  <label for="Zip">Zip code</label>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-floating">
                  <input type="text" class="form-control" id="location_title" placeholder="Location Title"
                    v-model="register_form.location_title" @click="removeClass('#location_title', 'red-border')">
                  <label for="location_title">Location Title</label>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-floating">
                  <input type="text" class="form-control" id="street_address_1" placeholder="Street Address 1"
                    v-model="register_form.street_address_1" @click="removeClass('#street_address_1', 'red-border')">
                  <label for="street_address_1">Street Address 1</label>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-floating">
                  <input type="text" class="form-control" id="street_address_2" placeholder="Street Address 2"
                    v-model="register_form.street_address_2">
                  <label for="street_address_2">Street Address 2</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input type="text" class="form-control" id="city" placeholder="City" v-model="register_form.city"
                    @click="removeClass('#city', 'red-border')">
                  <label for="city">City</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input type="text" class="form-control" id="state" placeholder="State" v-model="register_form.state"
                    @click="removeClass('#state', 'red-border')">
                  <label for="state">State</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input type="text" class="form-control" id="country" placeholder="Country" value="US" readonly>
                  <label for="country">Country</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <input type="text" class="form-control" id="postal_code" placeholder="Postal Code"
                    v-model="register_form.postal_code" @click="removeClass('#postal_code', 'red-border')">
                  <label for="postal_code">Postal Code</label>
                </div>
              </div>
              <div class="form-floating">
              <div class="col-12 text-center">
                <button type="button" id="register-btn" class="cus-btn" @click="RegisterUser">Add Client</button>
              </div>
            </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import axios from "axios";
// import { useRoute } from "vue-router";
// import TwilioTrackingForm from "./../../../components/TwilioTrackingNumbers/TwilioTrackingForm.vue";
import vueMask from 'vue-jquery-mask';
export default {
  components: { vueMask },
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    // const Params = useRoute();
    // console.log(Params.params.id);
    let register_form = ref({
      register_first_name: '',
      register_last_name: '',
      register_business: '',
      register_email: '',
      register_phone: '',
      register_zip: '',
      register_password: '',
      industry_id: 0,
      feature_id: 0,
      location_title: '',
      street_address_1: '',
      street_address_2: '',
      city: '',
      state: '',
      country: '',
      postal_code: ''
    });
    const RegisterUser = async () => {
      removeClass('.form-control', 'red-border');
      let validate = 1;
      console.log(validate);
      // console.log(register_form.value);
      if (register_form.value.register_first_name == '') {
        validate = 0;
        addClass('#Firstname', 'red-border');
        showToast('error', 'First name is required');
        return false;
      }
      if (register_form.value.register_last_name == '') {
        validate = 0;
        addClass('#lastname', 'red-border');
        showToast('error', 'Last name is required');
        return false;
      }
      if (register_form.value.register_business == '') {
        validate = 0;
        addClass('#businessname', 'red-border');
        showToast('error', 'Business name is required');
        return false;
      }
      if (register_form.value.register_email == '') {
        validate = 0;
        addClass('#email', 'red-border');
        showToast('error', 'Email is required');
        return false;
      }
      if (register_form.value.register_password == '') {
        validate = 0;
        addClass('#password1', 'red-border');
        showToast('error', 'Password is required');
        return false;
      } else {
        let password = register_form.value.register_password;
        if (!isPasswordValid(password)) {
          validate = 0;
          addClass('#password1', 'red-border');
          showToast('error', 'Password must be at least 8 characters, must contain at least 1 uppercase, 1 lowercase and 1 number');
          return false;
        } else {
          if ($('#password2').val() === password) { console.log('Passwords match'); } else {
            validate = 0;
            addClass('#password2', 'red-border');
            showToast('error', 'Passwords do not match');
            return false;
          }
        }
      }
      if (register_form.value.register_phone == '') {
        validate = 0;
        addClass('#Phone', 'red-border');
        showToast('error', 'Phone number is required1');
        return false;
      } else if (register_form.value.register_phone.length < 10) {
        validate = 0;
        addClass('#Phone', 'red-border');
        showToast('error', 'Phone number is invalid2');
        return false;
      } else if (!isPhoneValid(register_form.value.register_phone)) {
        validate = 0;
        addClass('#Phone', 'red-border');
        showToast('error', 'Phone number is invalid3');
        return false;
      }
      if (register_form.value.location_title == '') {
        validate = 0;
        addClass('#location_title', 'red-border');
        showToast('error', 'Location title is required');
        return false;
      }
      if (register_form.value.street_address_1 == '') {
        validate = 0;
        addClass('#street_address_1', 'red-border');
        showToast('error', 'Street Address is required');
        return false;
      }
      if (register_form.value.city == '') {
        validate = 0;
        addClass('#city', 'red-border');
        showToast('error', 'City is required');
        return false;
      }
      if (register_form.value.state == '') {
        validate = 0;
        addClass('#state', 'red-border');
        showToast('error', 'City is required');
        return false;
      }
      if (register_form.value.postal_code == '') {
        validate = 0;
        addClass('#postal_code', 'red-border');
        showToast('error', 'Postal Code is required');
        return false;
      }
      if (validate == 1) {
        let registerFormData = new FormData();
        registerFormData.append('first_name', register_form.value.register_first_name);
        registerFormData.append('last_name', register_form.value.register_last_name);
        registerFormData.append('business_name', register_form.value.register_business);
        registerFormData.append('email', register_form.value.register_email);
        registerFormData.append('phone', register_form.value.register_phone);
        registerFormData.append('zip', register_form.value.register_zip);
        registerFormData.append('password', register_form.value.register_password);
        registerFormData.append('dealer_id', 0);
        registerFormData.append('location_id', 0);
        registerFormData.append('industry_id', register_form.value.industry_id);
        registerFormData.append('feature_id', register_form.value.feature_id);
        registerFormData.append('title', register_form.value.location_title);
        registerFormData.append('address', register_form.value.street_address_1);
        registerFormData.append('address_2', register_form.value.street_address_2);
        registerFormData.append('city', register_form.value.city);
        registerFormData.append('state', register_form.value.state);
        registerFormData.append('country', register_form.value.country);
        registerFormData.append('postal_code', register_form.value.postal_code);
        window.showHideMainLoader(true);
        await axios
          .post(
            axios.defaults.baseUrl + "admin/clients/add",
            registerFormData,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem('auth_token'),
              },
            }
          )
          .then((response) => {
            window.showHideMainLoader(false);
            showToast(response.data.status, response.data.message);
            register_form.value = '';

            localStorage.setItem("location_id", response.data.data.location_id);
            localStorage.setItem("location_address", response.data.data.location_address);
            setTimeout(function () {
              window.location.href = '/admin/clients';
            }, 5000);
          })
          .catch((error) => {
            window.showHideMainLoader(false);
            showToast(error.response.data.status, error.response.data.message);
            return false;
          });
      }
    }
    const isPasswordValid = (email) => {
      let regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
      return regex.test(email);
    }
    const isPhoneValid = (phone) => {
      let regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      return regex.test(phone);
    }
    const addClass = (elementName, className) => {
      $(elementName).addClass(className);
    }

    const removeClass = (elementName, className) => {
      $(elementName).removeClass(className);
    }
    const showToast = (toastIcon, toastMessage) => {
      window.toast.fire({
        icon: toastIcon,
        title: toastMessage,
      });
    }
    // Open Modal
    const openModal = () => {

    };

    // Close Modal
    const closeModal = () => {

    };
    onMounted(() => {

      // // console.log(Params);
      // datatableInit();
      window.showHideMainLoader(false);
    });

    return {
      openModal,
      closeModal,
      base_url,
      register_form,
      RegisterUser,
      removeClass,
      addClass,
      showToast,
    };
  },
};
</script>