import {defineStore } from 'pinia'
import {ref, watch} from 'vue'
import api from "@/use/api";


export const useLocationsStore = defineStore('locations', () => {

    const locations = ref([]);
    const is_loading = ref(false);
    const is_updated_or_created_loading = ref(false);
    // const update_loading = ref(false);

    // const is_loading = ref(false);

    const {results, status, sendGetRequest, sendPostRequest} = api();

    function getListLocations() {
        if (!is_loading.value)
        loadLocations();
        return locations;
    }

    function loadLocations() {

        if (!is_loading.value) {


            sendGetRequest('locations');

        }


    }

    function storeLocations(data) {
        is_updated_or_created_loading.value =true;
        sendPostRequest('locations/store', data);

    }


    watch(status, (currentStatus) => {

        console.log('success'  , currentStatus)
        console.log('value'  , results.value)
        if (currentStatus) { ///  if  success login


            if (Array.isArray(results.value))  // if  request to get all data
                locations.value = results.value;
            else { //  if  create new item
                locations.value.unshift(results.value);
                is_updated_or_created_loading.value =false;

            }

            is_loading.value =true;
        }

    })
    return {locations, is_loading, getListLocations, storeLocations , is_updated_or_created_loading};


})