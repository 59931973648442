//firebase.js

import { initializeApp } from "firebase/app";
import { getMessaging, getToken , onMessage } from "firebase/messaging";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyCpbLdxXYQocEXAw4Udruv5KEtwJv-468A",
  authDomain: "ornate-justice-283216.firebaseapp.com",
  projectId: "ornate-justice-283216",
  storageBucket: "ornate-justice-283216.appspot.com",
  messagingSenderId: "1018907380668",
  appId: "1:1018907380668:web:cbbfc6df614324d72479b9",
  measurementId: "G-BJ0E5KP8B7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);
//
// getToken(messaging, { vapidKey: 'BE9yPw-x6Na46UsMnP-eF3_23HTBF1K-gBbDKWBiiqQ1QcqSGp2GDiC6cakTAb8_JtMYAKRDYMCwkQp5pghlGno' })
//     .then((currentToken) => {
//       if (currentToken) {
//         localStorage.setItem("FCM-Token", currentToken)
//       } else {
//         console.log('No registration token available. Request permission to generate one.');
//       }
//     }).catch((err) => {
//   console.log('An error occurred while retrieving token. ', err);
// });

export { messaging, getToken ,onMessage };