<template>
  <!-- search-top -->
  <div class="search-top">
    <div class="row">
      <div class="col-md-12">
        <div class="export-right">
          <div class="export">
            <router-link :to="{ name: 'AdminClientsAdd' }"><img class="img-fluid" src="@/assets/img/pl.svg"
                alt="image title"> Add New Client</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- search-top -->
  <!-- review-table -->
  <div class="row">
  <div class="col-6">
    <a :class="{'btn':true, 'btn-sm':true, 'btn-default':true, 'm-2':true, 'btn-primary':!Params?.params.param || Params?.params.param == 'all'}" :href="'/admin/clients/all'">All Time</a>
    <a :class="{'btn':true, 'btn-sm':true, 'btn-default':true, 'm-2':true, 'btn-primary':Params?.params.param == 'month'}" :href="'/admin/clients/month'">This Month</a>
    <a :class="{'btn':true, 'btn-sm':true, 'btn-default':true, 'm-2':true, 'btn-primary':Params?.params.param == 'week'}" :href="'/admin/clients/week'">This Week</a>
    <a :class="{'btn':true, 'btn-sm':true, 'btn-default':true, 'm-2':true, 'btn-primary':Params?.params.param == 'today'}" :href="'/admin/clients/today'">Today</a>
  </div>
  </div>
  <div class="review-table">
    <table class="display" id="dataTable">
      <thead>
        <tr>
          <th>Client Name</th>
          <th>Twilio Phone</th>
          <th>Inbound - SMS</th>
          <th>Outbound - SMS</th>
          <th>Email</th>
          <th>Location</th>
          <th>Total Customers</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="User in AllClients" :key="User">
          <td>
            <a class="manname" href="javascript:">
              <img class="img-fluid" v-if="User.user_image" :src="base_url + 'user/' + User.user_image" alt="User Image">
              <img class="img-fluid" v-else src="@/assets/img/man.png" alt="image title">
              {{ User.first_name }} {{ User.last_name }}
            </a>
          </td>
          <td v-if="User.phone_no_request == 1">{{ User.phone_no }}</td>
          <td v-if="User.phone_no_request == 2">
            <router-link class="invites" :to="'/admin/clients/' + User.id + '/buy_phone_no'">Requested</router-link>
          </td>
          <td v-if="User.phone_no_request == 0">N/A</td>
          <td>{{ User.inbound_sms }}<br>= ${{ User.inbound_sms_price }}</td>
          <td>{{ User.outbound_sms }}<br>= ${{ User.outbound_sms_price }}</td>
          <td>{{ User.email }}</td>
          <td>{{ User.company }}
            <p>{{ User.address }}</p>
          </td>
          <td>{{ User.total_customers }}</td>
          <td v-if="User.phone_no_request == 0">
            <router-link class="invites" :to="'/admin/clients/' + User.id + '/buy_phone_no'">Buy Twilio
              Number</router-link>
              <a href="javascript:;" class="invites" @click="Changepassword(User)">Change Password</a>
          </td>
          <td v-else>
            <a href="javascript:;" class="invites" @click="Changepassword(User)">Change Password</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- review-table -->
    <!-- invite-Modal -->
    <div class="modal fade" id="passwordChange" tabindex="-1" aria-labelledby="passwordChangeLabel" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="passwordChangeLabel">Change Password for {{ passwordChange.client_name }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <img class="img-fluid" src="@/assets/img/close.png" alt="image title">
        </button>
                </div>
                <div class="modal-body">

                    <div class="contact-form">
                        <form action="#">
                            <div class="row">
                              <input type="hidden" name="client_id" v-model="passwordChange.client_id" />
                                <div class="col-md-6">
                                    <div class="customer-field">
                                        <p><input type="password" class="form-control" id="newpassword" v-model="passwordChange.password" placeholder="Enter New Password..."/></p>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="customer-field">
                                        <p><input type="password" class="form-control" id="confirmpassword" v-model="passwordChange.confirm_password" placeholder="Enter Confirm Password..."/></p>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center">
                                <button type="button" class="send-btn" @click="passwordChangeSubmit">Change Password</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
// import vueMask from 'vue-jquery-mask';
// console.log(localStorage);
export default {
  components: {},
  setup() {
    let base_url = "http://api.getstreamly.com/uploads/";
    const Params = useRoute();
    // let AllCustomersLists = ref([]);
    let AllClients = ref([]);
    let filterBy = ref('');
    let passwordChange = ref({
      client_id: 0,
      client_name: '',
      password: '',
      confirm_password: '',
    });

    const addClass = (elementName, className) => {
      $(elementName).addClass(className);
    }

    const removeClass = (elementName, className) => {
      $(elementName).removeClass(className);
    }
    const showToast = (toastIcon, toastMessage) => {
      window.toast.fire({
        icon: toastIcon,
        title: toastMessage,
      });
    }

    // Open Modal
    const openModal = () => {
      $("#featureNotAvailable").modal("show");
    };

    // Close Modal
    const closeModal = () => {
      $("#create_contact").modal("hide");
      $("#upload_contacts").modal("hide");
      $("#disply_csv_uploaded_contact").modal("hide");
    };
    const getAllClients = async (filter_by = '') => {
      if ($.fn.DataTable.isDataTable("#dataTable")) {
        $("#dataTable").DataTable().clear().destroy();
      }
      $('#dataTable').DataTable({
          "processing": true,
          "serverSide": true,
          language: {
            emptyTable: "No matching records found",
            lengthMenu: "Show _MENU_ records per page",
            zeroRecords: "",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            infoEmpty: "No records available",
            infoFiltered: "(filtered from _MAX_ total records)",
          },
          "ajax": {
              "url": axios.defaults.baseUrl+"admin/clients/dt",
              "type": "POST", // Assuming your API endpoint expects a POST request
              "headers": {
                Authorization: "Bearer " + localStorage.getItem('auth_token'),
              },
              "data": function(d) {
                  // You can add additional parameters or modify the request data here
                  return $.extend({}, d, {
                    'filterBy' : filter_by,
                  });
              },        
              dataSrc: function(response) {
                // Transform the data here before DataTables processes it
                AllClients.value = response.data;
                return [];
              },
          },
          "searching": true,
          "order": [],
          "columns" : [],
      });
      $(".display").addClass("nowrap").dataTable();
    }
    const Changepassword = (ClientData = null) => {
      // console.log(CustomerData);
      passwordChange.value.client_id = ClientData.user_id;
      passwordChange.value.client_name = ClientData.first_name+' '+ClientData.last_name;
      passwordChange.value.password = '';
      passwordChange.value.confirm_password = '';
      $("#passwordChange").modal("show");
    }
    const passwordChangeSubmit = async () => {
      console.log(passwordChange.value);
      if(passwordChange.value.password != ''){
        if(passwordChange.value.confirm_password != ''){
          if(passwordChange.value.password.length >= 6){
              if(passwordChange.value.password === passwordChange.value.confirm_password){
                const getFormData = new FormData();
                getFormData.append('client_id', passwordChange.value.client_id);
                getFormData.append('password', passwordChange.value.password);
                await axios
                  .post(
                    axios.defaults.baseUrl + "admin/change_password",
                    getFormData,
                    {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem('auth_token'),
                      },
                    }
                  )
                  .then((response) => {
                    showToast(response.data.status, response.data.message);
                    location.reload();//----remove later
                    // AllCustomersLists.value = response.data.data.allCustomersLists;
                    // datatableInit();
                  })
                  .catch((error) => {
                    // window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                  });
              }else{
                alert('Passwords do no match...');
              }
          }else{
            alert('Password must be atleasat 6 characters...');
          }
        }else{
          alert('Please Enter Confirm Password...');
        }
      }else{
        alert('Please Enter Password...');
      }
    }
    onMounted(() => {

      console.log(Params.params.param);
      // datatableInit();
      // getClients();
      if (Params.params.param != null) {
        filterBy.value = Params.params.param;
      }
      getAllClients(filterBy.value);
      window.showHideMainLoader(false);
    });

    return {
      openModal,
      closeModal,
      base_url,
      AllClients,
      Changepassword,
      passwordChange,
      passwordChangeSubmit,
      removeClass,
      addClass,
      showToast,
      filterBy,
      Params,
    };
  },
};
</script>