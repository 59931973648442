<template>

    <!-- search-top -->
    <div class="search-top">
      <div class="row">
        <div class="col-md-6 col-lg-4">
          <div class="search-box">
            <form>
              <div class="form-group">
                <input type="search" class="form-control" placeholder="Search" aria-label="search">
                <img class="img-fluid sea-icon" src="@/assets/img/search.svg" alt="image title">
              </div>
            </form>

          </div>
        </div>
        <div class="col-md-6 col-lg-8">
          <div class="export-right">
            <div class="todayselect">
              <div class="reportrange">
                This Month <i class="fa fa-angle-down" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- search-top -->

    <!-- review-table -->
    <div class="review-table alert-table">
      <table class="display" id="dataTable">
        <thead>
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th>Agents</th>
            <th>Leadsheet</th>
            <th>Alert</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <td>Mar 16, 2021</td>
            <td> 11:50am</td>
            <td>Sony</td>
            <td><a href="#">DealerGear Mohshin</a> </td>

            <td>
              <div class="alert alert-warning" role="alert">
                <img class="img-fluid" src="@/assets/img/warning.svg" alt=""> Customer hasn't been touched for 5 mins
              </div>
            </td>
          </tr>
          <tr>
            <td>Mar 16, 2021</td>
            <td> 11:50am</td>
            <td>Sony</td>
            <td><a href="#">Jamil Khandakar</a> </td>

            <td>
              <div class="alert alert-danger" role="alert">
                <img class="img-fluid" src="@/assets/img/danger.svg" alt=""> Customer hasn't been touched for 5 mins
              </div>
            </td>
          </tr>
          <tr>
            <td>Mar 16, 2021</td>
            <td> 11:50am</td>
            <td>Sony</td>
            <td><a href="#">Shaha Alam</a> </td>

            <td>
              <div class="alert alert-success" role="alert">
                <img class="img-fluid" src="@/assets/img/success.svg" alt=""> Customer updated
              </div>
            </td>
          </tr>

          <tr>
            <td>Mar 16, 2021</td>
            <td> 11:50am</td>
            <td>Sony</td>
            <td><a href="#">DealerGear Mohshin</a> </td>

            <td>
              <div class="alert alert-warning" role="alert">
                <img class="img-fluid" src="@/assets/img/warning.svg" alt=""> Customer hasn't been touched for 5 mins
              </div>
            </td>
          </tr>
          <tr>
            <td>Mar 16, 2021</td>
            <td> 11:50am</td>
            <td>Sony</td>
            <td><a href="#">Jamil Khandakar</a> </td>

            <td>
              <div class="alert alert-danger" role="alert">
                <img class="img-fluid" src="@/assets/img/danger.svg" alt=""> Customer hasn't been touched for 5 mins
              </div>
            </td>
          </tr>
          <tr>
            <td>Mar 16, 2021</td>
            <td> 11:50am</td>
            <td>Sony</td>
            <td><a href="#">Shaha Alam</a> </td>

            <td>
              <div class="alert alert-success" role="alert">
                <img class="img-fluid" src="@/assets/img/success.svg" alt=""> Customer updated
              </div>
            </td>
          </tr>

          <tr>
            <td>Mar 16, 2021</td>
            <td> 11:50am</td>
            <td>Sony</td>
            <td><a href="#">DealerGear Mohshin</a> </td>

            <td>
              <div class="alert alert-warning" role="alert">
                <img class="img-fluid" src="@/assets/img/warning.svg" alt=""> Customer hasn't been touched for 5 mins
              </div>
            </td>
          </tr>
          <tr>
            <td>Mar 16, 2021</td>
            <td> 11:50am</td>
            <td>Sony</td>
            <td><a href="#">Jamil Khandakar</a> </td>

            <td>
              <div class="alert alert-danger" role="alert">
                <img class="img-fluid" src="@/assets/img/danger.svg" alt=""> Customer hasn't been touched for 5 mins
              </div>
            </td>
          </tr>
          <tr>
            <td>Mar 16, 2021</td>
            <td> 11:50am</td>
            <td>Sony</td>
            <td><a href="#">Shaha Alam</a> </td>

            <td>
              <div class="alert alert-success" role="alert">
                <img class="img-fluid" src="@/assets/img/success.svg" alt=""> Customer updated
              </div>
            </td>
          </tr>


        </tbody>
      </table>
    </div>
    <!-- review-table -->

</template>
<script>
import $ from "jquery";
 import { onMounted } from "vue";

 export default {
  components: {

  },
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    // Open Modal
    const openModal = () => {
      $("#featureNotAvailable").modal("show");
    };

    // Close Modal
    const closeModal = () => {
      $("#featureNotAvailable").modal("hide");
    };
    const datatableInit = async () => {
      if ($.fn.DataTable.isDataTable("#dataTable")) {
        $("#dataTable").DataTable().clear().destroy();
      }
      $("#dataTable").addClass("nowrap").dataTable({
        order: [],
        columnDefs: [
          { targets: [1], orderable: false }
        ]
      });
    }
    onMounted(() => {

      datatableInit();
      window.showHideMainLoader(false);
     });

    return { openModal, closeModal, base_url };
  },
};
</script>