<template>
<div>
    <!-- review-table -->
    <div class="review-table">
        <table class="display" id="dataTable">
            <thead>
                <tr>
                    <th>No.</th>
                    <th>Team Member Name</th>
                    <th>All Appointments</th>
                    <th>Today's Appointments</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in leaderboardData" :key="item.id">
                    <td>{{ item.no }}</td>
                    <td>
                      <div class="rlpyuser leaderboard"><h6>{{ item.name_initials }}</h6><p>{{ item.first_name+' '+item.last_name }}</p></div></td>
                    <td>{{ item.all_appointments }}</td>
                    <td>{{ item.todays_appointments }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- review-table -->
</div>
</template>
<style scoped>
.leaderboard {
    display: flex;
    align-items: center;
}
.leaderboard h6 {
    margin-right: 15px;
    margin-left: 0;
}
</style>
<script>
import $ from "jquery";
import {
    ref,
    onMounted
} from "vue";
import axios from "axios";
export default {
    components: {

    },
    setup() {
        let base_url = "http://api.streamly.com/uploads/";
        let leaderboardData = ref([]);

        const getLeaderboardData = async () => {
            const getFormData = new FormData();
            getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            getFormData.append('location_id', localStorage.getItem('location_id'));
            await axios
                .post(axios.defaults.baseUrl + "leaderboard",
                    getFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    // console.log(response);
                    leaderboardData.value = response.data.data.leaderboard_data;
                    setTimeout(() => {
                        datatableInit();
                    }, 500);
                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        const datatableInit = async () => {
            if ($.fn.DataTable.isDataTable("#dataTable")) {
                $("#dataTable").DataTable().clear().destroy();
            }
            $("#dataTable").addClass("nowrap").dataTable({
                order: [],
                columnDefs: [{
                        targets: [0],
                        orderable: false,
                        searchable: false
                    },
                    {
                        targets: [1],
                        orderable: true,
                        searchable: true
                    },
                    {
                        targets: [2],
                        orderable: true,
                        searchable: true
                    },
                    {
                        targets: [3],
                        orderable: true,
                        searchable: true
                    },
                ]
            });
            $(".display").addClass("nowrap").dataTable();

        }
        const showToast = (toastIcon, toastMessage) => {
            window.toast.fire({
                icon: toastIcon,
                title: toastMessage,
            });
        }

        onMounted(() => {
            getLeaderboardData();
            // datatableInit();
            // console.log($('#listId').val());
            // getCustomers();
            window.showHideMainLoader(false);
        });

        return {
            // openModal,
            // closeModal,
            base_url,
            leaderboardData,
            showToast,
        };
    },
};
</script>
