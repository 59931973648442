export const starsTemplate = (rate) => {

    let $stars = "";

    for (let $n =1; $n <= 5; $n++) {
        let $class = $n > Math.ceil(rate) ? "black" : "yellow";
        let $class2 = $n <= rate || $n > rate ? "fa-star" : "fa-star-half";
        $stars += ` <li  key="${$n}"
                class="${$class}">
            <i class="fa  ${$class2}"
               aria-hidden="true"></i></li>`

    }

    return ` <ul class="ratestar" >
       ${$stars}

    </ul>`
}



