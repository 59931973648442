<template>
<!-- search-top -->
<div class="search-top">
    <div class="row">
        <div class="col-md-12">
            <div class="export-right">
                <div class="export">
                    <router-link :to="{ name: 'AdminClientsAdd' }"><img class="img-fluid" src="@/assets/img/pl.svg" alt="image title"> Add New Client</router-link>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- search-top -->
<!-- review-table -->
<div class="row">
    <div class="col-6">
        <a :class="{'btn':true, 'btn-sm':true, 'btn-default':true, 'm-2':true, 'btn-primary':!Params?.params.param || Params?.params.param == 'all'}" :href="'/admin/clients/all'">All Time</a>
        <a :class="{'btn':true, 'btn-sm':true, 'btn-default':true, 'm-2':true, 'btn-primary':Params?.params.param == 'month'}" :href="'/admin/clients/month'">This Month</a>
        <a :class="{'btn':true, 'btn-sm':true, 'btn-default':true, 'm-2':true, 'btn-primary':Params?.params.param == 'week'}" :href="'/admin/clients/week'">This Week</a>
        <a :class="{'btn':true, 'btn-sm':true, 'btn-default':true, 'm-2':true, 'btn-primary':Params?.params.param == 'today'}" :href="'/admin/clients/today'">Today</a>
    </div>
</div>
<!-- v-for="User in AllClients" :key="User"  -->
<div class="review-table">
    <div class="">
        <table class="table tabl-bordered" id="dataTable">
            <thead>
                <tr>
                    <th>Date/Time</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="Log in AllLogs" :key="Log.id">
                    <td>
                        [{{ Log.date_created }}]
                    </td>
                    <td>
                        {{ Log.message }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<!-- review-table -->
</template>

<script>
import $ from "jquery";
import {
    ref,
    onMounted
} from "vue";
import axios from "axios";
import {
    useRoute
} from "vue-router";
// import vueMask from 'vue-jquery-mask';
// console.log(localStorage);
export default {
    components: {},
    setup() {
        let base_url = "http://api.getstreamly.com/uploads/";
        const Params = useRoute();
        // let AllCustomersLists = ref([]);
        let AllLogs = ref([]);
        let filterBy = ref('');
        const getAllLogs = async () => {
            // let filterby = filterBy.value; 
            await axios
                .get(
                    axios.defaults.baseUrl + "admin/logs", {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    AllLogs.value = response.data.data.allLogs;
                    // datatableInit();
                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        const addClass = (elementName, className) => {
            $(elementName).addClass(className);
        }

        const removeClass = (elementName, className) => {
            $(elementName).removeClass(className);
        }
        const showToast = (toastIcon, toastMessage) => {
            window.toast.fire({
                icon: toastIcon,
                title: toastMessage,
            });
        }

        // Open Modal
        const openModal = () => {};

        // Close Modal
        const closeModal = () => {};
        const datatableInit = async () => {
            if ($.fn.DataTable.isDataTable("#dataTable")) {
                $("#dataTable").DataTable().clear().destroy();
            }
            $("#dataTable").addClass("nowrap").dataTable({
              searching: false,
                order: [],
                columnDefs: [{
                        targets: [0],
                        orderable: false
                    },
                    {
                        targets: [1],
                        orderable: false
                    }
                ]
            });
            $(".display").addClass("nowrap").dataTable();

        }
        onMounted(() => {

            console.log(Params.params.param);
            if (Params.params.param != null) {
                filterBy.value = Params.params.param;
            }
            getAllLogs(filterBy.value);
            
            setTimeout(function () {
              datatableInit();
            }, 2000);
            window.showHideMainLoader(false);
        });

        return {
            getAllLogs,
            openModal,
            closeModal,
            base_url,
            AllLogs,
            removeClass,
            addClass,
            showToast,
            filterBy,
            Params,
        };
    },
};
</script>
