<template>
<!-- setting-note -->
<div class="setting-note">
    <div class="row">
        <div class="col-md-8 col-xl-8 mb-4">
            <div class="review-goal">
                <h3>My Profile</h3>
                <div class="contact-form">
                    <form>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label for="create_contact_first_name">
                                    <p class="label-txt label-active">First Name</p>
                                    <input type="text" class=" input" id="create_contact_first_name" v-model="profile_first_name" readonly>
                                </label>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="create_contact_last_name">
                                    <p class="label-txt label-active">Last Name</p>
                                    <input type="text" class=" input" id="create_contact_last_name" v-model="profile_last_name" readonly>
                                </label>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="create_contact_email">
                                    <p class="label-txt label-active">Email Address</p>
                                    <input type="email" class=" input" id="create_contact_email" v-model="profile_email" readonly>
                                </label>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="create_contact_phone">
                                    <p class="label-txt label-active">Phone no</p>
                                    <input type="text" class=" input" id="create_contact_phone" v-model="profile_phone" readonly>
                                </label>
                            </div>
                            <div class="form-group col-md-12">
                                <label for="create_contact_address">
                                    <p class="label-txt label-active">Full Address</p>
                                    <textarea class="input" id="create_contact_address" rows="2" v-model="profile_address" readonly></textarea>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="col-md-4 col-xl-4 mb-4">
            <div class="review-goal">
                <h3>Phone number for SMS
                    <div class="float-right">
                        <button v-if="phone_no_request == 0" type="button" class="btn btn-sm btn-primary" @click="RequestPhoneNo">Request
                            Phone No.</button>
                        <button v-else-if="phone_no_request == 2" type="button" class="btn btn-sm btn-primary">Requested</button>
                    </div>
                </h3>
                <div class="contact-form">
                    <form>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <label for="twilio_phone_no">
                                    <p class="label-txt label-active label-active">Phone no</p>
                                    <input type="text" class=" input" id="twilio_phone_no" v-model="twilio_number" readonly />
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
                <h3>Phone number for Notification
                    <div class="float-right">
                        <button type="button" class="btn btn-sm btn-primary" @click="SubmitNotificationPhone">Update</button>
                    </div>
                </h3>
                <div class="contact-form">
                    <form>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <label for="notification_phone_no">
                                    <p class="label-txt label-active label-active">Phone no</p>
                                    <vue-mask class="form-control" mask="(000)000-0000" :options="options" id="notification_phone_no" placeholder="(xxx)xxx-xxxx" v-model="notification_phone_no" @click="removeClass('#notification_phone_no', 'red-border')"></vue-mask>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
                <h3 v-if="profile_user_type == 'dealer'">Phone number for OTP
                    <div class="float-right">
                        <button type="button" class="btn btn-sm btn-primary" @click="SubmitOtpPhone">Update</button>
                    </div>
                </h3>
                <p v-if="profile_user_type == 'dealer'">Enable OTP for Parent account?
                    <span class="float-right">
                        <!-- Rounded switch -->
                        <label class="switch">
                            <input type="checkbox" class="switch-checkbox" :checked="enable_otp_parent == 1" @change="optOTP('parent')" />
                            <span class="slider round"></span>
                        </label>
                    </span>
                </p>
                <p v-if="profile_user_type == 'dealer'">Enable OTP for Team members?
                    <span class="float-right">
                        <!-- Rounded switch -->
                        <label class="switch">
                            <input type="checkbox" class="switch-checkbox" :checked="enable_otp_team == 1" @change="optOTP('team')" />
                            <span class="slider round"></span>
                        </label>
                    </span>
                </p>
                <div class="contact-form" v-if="profile_user_type == 'dealer'">
                    <form>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <label for="otp_phone_no">
                                    <p class="label-txt label-active label-active">Phone no</p>
                                    <vue-mask class="form-control" mask="(000)000-0000" :options="options" id="otp_phone_no" placeholder="(xxx)xxx-xxxx" v-model="otp_phone_no" @click="removeClass('#otp_phone_no', 'red-border')"></vue-mask>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
                <h3>Email Address for CRM
                    <div class="float-right">
                        <button type="button" class="btn btn-sm btn-primary" @click="SubmitCrmEmail">Update</button>
                    </div>
                </h3>
                <div class="contact-form">
                    <form>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <label for="crm_email">
                                    <p class="label-txt label-active label-active">Email Address</p>
                                    <input type="text" class=" input" id="crm_email" v-model="crm_email" />
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
                <div v-if="twilio_number.value != ''">
                    <h3>Messaging iframe
                        <div class="float-right">
                            <button type="button" class="btn btn-sm btn-primary" @click="CopyCode">Copy</button>
                        </div>
                    </h3>
                    <div class="contact-form">
                        <form>
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <label for="crm_email">
                                        <p class="label-txt label-active label-active"></p>
                                        <input type="text" class=" input" id="messaging_script" v-model="messaging_script" ref="message" readonly />
                                    </label>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- setting-note -->
</template>

<style>
/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
}

input:checked+.slider {
    background-color: #6046fe;
}

input:focus+.slider {
    box-shadow: 0 0 1px #6046fe;
}

input:checked+.slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* The switch - the box around the slider */
</style>

<script>
import $ from "jquery";
import {
    ref,
    onMounted
} from "vue";
import vueMask from 'vue-jquery-mask';
import axios from "axios";
export default {
    components: {
        vueMask
    },
    setup() {
        let base_url = "http://api.streamly.com/uploads/";
        let profile_user_type = ref('');
        let profile_first_name = ref('');
        let profile_last_name = ref('');
        let profile_email = ref('');
        let profile_phone = ref('');
        let profile_address = ref('');
        let phone_no_request = ref('');
        let crm_email = ref('');
        let messaging_script = ref('');
        let notification_phone_no = ref('');
        let otp_phone_no = ref('');
        let twilio_number = ref('(XXX) XXX-XXXX');
        let enable_otp_parent = ref(0);
        let enable_otp_team = ref(0);

        const getUserProfile = async () => {
            let profileFormData = new FormData();
            profileFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            profileFormData.append('location_id', localStorage.getItem('location_id'));
            window.showHideMainLoader(true);
            await axios
                .post(
                    axios.defaults.baseUrl + "profile-settings",
                    profileFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
                .then((response) => {
                    let response_data = response.data.data;
                    profile_user_type.value = response_data.user_type;
                    profile_first_name.value = response_data.first_name;
                    profile_last_name.value = response_data.last_name;
                    profile_email.value = response_data.email;
                    profile_phone.value = response_data.phone;
                    profile_address.value = response_data.address;
                    if (response_data.phone_no_request == 1) {
                        twilio_number.value = response_data.twilio_phone;
                        messaging_script.value = response_data.messaging_script;
                    }
                    phone_no_request.value = response_data.phone_no_request;
                    crm_email.value = response_data.crm_email;
                    notification_phone_no.value = response_data.notification_phone_no;
                    otp_phone_no.value = response_data.two_factor_number;
                    enable_otp_parent.value = response_data.enable_otp_parent;
                    enable_otp_team.value = response_data.enable_otp_team;
                });
        }
        const RequestPhoneNo = async () => {
            window.showHideMainLoader(true);
            await axios
                .get(
                    axios.defaults.baseUrl + "request-twilio-phone-no", {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
                .then((response) => {
                    console.log(response);
                    phone_no_request.value = 2;
                    window.showHideMainLoader(false);
                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        const SubmitCrmEmail = async () => {
            window.showHideMainLoader(true);
            let updateFormData = new FormData();
            updateFormData.append('crm_email', crm_email.value);
            await axios
                .post(
                    axios.defaults.baseUrl + "update-dealer-crm-email",
                    updateFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
                .then((response) => {
                    // console.log(response);
                    showToast(response.data.status, response.data.message);
                    window.showHideMainLoader(false);
                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        const SubmitNotificationPhone = async () => {
            // console.log();
            window.showHideMainLoader(true);
            let updateFormData = new FormData();
            updateFormData.append('notification_phone_no', notification_phone_no.value);
            await axios
                .post(
                    axios.defaults.baseUrl + "update-dealer-notification-phone",
                    updateFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
                .then((response) => {
                    // console.log(response);
                    showToast(response.data.status, response.data.message);
                    window.showHideMainLoader(false);
                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        const SubmitOtpPhone = async () => {
            // console.log();
            window.showHideMainLoader(true);
            let updateFormData = new FormData();
            updateFormData.append('two_factor_number', otp_phone_no.value);
            await axios
                .post(
                    axios.defaults.baseUrl + "update-dealer-otp-phone",
                    updateFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
                .then((response) => {
                    // console.log(response);
                    showToast(response.data.status, response.data.message);
                    window.showHideMainLoader(false);
                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }

        const optOTP = async (type = null) => {
            // console.log();
            window.showHideMainLoader(true);
            let updateFormData = new FormData();
            if (type == 'parent') {
                updateFormData.append('enable_otp_parent', 'yes');
            } else {
                updateFormData.append('enable_otp_team', 'yes');
            }
            await axios
                .post(
                    axios.defaults.baseUrl + "update-dealer-otp-otpin",
                    updateFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
                .then((response) => {
                    // console.log(response);
                    showToast(response.data.status, response.data.message);
                    if(type == 'parent'){
                      enable_otp_parent.value = response.data.data.enable_otp_parent;
                    }else{
                      enable_otp_team.value = response.data.data.enable_otp_team;
                    }
                    window.showHideMainLoader(false);
                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        const CopyCode = () => {
            $('#messaging_script').select();
            document.execCommand('copy');
        }
        const addClass = (elementName, className) => {
            $(elementName).addClass(className);
        }

        const removeClass = (elementName, className) => {
            $(elementName).removeClass(className);
        }
        const showToast = (toastIcon, toastMessage) => {
            window.toast.fire({
                icon: toastIcon,
                title: toastMessage,
            });
        }
        // Open Modal
        const openModal = () => {
            // $("#featureNotAvailable").modal("show");
        };

        // Close Modal
        const closeModal = () => {
            // $("#featureNotAvailable").modal("hide");
        };

        onMounted(() => {
            getUserProfile();
            window.showHideMainLoader(false);
        });

        return {
            openModal,
            closeModal,
            base_url,
            profile_user_type,
            profile_first_name,
            profile_last_name,
            profile_email,
            profile_phone,
            profile_address,
            phone_no_request,
            twilio_number,
            crm_email,
            messaging_script,
            notification_phone_no,
            otp_phone_no,
            enable_otp_parent,
            enable_otp_team,
            RequestPhoneNo,
            SubmitCrmEmail,
            SubmitNotificationPhone,
            SubmitOtpPhone,
            optOTP,
            CopyCode,
            removeClass,
            addClass,
            showToast,
        };
    },
};
</script>
