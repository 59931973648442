<template>
<!-- services-area -->
<section class="streamly-services">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-10 offset-lg-1">
                <div class="service-heading">
                    <h2>We Listened! DealersGear CRM is automotive’s newest CRM. Built with the latest technological solutions, created directly from the feedback and complaints dealers have had with their CRM.</h2>
                    <p class="bar"></p>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="steamly-service">
                    <div class="d-flex">
                        <div class="flex-shrink-0">
                            <div class="number">
                                1
                            </div>
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <h3 v-if="urlparam == 'crm'">Strengthen Relationships, Drive Growth</h3>
                            <p v-if="urlparam == 'crm'">Streamly CRM empowers you to manage customer interactions effectively, building stronger bonds and fueling business growth.</p>

                            <h3 v-if="urlparam == 'email-marketing'">Streamly Email Marketing: A Powerful Tool</h3>
                            <p v-if="urlparam == 'email-marketing'">Email marketing remains a top digital marketing channel for businesses. Streamly's tools help you create targeted campaigns to nurture leads, drive sales, and build customer relationships.</p>

                            <h3 v-if="urlparam == 'text-marketing'">The Mobile Revolution and Customer Engagement</h3>
                            <p v-if="urlparam == 'text-marketing'">In today's fast-paced world, people are glued to their phones. Streamly Text Marketing lets you leverage this constant connection to reach your customers directly through SMS. With concise, targeted messages delivered straight to their mobile devices, you can boost engagement, drive sales, and build stronger customer relationships.</p>

                            <h3 v-if="urlparam == 'reputation-management'">The Digital Age of Reputation</h3>
                            <p v-if="urlparam == 'reputation-management'">In today's digital age, online reviews and social media conversations hold immense power in shaping a business's reputation. Streamly Reputation Management empowers you to take control of your online presence and foster a positive brand image. By monitoring online mentions, responding to reviews, and proactively managing your brand's narrative, Streamly helps you build trust with potential customers and safeguard your reputation.</p>

                            <h3 v-if="urlparam == 'marketing-integrations'">The Power of Cohesive Marketing</h3>
                            <p v-if="urlparam == 'marketing-integrations'">Today's marketing landscape demands a cohesive approach. Customers navigate a complex web of touchpoints, and your marketing efforts need to reflect that. Streamly Marketing Integrations connect your CRM, email marketing, text marketing, and reputation management tools seamlessly, fostering a unified customer experience. This interconnected ecosystem empowers you to leverage valuable customer data across all your marketing channels, delivering personalized messages and driving optimal results.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="steamly-service">
                    <div class="d-flex">
                        <div class="flex-shrink-0">
                            <div class="number">
                                2
                            </div>
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <h3 v-if="urlparam == 'crm'">Centralized Customer Hub</h3>
                            <p v-if="urlparam == 'crm'">Streamly CRM acts as a central hub for all your customer data. Access contact details, purchase history, interactions, and support tickets in one place. Gain deeper customer understanding and personalize your approach.</p>

                            <h3 v-if="urlparam == 'email-marketing'">Measurable Marketing Advantage</h3>
                            <p v-if="urlparam == 'email-marketing'">Unlike traditional marketing, email marketing with Streamly is measurable. Track key metrics like open rates and click-through rates to gain insights into audience engagement and campaign effectiveness. Plus, it's cost-effective, reaching a wider audience for less.</p>

                            <h3 v-if="urlparam == 'text-marketing'">Cut Through the Noise: The Power of Text Marketing</h3>
                            <p v-if="urlparam == 'text-marketing'">Emails often get lost in overflowing inboxes, but SMS messages have a near-perfect open rate. Streamly helps you craft concise, impactful messages that cut through the noise and reach your audience instantly. Text marketing allows for immediate engagement, ideal for sending appointment reminders, flash sales, or time-sensitive offers.</p>

                            <h3 v-if="urlparam == 'reputation-management'">Never Miss a Beat with Streamly Monitoring</h3>
                            <p v-if="urlparam == 'reputation-management'">Stay ahead of the curve with Streamly's comprehensive monitoring tools. Our software tracks brand mentions across various platforms, including review sites, social media, and news outlets. This allows you to identify both positive and negative feedback in real-time. With this instant awareness, you can address concerns swiftly and capitalize on opportunities to showcase your brand's strengths.</p>

                            <h3 v-if="urlparam == 'marketing-integrations'">Streamlining Workflows for Maximum Efficiency</h3>
                            <p v-if="urlparam == 'marketing-integrations'">Streamly eliminates the need to juggle multiple marketing platforms. Our integrations create a centralized hub for managing all your customer interactions and marketing campaigns. By automating tasks and eliminating data silos, Streamly frees up valuable time and resources. This allows your marketing team to focus on strategic initiatives and deliver exceptional results, maximizing efficiency and impact.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="steamly-service">
                    <div class="d-flex">
                        <div class="flex-shrink-0">
                            <div class="number">
                                3
                            </div>
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <h3 v-if="urlparam == 'crm'">Boost Sales & Marketing</h3>
                            <p v-if="urlparam == 'crm'">Equip your sales and marketing teams with the tools they need to succeed. Streamly CRM helps prioritize leads, personalize outreach, track opportunities, and launch targeted campaigns for maximum impact.</p>

                            <h3 v-if="urlparam == 'email-marketing'">Streamline the Lead Nurturing Process</h3>
                            <p v-if="urlparam == 'email-marketing'">Streamly goes beyond one-off emails. Create automated email sequences that nurture leads throughout their buying journey. Provide valuable information, address customer concerns, and keep your brand top-of-mind with targeted email campaigns.</p>

                            <h3 v-if="urlparam == 'text-marketing'">Hyper-Targeting for Personalized Impact</h3>
                            <p v-if="urlparam == 'text-marketing'">Streamly's text marketing platform allows you to segment your audience based on demographics, purchase history, and other key factors. This enables you to send targeted text messages that resonate with specific customer segments, maximizing the impact of your campaigns. Additionally, Streamly integrates seamlessly with your CRM, allowing you to personalize text messages with customer names and relevant offers for a more impactful experience.</p>

                            <h3 v-if="urlparam == 'reputation-management'">Responding with Authenticity: A Streamlined Approach</h3>
                            <p v-if="urlparam == 'reputation-management'">Streamly streamlines the process of responding to online reviews. Our platform provides a centralized location to manage all your reviews, allowing you to respond promptly and professionally. We empower you to craft personalized responses that address customer concerns, thank satisfied customers, and demonstrate your commitment to providing exceptional service.</p>

                            <h3 v-if="urlparam == 'marketing-integrations'">Unlocking Deeper Customer Insights</h3>
                            <p v-if="urlparam == 'marketing-integrations'">Streamly integrations unlock a wealth of customer data. By unifying information from your CRM, email marketing, and text marketing campaigns, you gain a deeper understanding of your audience's preferences and behaviors. This allows you to personalize your marketing messages, tailoring content and offers to resonate with specific customer segments. This results in a more impactful experience for your customers and strengthens your brand loyalty.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="steamly-service">
                    <div class="d-flex">
                        <div class="flex-shrink-0">
                            <div class="number">
                                4
                            </div>
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <h3 v-if="urlparam == 'crm'">Deliver Exceptional Service</h3>
                            <p v-if="urlparam == 'crm'">Happy customers are loyal customers. Streamly CRM empowers your support team to deliver exceptional service. Agents gain a complete view of each customer's history, allowing them to resolve issues faster and provide personalized solutions.</p>

                            <h3 v-if="urlparam == 'email-marketing'">Convert Leads into Customers</h3>
                            <p v-if="urlparam == 'email-marketing'">Streamly's email marketing features help nurture leads with informative content and special offers. This approach effectively converts leads into paying customers, growing your business.</p>

                            <h3 v-if="urlparam == 'text-marketing'">Driving Sales Beyond the Inbox</h3>
                            <p v-if="urlparam == 'text-marketing'">From promoting new products and special offers to sending order confirmations and shipping updates, Streamly Text Marketing keeps your customers informed and engaged. You can leverage SMS for two-way communication, facilitating customer support inquiries and building a more personalized connection with your audience.</p>

                            <h3 v-if="urlparam == 'reputation-management'">Proactive Reputation Building: Beyond Reaction</h3>
                            <p v-if="urlparam == 'reputation-management'">Streamly goes beyond reactive measures. Our tools empower you to proactively build a positive online reputation. By encouraging satisfied customers to leave reviews and sharing positive brand stories on social media, you can cultivate a strong online presence and establish trust with potential customers. Streamly helps you showcase the best of your brand and build lasting customer loyalty.</p>

                            <h3 v-if="urlparam == 'marketing-integrations'">Measuring Success Across the Board</h3>
                            <p v-if="urlparam == 'marketing-integrations'">With Streamly Marketing Integrations, you gain a holistic view of your marketing performance. By tracking key metrics across all your marketing channels in one place, you can identify what's working and what's not. This data-driven approach allows you to optimize your campaigns for maximum ROI and continuously improve your customer engagement strategy. Streamly empowers you to make data-driven decisions and achieve your marketing goals.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="steamly-service">
                    <div class="d-flex">
                        <div class="flex-shrink-0">
                            <div class="number">
                                5
                            </div>
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <h3 v-if="urlparam == 'crm'">Data-Driven Decisions</h3>
                            <p v-if="urlparam == 'crm'">Streamly CRM goes beyond contact management. Gain valuable business insights with built-in analytics that track key sales and marketing metrics. Make informed decisions based on trends and optimize your business strategy.</p>

                            <h3 v-if="urlparam == 'email-marketing'">Grow Your Business with Streamly</h3>
                            <p v-if="urlparam == 'email-marketing'">Email marketing is powerful for driving sales, brand awareness, and customer loyalty. Streamly's platform offers everything you need for successful email campaigns, from managing subscribers to tracking performance.</p>

                            <h3 v-if="urlparam == 'text-marketing'">Building Two-Way Communication</h3>
                            <p v-if="urlparam == 'text-marketing'">Streamly goes beyond simple broadcasts. Their platform allows for two-way communication, facilitating customer support inquiries and building a more personalized connection with your audience. This can be invaluable for resolving issues quickly and fostering customer loyalty.</p>

                            <h3 v-if="urlparam == 'reputation-management'">Building Advocacy: Empowering Satisfied Customers</h3>
                            <p v-if="urlparam == 'reputation-management'">Streamly empowers you to leverage the voices of your satisfied customers. With our tools, you can encourage them to leave positive reviews on relevant platforms. Positive reviews not only showcase the quality of your product or service but also build trust with potential customers searching for your brand online.</p>

                            <h3 v-if="urlparam == 'marketing-integrations'">Simplifying Compliance Management</h3>
                            <p v-if="urlparam == 'marketing-integrations'">Marketing campaigns must navigate complex compliance regulations. Streamly helps you navigate these complexities. By ensuring consistent messaging across all your marketing channels, you can streamline compliance efforts and minimize the risk of errors. This reduces the burden of compliance management and allows your team to focus on creative marketing initiatives.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="steamly-service">
                    <div class="d-flex">
                        <div class="flex-shrink-0">
                            <div class="number">
                                6
                            </div>
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <h3 v-if="urlparam == 'crm'">Improved Collaboration</h3>
                            <p v-if="urlparam == 'crm'">Streamly CRM fosters collaboration within your organization. Sales and marketing teams can work seamlessly, sharing customer data and campaign insights for a unified, cohesive customer experience.</p>

                            <h3 v-if="urlparam == 'email-marketing'">Unleash the Power of Email Marketing</h3>
                            <p v-if="urlparam == 'email-marketing'">Streamly empowers businesses to connect with their audience on a deeper level. Create engaging content and personalized messages to foster brand loyalty and trust.</p>

                            <h3 v-if="urlparam == 'text-marketing'">Measuring Success with Streamly</h3>
                            <p v-if="urlparam == 'text-marketing'">Streamly provides valuable analytics to track the success of your text marketing campaigns. You can monitor key metrics like open rates, click-through rates, and conversion rates to measure the effectiveness of your messages and refine your strategies for continuous improvement.</p>

                            <h3 v-if="urlparam == 'reputation-management'">Harnessing the Power of Social Proof</h3>
                            <p v-if="urlparam == 'reputation-management'">Positive online reviews and brand stories act as social proof, influencing the purchasing decisions of potential customers. Streamly helps you share positive brand stories on social media, amplifying your brand advocacy and building a strong online reputation.</p>

                            <h3 v-if="urlparam == 'marketing-integrations'">Building Brand Consistency: A Seamless Experience</h3>
                            <p v-if="urlparam == 'marketing-integrations'">A unified marketing approach fosters brand consistency. Streamly Integrations ensure that your brand voice, messaging, and visuals are consistent across all your marketing touchpoints, creating a seamless and memorable customer experience. This consistency builds trust and reinforces your brand identity in the minds of your customers.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="steamly-service">
                    <div class="d-flex">
                        <div class="flex-shrink-0">
                            <div class="number">
                                7
                            </div>
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <h3 v-if="urlparam == 'crm'">Secure & Scalable Growth</h3>
                            <p v-if="urlparam == 'crm'">Streamly CRM prioritizes security. Industry-standard encryption and access controls protect your valuable customer data. Additionally, Streamly scales with your business, ensuring you have the tools you need for continued success.</p>

                            <h3 v-if="urlparam == 'email-marketing'">Achieve Your Growth Goals with Streamly</h3>
                            <p v-if="urlparam == 'email-marketing'">Leverage the power of email marketing with Streamly. Connect with your audience, nurture leads, and ultimately achieve your business growth goals through effective email marketing campaigns.</p>

                            <h3 v-if="urlparam == 'text-marketing'">Streamly: Your Text Marketing Partner</h3>
                            <p v-if="urlparam == 'text-marketing'">Streamly Text Marketing empowers businesses to drive sales, improve customer engagement, and build lasting relationships through the power of text marketing. With its user-friendly platform, powerful targeting options, and built-in analytics, Streamly is your one-stop shop for taking your mobile marketing efforts to the next level.</p>
                            
                            <h3 v-if="urlparam == 'reputation-management'">Streamly: Your Partner in Reputation Management</h3>
                            <p v-if="urlparam == 'reputation-management'">Streamly Reputation Management equips you with the tools and strategies to navigate the ever-evolving online landscape. Our platform empowers you to build trust, safeguard your brand reputation, and cultivate lasting customer loyalty in the digital age.</p>

                            <h3 v-if="urlparam == 'marketing-integrations'">Streamly: Your Partner in Unified Marketing Success</h3>
                            <p v-if="urlparam == 'marketing-integrations'">Streamly Marketing Integrations provide a powerful solution for unifying your marketing efforts. Our platform empowers you to streamline workflows, gain deeper customer insights, measure success across channels, and ultimately achieve your marketing goals. With Streamly, you can build stronger brand consistency, ensure compliance, and cultivate lasting customer relationships. Streamly is your partner in achieving unified marketing success.</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- services-area -->
</template>

<style>
</style>

<script>
// import $ from "jquery";
import {
    ref,
    onMounted
} from "vue";
import {
    useRoute
} from "vue-router";
// import axios from "axios";

export default {
    components: {},
    setup() {
        let base_url = "http://api.getstreamly.com/uploads/";
        const Params = useRoute();
        let urlparam = ref('');
        // Open Modal
        const openModal = () => {
            // $("#featureNotAvailable").modal("show");
        };

        // Close Modal
        const closeModal = () => {
            // $("#featureNotAvailable").modal("hide");
            // $("#create-appointment").modal("hide");
        };
        const showToast = (toastIcon, toastMessage) => {
            window.toast.fire({
                icon: toastIcon,
                title: toastMessage,
            });
        }
        onMounted(() => {
            console.log(Params.params.service);
            urlparam.value = Params.params.service;
            window.showHideMainLoader(false);
        });

        return {
            openModal,
            closeModal,
            base_url,
            showToast,
            urlparam,
        };
    },
};
</script>