<template>

  <!-- search-top -->
  <div class="search-top">
    <div class="row">
      <div class="col-md-4 col-lg-4">
        <div class="search-box">
          <form>
            <div class="form-group">
              <input type="search" class="form-control" aria-label="search" placeholder="Search">
              <img class="img-fluid sea-icon" src="@/assets/img/search.svg" alt="image title">
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-8 col-lg-8">
        <div class="export-right version2">
          <div class="export">
            <a href="#"> <img class="img-fluid" src="@/assets/img/export.svg" alt="image title"> Export</a>
          </div>
          <div class="todayselect">
            <div class="reportrange">
              This Month <i class="fa fa-angle-down" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- search-top -->


  <!-- review-table -->
  <div class="review-table">
    <table class="display" id="dataTable">
      <thead>
      <tr>
        <th>Name</th>
        <th>Rank</th>
        <th>Invites Sent</th>
        <th>Reviews</th>
        <th>Rating</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">4.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">3.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star-half" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">4.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">3.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star-half" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">4.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">3.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star-half" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">4.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">3.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star-half" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">4.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">3.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star-half" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">4.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">3.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star-half" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">4.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">3.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star-half" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">4.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">3.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star-half" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">4.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">3.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star-half" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">4.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>
          <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane Hawkins
          </a>
        </td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
        <td>
          <span class="ratee">3.9</span>
          <ul class="ratestar">
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star" aria-hidden="true"></i></li>
            <li class="yellow"><i class="fa fa-star-half" aria-hidden="true"></i></li>
            <li class="black"><i class="fa fa-star" aria-hidden="true"></i></li>
          </ul>
        </td>
      </tr>

      </tbody>
    </table>
  </div>
  <!-- review-table -->

</template>
<script>
import $ from "jquery";
import {onMounted} from "vue";

export default {
  components: {},
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    // Open Modal
    const openModal = () => {
      $("#featureNotAvailable").modal("show");
    };

    // Close Modal
    const closeModal = () => {
      $("#featureNotAvailable").modal("hide");
    };
    const datatableInit = async () => {
      if ($.fn.DataTable.isDataTable("#dataTable")) {
        $("#dataTable").DataTable().clear().destroy();
      }
      $("#dataTable").addClass("nowrap").dataTable({
        order: [],
        columnDefs: [
          {targets: [1], orderable: false}
        ]
      });
    }
    onMounted(() => {
      datatableInit();
      window.showHideMainLoader(false);
    });

    return {openModal, closeModal, base_url};
  },
};
</script>