<template>

    <!-- search-top -->
    <div class="search-top">
      <div class="row">
        <div class="col-md-6 col-lg-4">
          <div class="search-box">
            <form>
              <div class="form-group">
                <input type="search" class="form-control" placeholder="Search" aria-label="search">
                <img class="img-fluid sea-icon" src="@/assets/img/search.svg" alt="image title">
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
    <!-- search-top -->

    <!-- review-table -->
    <div class="review-table">
      <table class="display" id="dataTable">
        <thead>
        <tr>
          <th>User Name</th>
          <th>Location</th>
          <th>Assign To</th>
          <th>Message Received</th>
          <th>Message Sent</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>Schulist Ltd
            <p>2130 George Avenue, Louisville, <br> KY, Kentucky 40261, USA</p>
          </td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>320</td>
          <td>300</td>
        </tr>
        <tr>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>Schulist Ltd
            <p>2130 George Avenue, Louisville, <br> KY, Kentucky 40261, USA</p>
          </td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>320</td>
          <td>300</td>
        </tr>
        <tr>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>Schulist Ltd
            <p>2130 George Avenue, Louisville, <br> KY, Kentucky 40261, USA</p>
          </td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>320</td>
          <td>300</td>
        </tr>
        <tr>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>Schulist Ltd
            <p>2130 George Avenue, Louisville, <br> KY, Kentucky 40261, USA</p>
          </td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>320</td>
          <td>300</td>
        </tr>
        <tr>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>Schulist Ltd
            <p>2130 George Avenue, Louisville, <br> KY, Kentucky 40261, USA</p>
          </td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>320</td>
          <td>300</td>
        </tr>
        <tr>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>Schulist Ltd
            <p>2130 George Avenue, Louisville, <br> KY, Kentucky 40261, USA</p>
          </td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>320</td>
          <td>300</td>
        </tr>
        <tr>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>Schulist Ltd
            <p>2130 George Avenue, Louisville, <br> KY, Kentucky 40261, USA</p>
          </td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>320</td>
          <td>300</td>
        </tr>
        <tr>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>Schulist Ltd
            <p>2130 George Avenue, Louisville, <br> KY, Kentucky 40261, USA</p>
          </td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>320</td>
          <td>300</td>
        </tr>
        <tr>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>Schulist Ltd
            <p>2130 George Avenue, Louisville, <br> KY, Kentucky 40261, USA</p>
          </td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>320</td>
          <td>300</td>
        </tr>
        <tr>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>Schulist Ltd
            <p>2130 George Avenue, Louisville, <br> KY, Kentucky 40261, USA</p>
          </td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>320</td>
          <td>300</td>
        </tr>


        </tbody>
      </table>
    </div>
    <!-- review-table -->

</template>
<script>
import $ from "jquery";
 import {onMounted} from "vue";

export default {
  components: {

  },
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    // Open Modal
    const openModal = () => {
      $("#featureNotAvailable").modal("show");
    };

    // Close Modal
    const closeModal = () => {
      $("#featureNotAvailable").modal("hide");
    };
    const datatableInit = async () => {
      if ($.fn.DataTable.isDataTable("#dataTable")) {
        $("#dataTable").DataTable().clear().destroy();
      }
      $("#dataTable").addClass("nowrap").dataTable({
        order: [],
        columnDefs: [
          {targets: [1], orderable: false}
        ]
      });
    }
    onMounted(() => {
      datatableInit();
      window.showHideMainLoader(false);
    });

    return {openModal, closeModal, base_url};
  },
};
</script>