<template>
<div>
    <!-- email-template -->
    <div class="email-template">
        <div class="row">
            <div class="col-md-4">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a class="nav-link active" id="v-pills-touch-tab" data-toggle="pill" href="#v-pills-touch" role="tab" aria-controls="v-pills-touch" aria-selected="true" @click="resetTemplateValue">Create New Template</a>
                    <a class="nav-link" id="v-pills-touch-tab" data-toggle="pill" :href="'#v-pills-touch' + Template.id" role="tab" aria-controls="v-pills-touch" aria-selected="true" v-for="Template in AllTemplates" :key="Template" @click="putTemplateValue(Template)">{{ Template.title }}</a>
                </div>
            </div>
            <div class="col-md-8">
                <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade show active" id="v-pills-touch" role="tabpanel" aria-labelledby="v-pills-touch-tab">
                        <div class="email-boxs">
                            <div class="row">
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <div class="send-btns">
                                        <div class="button-wrapper">
                                            <span class="label">
                                                <img class="img-fluid" src="@/assets/img/upload.svg" alt="image title"> Attach file
                                            </span>
                                            <input type="file" id="upload" @change="uploadFile" class="upload-box" placeholder="Upload File" aria-label="Upload File" accept="images/*" />
                                        </div>
                                    </div>
                                    <div class="template-img-box">
                                        <img id="empty-template" class="img-fluid" src="@/assets/img/placeholder-img.png" />
                                    </div>
                                </div>
                                <div class="col-lg-10 col-md-9 col-sm-8">
                                    <div class="text-box">
                                        <input type="text" class="form-control" aria-label="Message" placeholder="Template Title" id="template_title" v-model="template_form.template_title" @click="removeClass('#template_title', 'red-border')" />
                                    </div>
                                    <div class="text-box">
                                        <textarea id="template_textarea" class="form-control" v-model="template_form.template_textarea" placeholder="Template Message..." @keyup="countChar($event)" @click="removeClass('#template_textarea', 'red-border')"></textarea>
                                        <div id="charNum">0/160</div>
                                    </div>
                                    <!-- <div class="">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <label class="checkbox-containers"><span class="label">Attach File?</span>
                                                <input type="hidden" name="attach_file" value="NO" />
                                                <input type="checkbox" name="attach_file" value="yes" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div> -->
                                    <div class="text-template variable">
                                        <p>Variable :</p>
                                        <ul>
                                            <li><a href="javascript:;" @click="variable_inupt($event)">%customername%</a></li>
                                            <!-- <li><a href="javascript:;" @click="variable_inupt($event)">%Last-Name%</a></li>
                                            <li><a href="javascript:;" @click="variable_inupt($event)">%companyname%</a></li>
                                            <li><a href="javascript:;" @click="variable_inupt($event)">%companyphone%</a></li>
                                            <li><a href="javascript:;" @click="variable_inupt($event)">%companyemail%</a></li>
                                            <li><a href="javascript:;" @click="variable_inupt($event)">%companyaddress%</a></li> -->
                                        </ul>
                                    </div>
                                    <div class="modal-footer email-footer">
                                        <a href="javascript:;" @click="addTemplate" class="add-btn">Save</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" :id="'v-pills-touch' + Template.id" role="tabpanel" aria-labelledby="v-pills-touch-tab" v-for="Template in AllTemplates" :key="Template">
                        <div class="email-boxs">
                            <div class="row">
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <div class="send-btns">
                                        <div class="button-wrapper">
                                            <span class="label">
                                                <img class="img-fluid" src="@/assets/img/upload.svg" alt="image title"> Attach file
                                            </span>
                                            <input type="file" id="upload" @change="uploadFile($event,Template.id)" class="upload-box" placeholder="Upload File" aria-label="Upload File" accept="images/*" />
                                        </div>
                                    </div>
                                    <div class="template-img-box">
                                        <img :id="'template-image-'+Template.id" class="img-fluid" src="@/assets/img/placeholder-img.png" />
                                    </div>
                                </div>
                                <div class="col-lg-10 col-md-9 col-sm-8">
                                    <div class="text-box">
                                        <input type="text" class="form-control" aria-label="Template Name" placeholder="Template Title" v-model="template_form.template_title" />
                                    </div>
                                    <div class="text-box">
                                        <textarea id="editortiny2" class="form-control" v-model="template_form.template_textarea" placeholder="Template Message..." @keyup="countChar($event)"></textarea>
                                        <div id="charNum2"></div>
                                    </div>
                                    <div class="text-template variable">
                                        <p>Variable :</p>
                                        <ul>
                                            <li><a href="javascript:;" @click="variable_inupt($event)">%customername%</a></li>
                                            <!-- <li><a href="javascript:;" @click="variable_inupt($event)">%Last-Name%</a></li>
                                            <li><a href="javascript:;" @click="variable_inupt($event)">%companyname%</a></li>
                                            <li><a href="javascript:;" @click="variable_inupt($event)">%companyphone%</a></li>
                                            <li><a href="javascript:;" @click="variable_inupt($event)">%companyemail%</a></li>
                                            <li><a href="javascript:;" @click="variable_inupt($event)">%companyaddress%</a></li> -->
                                        </ul>
                                    </div>
                                    <div class="modal-footer email-footer">
                                        <a href="javascript:;" @click="handleMTemplateDeleteRequest(Template.id)" class="rem-btn">Delete</a>
                                        <a href="javascript:;" @click="handleMTemplateUpdateRequest(Template.id)" class="add-btn">Update</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- email-template -->
</div>
</template>

<style>
.checkbox-containers {
    display: block;
    position: relative;
    padding-left: 35px;
    margin: 5px 0;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-weight: 500;
    line-height: 1.5;
    border: none;
    border-radius: 8px;
    padding: 10px;
    height: 74px;
    transition: all 0.4s ease;
}

.checkbox-containers input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox-containers span.label {
    margin-left: 15px;
}

.checkbox-containers .checkmark {
    position: absolute;
    top: 10px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #DDDAE7;
}

.template-img-box {
    margin: 10px;
    margin-right: 0;
    clear: both;
    border: 5px solid #f1f1f1;
    border-radius: 8px;
}

.template-img-box img {
    object-fit: cover;
    vertical-align: bottom;
}
</style>

<script>
import $ from "jquery";
import {
    ref,
    onMounted
} from "vue";
import axios from "axios";
// import TinyMceEditor from '@tinymce/tinymce-vue'
export default {
    components: {
        // TinyMceEditor
    },
    /*
      head: {
        script: [
          { type: 'text/javascript', src: 'https://cdn.tiny.cloud/1/no-api-key/tinymce/6/tinymce.min.js', async: true, body: true }, // Insert in body
          { type: 'text/javascript', src: 'https://cdn.jsdelivr.net/npm/@tinymce/tinymce-jquery@1/dist/tinymce-jquery.min.js', async: true, body: true }, // Insert in body
        ],
      },
    */
    setup() {
        let base_url = "http://api.streamly.com/uploads/";
        let image_url = "http://api.getstreamly.com/uploads/template/";
        let placholder_image = '@/assets/img/placeholder-img.png';
        let AllTemplates = ref([]);
        let company_detail = ref({
            company_name: '',
            company_email: '',
            company_phone: '',
            company_address: '',
        });
        let template_form = ref({
            template_image: '',
            template_title: '',
            template_textarea: '',
        });
        const getCompanyDetails = async () => {
            await axios
                .get(
                    axios.defaults.baseUrl + "profile", {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    company_detail.value.company_name = response.data.company_name;
                    company_detail.value.company_email = response.data.company_email;
                    company_detail.value.company_phone = response.data.company_phone;
                    company_detail.value.company_address = response.data.company_address;
                    // AllTemplates.value = response.data.data.allTemplates;
                    // datatableInit();
                })
                .catch((error) => {
                    console.log(error);
                    window.showHideMainLoader(false);
                    // showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        const getTextTemplates = async () => {
            const getFormData = new FormData();
            // getFormData.append('list_id', list_id);
            getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            getFormData.append('location_id', localStorage.getItem('location_id'));
            await axios
                .post(axios.defaults.baseUrl + "templates/text",
                    getFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    AllTemplates.value = response.data.data.allTemplates;
                    // datatableInit();
                    window.showHideMainLoader(false);
                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        const uploadFile = async (event = null, id = null) => {
            // console.log(event.target.files);
            if (event.target.files && event.target.files[0]) {
                var reader = new FileReader();
                reader.onload = function (e) {
                  if(id != null){
                    $('#template-image-'+id).attr('src', e.target.result);
                  }else{
                    $('#empty-template').attr('src', e.target.result);
                  }
                }
                reader.readAsDataURL(event.target.files[0]);
                template_form.value.template_image = event.target.files[0];
            }
        }
        const addTemplate = async () => {
            removeClass('.form-control', 'red-border');
            let validate = 1;
            console.log(validate);
            // console.log(register_form.value);
            if (template_form.value.template_title == '') {
                validate = 0;
                addClass('#template_title', 'red-border');
                showToast('error', 'Template Title is required');
                return false;
            }
            if (template_form.value.template_textarea == '') {
                validate = 0;
                addClass('#template_textarea', 'red-border');
                showToast('error', 'Template Content is required');
                return false;
            }
            if (validate == 1) {
                window.showHideMainLoader(true);
                let addFormData = new FormData();
                // addFormData.append('image', teamuser_image);
                addFormData.append('dealer_id', localStorage.getItem('dealer_id'));
                addFormData.append('location_id', localStorage.getItem('location_id'));
                addFormData.append('image', template_form.value.template_image);
                addFormData.append('title', template_form.value.template_title);
                addFormData.append('template', template_form.value.template_textarea);
                // console.log(template_form.value.template_title);
                // console.log(template_form.value.template_textarea);
                await axios
                    .post(
                        axios.defaults.baseUrl + "templates/text/add",
                        addFormData, {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("auth_token"),
                            },
                        }
                    )
                    .then((response) => {
                        window.toast.fire({
                            icon: response.data.status,
                            title: response.data.message,
                        });
                        // getCustomers($('#listId').val());
                        getTextTemplates();
                        // closeModal();
                        template_form.value = '';
                        // location.reload(); //-----remove later
                        resetTemplateValue();
                    });
            }
        }

        const handleMTemplateUpdateRequest = async (Id) => {
            let addFormData = new FormData();
            // addFormData.append('image', teamuser_image);
            addFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            addFormData.append('location_id', localStorage.getItem('location_id'));
            addFormData.append('title', template_form.value.template_title);
            addFormData.append('template', template_form.value.template_textarea);
            // console.log(template_form.value.template_title);
            // console.log(template_form.value.template_textarea);
            await axios
                .post(
                    axios.defaults.baseUrl + "templates/text/edit/" + Id,
                    addFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
                .then((response) => {
                    window.toast.fire({
                        icon: response.data.status,
                        title: response.data.message,
                    });
                    // getCustomers($('#listId').val());
                    getTextTemplates();
                    // closeModal();
                    template_form.value = '';
                    location.reload(); //-----remove later
                    resetTemplateValue();
                });
        }

        const handleMTemplateDeleteRequest = async (Id) => {
            await axios
                .delete(axios.defaults.baseUrl + "deleteTemplate/text/" + Id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem('auth_token'),
                    },
                })
                .then((response) => {
                    window.toast.fire({
                        icon: response.data.status,
                        title: response.data.message,
                    });
                    getTextTemplates();
                    // closePopup();
                    template_form.value = '';
                    location.reload(); //-----remove later
                    resetTemplateValue();

                })
        }
        const resetTemplateValue = async () => {
            // editMode.value = true;
            // template_form.value.Id = Member.id;
            template_form.value.template_title = '';
            template_form.value.template_textarea = '';
            template_form.value.template_image = '';
            $('#empty-template').attr('src', placholder_image.value);
        }
        const putTemplateValue = async (Template) => {
            // editMode.value = true;
            // template_form.value.Id = Member.id;
            template_form.value.template_title = Template.title;
            template_form.value.template_textarea = Template.template;
            // console.log(Template.image);
            if(Template.image){
              $('#template-image-'+Template.id).attr('src',image_url+Template.image);
            }else{
              $('#template-image-'+Template.id).attr('src',placholder_image.value);
            }
            // template_form.value.template_image = Template.image;
            // resetTemplateValue();
        }

        const variable_inupt = (event) => {
            // console.log(event.target.innerHTML);
            let thisVal = event.target.innerHTML;
            // let bookmark = TinyMceEditor.activeEditor.selection.getBookmark();
            // let description = TinyMceEditor.getInstanceById('editortiny').getContent();
            // let description = TinyMceEditor.activeEditor.getContent();
            if (thisVal == '%companyname%') {
                thisVal = company_detail.value.company_name;
            }
            if (thisVal == '%companyemail%') {
                thisVal = company_detail.value.company_email;
            }
            if (thisVal == '%companyphone%') {
                thisVal = company_detail.value.company_phone;
            }
            if (thisVal == '%companyaddress%') {
                thisVal = company_detail.value.company_address;
            }
            // console.log(thisVal);

            let description = template_form.value.template_textarea + thisVal;
            // console.log(description);
            template_form.value.template_textarea = description;
            setTimeout(function () {
                countChar();
            }, 300);

        }
        const countChar = (event = null) => {
            console.log(event);
            let maxLength = 320;
            let smsLength = 160;
            // let messageLength = event.target.value.length;
            let template_textarea = $('#template_textarea').val();
            // console.log(template_textarea);
            let messageLength = template_textarea.length;
            if (messageLength > 160) {
                smsLength = 320;
            }
            if (messageLength >= maxLength) {
                messageLength = template_textarea.substring(0, maxLength);
            } else {
                $('#charNum').text(messageLength + '/' + smsLength);
            }
            // $('#charNum').text(messageLength+'/'+maxCount);
        }
        const addClass = (elementName, className) => {
            $(elementName).addClass(className);
        }

        const removeClass = (elementName, className) => {
            $(elementName).removeClass(className);
        }
        const showToast = (toastIcon, toastMessage) => {
            window.toast.fire({
                icon: toastIcon,
                title: toastMessage,
            });
        }
        // Open Modal
        const openModal = () => {
            $("#featureNotAvailable").modal("show");
        };

        // Close Modal
        const closeModal = () => {
            $("#featureNotAvailable").modal("hide");
        };
        /*
            const datatableInit = async () => {
              if ($.fn.DataTable.isDataTable("#dataTable")) {
                $("#dataTable").DataTable().clear().destroy();
              }
              $("#dataTable").addClass("nowrap").dataTable({
                order: [],
                columnDefs: [
                  { targets: [1], orderable: false }
                ]
              });
            }
        */
        onMounted(() => {
            // datatableInit();
            getTextTemplates();
            getCompanyDetails();
            resetTemplateValue();
            window.showHideMainLoader(false);
        });

        return {
            openModal,
            closeModal,
            base_url,
            template_form,
            AllTemplates,
            addTemplate,
            handleMTemplateUpdateRequest,
            handleMTemplateDeleteRequest,
            resetTemplateValue,
            putTemplateValue,
            variable_inupt,
            countChar,
            company_detail,
            removeClass,
            addClass,
            showToast,

            uploadFile,
        };
    },
};
</script>
