import {ref} from "vue";
import axios from "axios";

export default () => {

    const results = ref([]);
    const errors = ref([]);
    const loading = ref(false);
    const status = ref(false);
    console.log(process.env.VUE_APP_BASE_URL);
    const callApi = async (url, options = {method: 'Get'}) => {
        loading.value = true;
        status.value = false;
        // axios.defaults.baseUrl = process.env.VUE_APP_BASE_URL
        axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
        const headers = {
            'Accept': "application/json",
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem("auth_token"),
            'FCM-Token': localStorage.getItem("FCM-Token"),
            'Location-Id': localStorage.getItem("Location-Id"),

        };

        // axios.defaults.headers['Accept'] = "application/json";
        //
        // axios.create({baseURL: process.env.VUE_APP_BASE_URL})


        const instance =axios.create({ baseURL: process.env.VUE_APP_BASE_URL, headers: headers});

        instance.request({url: url,...options})
            .then((response) => {
                results.value = response.data.data;
                status.value = true;
            })
            .catch((error) => {
                status.value = false;
                errors.value = error;


                handelErrorResponse(error)
            })
            .finally(() => {
                loading.value = false;
            });


    }

    const sendPostRequest = (url, data = {}) => {


        let formData = new FormData();

        Object.entries(data).forEach(([name, input_value]) => {


            formData.append(name, input_value)

        })

        formData.append('location_id',1);
        formData.append('dealer_id',1);
        callApi(url, {method: "post", data: formData});
    }
    const sendGetRequest = (url) => {

        callApi(url, {method: "GET"});
    }


    const handelErrorResponse = (response) => {

        const error_messages = Object.values(response.response.data.data ?? []);
        if (error_messages.length) {
            for (const error_message of error_messages) {

                window.toast.fire({
                    icon: "error",
                    title: error_message,
                });
            }
        } else {

            window.toast.fire({
                icon: "error",
                title: response.response.data.message,
            });
        }


    }

    return {results, errors, loading, status, callApi, sendPostRequest, sendGetRequest, handelErrorResponse}
}

