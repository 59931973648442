<template>
  <!-- setting-note -->
  <div class="setting-note">
    <div class="row">
      <div class="col-12 text-right">
        <div class="export-right">
          <div class="export">
            <router-link :to="'/admin/clients'">Go Back</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-8 col-xl-8 mb-4">
        <div class="review-goal">
          <h3>Client's Profile</h3>
          <div class="contact-form table-responsive">
            <table class="table table-bordered table-hover table-striped">
              <tbody>
                <tr>
                  <th>Client Name</th>
                  <td>{{ ClientData.first_name + ' ' + ClientData.last_name }}</td>
                </tr>
                <tr>
                  <th>Business Name</th>
                  <td>{{ ClientData.business_name }}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{{ ClientData.email }}</td>
                </tr>
                <tr>
                  <th>Phone No.</th>
                  <td>{{ '+1' + ClientData.phone }}</td>
                </tr>
                <tr>
                  <th>Address</th>
                  <td>{{ ClientData.address }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-xl-4 mb-4">
        <div class="review-goal">
          <h3>Phone number for SMS</h3>
          <div class="contact-form">
            <TwilioTrackingForm></TwilioTrackingForm>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- setting-note -->
</template>
<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
import TwilioTrackingForm from "./../../../components/TwilioTrackingNumbers/TwilioTrackingForm.vue";
export default {
  components: {
    TwilioTrackingForm,
  },
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    const Params = useRoute();
    // console.log(Params.params.id);
    let ClientData = ref([]);

    const getClient = async () => {
      let Id = Params.params.id;
      await axios
        .get(
          axios.defaults.baseUrl + "admin/clients/" + Id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem('auth_token'),
            },
          }
        )
        .then((response) => {
          console.log(response.data.data.allClients);
          ClientData.value = response.data.data.allClients;
          // datatableInit();
        })
        .catch((error) => {
          window.showHideMainLoader(false);
          showToast(error.response.data.status, error.response.data.message);
          return false;
        });
    }
    const addClass = (elementName, className) => {
      $(elementName).addClass(className);
    }

    const removeClass = (elementName, className) => {
      $(elementName).removeClass(className);
    }
    const showToast = (toastIcon, toastMessage) => {
      window.toast.fire({
        icon: toastIcon,
        title: toastMessage,
      });
    }
    // Open Modal
    const openModal = () => {

    };

    // Close Modal
    const closeModal = () => {

    };
    onMounted(() => {

      // // console.log(Params);
      // datatableInit();
      getClient();
      window.showHideMainLoader(false);
    });

    return {
      openModal,
      closeModal,
      base_url,
      ClientData,
      removeClass,
      addClass,
      showToast,
    };
  },
};
</script>