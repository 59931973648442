<template>

  <div class=" export add_action ">
    <a class="" data-toggle="modal" data-target="#createLocationModal"> <img class="img-fluid"
                                                                             src="@/assets/img/plus.svg"
                                                                             alt="Add Location"

    > Add Location</a>
  </div>
  <!-- add-member-Modal -->
  <div class="modal fade" id="createLocationModal" tabindex="-1" aria-labelledby="createLocationModal"
       aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createLocationModalLabel5">Add Locations</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <img class="img-fluid" src="@/assets/img/close.png" alt="">
          </button>
        </div>
        <form @submit.prevent="formProcess">

          <div class="modal-body">


            <div class="contact-form">
              <div class="row">
                <div class="form-group col-md-12">
                  <label>
                    <p class="label-txt">Title</p>
                    <input type="text" class=" input" required v-model="formData.title">

                  </label>

                </div>
                <div class="form-group col-md-12 mt-3">
                  <label>
                    <p class="label-txt">Address</p>
                    <textarea type="text" class=" input" required v-model="formData.address" rows="6"></textarea>
                  </label>
                </div>

              </div>


            </div>
          </div>
          <div class="modal-footer">
            <div class="text-center">

              <button type="submit" class="send-btn" :disabled="is_updated_or_created_loading">Add Location
                <font-awesome-icon icon="fa-solid fa-spinner" spin v-if="is_updated_or_created_loading"/>

              </button>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

</template>


<script setup>

import {reactive, watch,  onMounted} from "vue";
import {useLocationsStore} from "@/stores/location"
import {storeToRefs} from 'pinia'
import $ from "jquery";
import {loadInput} from "@/use/loadInputStyle";
// import loadInputStyle from "@/use/loadInputStyle"

const store = useLocationsStore();
const {is_updated_or_created_loading} = storeToRefs(store)

const formData = reactive({
  title: "",
  address: "",
})


const formProcess = () => {

  store.storeLocations(formData);
}
watch(is_updated_or_created_loading, (is_updated_or_created_loading) => {

  if (is_updated_or_created_loading) { ///  if  success login
    $('#createLocationModal').modal('hide');

    formData.title = "";
    formData.address = "";

  }

})

onMounted(function (){
  loadInput();
})


</script>
