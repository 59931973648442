<template>
    <ul class="ratestar" >
        <li v-for="n in 5 " :key="n" :class="{'black':n>Math.ceil(rate), 'yellow':n<=Math.ceil(rate)  }">
            <i class="fa" :class="{
                    'fa-star':n<=rate || n>rate ,
                    'fa-star-half':n>rate && n<=Math.ceil(rate)
                }"
               aria-hidden="true"></i></li>

    </ul>
</template>

<script>

export default {
    name: "Stars",
    props: {

        rate: {default: 0},

    },

}
</script>
