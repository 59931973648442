  <template>
  <div>
    <!-- loader -->
    <Loader/>
    <!-- loader -->

    <!-- login-area -->
    <section class="login-area">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="form-area">
              <div class="login-form">
                <div class="form-logo">
                  <router-link to="/"><img class="img-fluid" src="@/assets/img/logo.svg" alt="logo"></router-link>
                  <h3>Phone Number for OTP</h3>
                </div>
                <form @submit.prevent="loginNumberSubmit" class="wow fadeInRight" data-wow-duration="1s" data-wow-delay="1s">
                  <div class="form-floating">
                    <vue-mask class="form-control" mask="(000)000-0000" :options="options" id="Phone"
                      placeholder="Phone" v-model="loginParams.phone"
                      @click="removeClass('#Phone', 'red-border')"></vue-mask>
                    <label for="Phone" class=" label-active">Phone no</label>
                  </div>
                  <button type="submit" class="login-btns" :disabled="loading">Send OTP
                    <font-awesome-icon icon="fa-solid fa-spinner" spin v-if="loading"/>
                  </button>
                </form>
                <p class="dont-acc"> <a href="javascript:;" @click="goToLogin">Go Back</a></p>
                <div class="other-login">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- login-area -->
  </div>
</template>
<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import {useRouter} from 'vue-router';
import Loader from "@/components/Loader";
import vueMask from 'vue-jquery-mask';
import axios from "axios";
// console.log(localStorage);
export default {
  components: { Loader, vueMask },
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    const router = useRouter();
    let loginParams = ref({
      phone: ''
    });
    const loginNumberSubmit = async () => {
      const postFormData = new FormData();
      postFormData.append('phone', loginParams.value.phone);
      // console.log(sessionStorage.getItem("auth_token"));
      await axios
        .post(axios.defaults.baseUrl + "login/phoneNumberSubmit",
          postFormData,
          {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("auth_token"),
            },
          }
        )
        .then((response) => {
          if(response.data.data.login_status == 'otp'){
            // console.log('abcd');
            sessionStorage.setItem('otp_number', loginParams.value.phone);
            router.push('/login/two-factor-authentication-check');
          }else{
            router.push('/login');
          }
          window.showHideMainLoader(false);
        })
        .catch((error) => {
          window.showHideMainLoader(false);
          showToast(error.response.data.status, error.response.data.message);
          if(error.response.data.data.login_status == 'false'){
            router.push('/login');
          }
          // return false;
        });
    }
    
    const goToLogin = async () => {
      sessionStorage.removeItem("auth_token");
      sessionStorage.clear();
      localStorage.clear();
      // showToast('error','Please Login');
      router.push('/login');
    }
    const addClass = (elementName, className) => {
      $(elementName).addClass(className);
    }
    const removeClass = (elementName, className) => {
      $(elementName).removeClass(className);
    }
    const showToast = (toastIcon, toastMessage) => {
      window.toast.fire({
        icon: toastIcon,
        title: toastMessage,
      });
    }
    if (!sessionStorage.getItem("auth_token")) {
      showToast('error','Please Login');
      router.push('/login');
      // console.log('asd');
    }
    onMounted(() => {
      setTimeout(() => {
        window.showHideMainLoader(false);
      }, 500);
    });

    return {
      base_url,
      loginNumberSubmit,
      addClass,
      removeClass,
      showToast,

      loginParams,
      goToLogin
    };
  },
};
</script>