<template>
  <div>
    <!-- loader -->
    <Loader />
    <!-- loader -->
    <!-- header -->
    <header class="simple-header">
      <div class="containerss">
        <div class="row">
          <div class="col-sm-6">
            <div class="logos">
              <router-link to="/dashboard">
                <img class="img-fluid small-logo" src="@/assets/img/logo-s.svg" alt="logo">
                <img class="img-fluid big-logo" src="@/assets/img/logo.svg" alt="logo">
              </router-link>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="top-close">
              <ul>
                <li>
                  <router-link to="/login" class="accouts">Already have an account?</router-link>
                </li>
                <li>
                  <router-link to="/login" class="log">Login</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- header -->
    <!-- main-content -->
    <main class="main-content">
      <!-- simple-area -->
      <section class="simple-area">
        <div class="containerss">
          <div class="row">

            <div class="col-md-10 col-lg-10 offset-md-1 offset-lg-1">
              <div class="login">
                <h1>Register</h1>
                <h2>Create an account</h2>
                <div class="row justify-content-md-center">
                  <div class="col-lg-6">
                    <form>
                      <div class="row g-3">
                        <div class="col-md-6">
                          <div class="form-floating">
                            <input type="text" class="form-control" id="Firstname" placeholder="Firstname"
                              v-model="register_form.register_first_name"
                              @click="removeClass('#Firstname', 'red-border')">
                            <label for="Firstname">First name</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-floating">
                            <input type="text" class="form-control" id="lastname" placeholder="lastname"
                              v-model="register_form.register_last_name" @click="removeClass('#lastname', 'red-border')">
                            <label for="lastname">Last name</label>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-floating">
                            <input type="text" class="form-control" id="businessname" placeholder="businessname"
                              v-model="register_form.register_business"
                              @click="removeClass('#businessname', 'red-border')">
                            <label for="email">Business Name</label>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-floating">
                            <input type="email" class="form-control" id="email" placeholder="email"
                              v-model="register_form.register_email" @click="removeClass('#email', 'red-border')">
                            <label for="email">Email address</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-floating">
                            <input type="password" class="form-control" id="password1" placeholder="password"
                              v-model="register_form.register_password" @click="removeClass('#password1', 'red-border')">
                            <label for="password1">Password</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-floating">
                            <input type="password" class="form-control" id="password2" placeholder="Confirm password"
                              @click="removeClass('#password2', 'red-border')">
                            <label for="password2">Confirm password</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-floating">
                            <!-- <input type="number" class="form-control" id="Phone" placeholder="Phone"
                              v-model="register_form.register_phone" @click="removeClass('#Phone', 'red-border')"> -->
                            <vue-mask class="form-control" mask="(000)000-0000" :options="options" id="Phone"
                              placeholder="Phone" v-model="register_form.register_phone"
                              @click="removeClass('#Phone', 'red-border')"></vue-mask>
                            <label for="Phone">Phone no</label>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-floating">
                            <input type="text" class="form-control" id="Zip" placeholder="Zip code"
                              v-model="register_form.register_zip">
                            <label for="Zip">Zip code</label>
                          </div>
                        </div>
                        <div class="col-12 text-center">
                          <button type="button" id="register-btn" class="cus-btn" @click="register">Register</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- simple-area -->
    </main>
    <!-- main-content -->
    <!-- footer -->
    <footer class="footer-area">
      <!-- <div class="footer">
              <img class="img-fluid lazy" data-src="assets/img/footer-bg.png" alt="footer bg">
          </div> -->
      <div class="col-12">
        <a href="#" class="scrolltotop"><i class="fa fa-angle-up"></i></a>
      </div>
    </footer>
    <!-- footer -->
  </div>
</template>

<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import axios from "axios";
import { useRouter } from 'vue-router';
import Loader from "@/components/Loader";
import vueMask from 'vue-jquery-mask';
export default {
  components: { Loader, vueMask },
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    const router = useRouter();
    // console.log(Params);
    let register_form = ref({
      register_first_name: '',
      register_last_name: '',
      register_business: '',
      register_email: '',
      register_phone: '',
      register_zip: '',
      register_password: ''
    });
    const register = async () => {
      removeClass('.form-control', 'red-border');
      let validate = 1;
      console.log(validate);
      // console.log(register_form.value);
      if (register_form.value.register_first_name == '') {
        validate = 0;
        addClass('#Firstname', 'red-border');
        showToast('error', 'First name is required');
        return false;
      }
      if (register_form.value.register_last_name == '') {
        validate = 0;
        addClass('#lastname', 'red-border');
        showToast('error', 'Last name is required');
        return false;
      }
      if (register_form.value.register_business == '') {
        validate = 0;
        addClass('#businessname', 'red-border');
        showToast('error', 'Business name is required');
        return false;
      }
      if (register_form.value.register_email == '') {
        validate = 0;
        addClass('#email', 'red-border');
        showToast('error', 'Email is required');
        return false;
      }
      if (register_form.value.register_password == '') {
        validate = 0;
        addClass('#password1', 'red-border');
        showToast('error', 'Password is required');
        return false;
      } else {
        let password = register_form.value.register_password;
        if (!isPasswordValid(password)) {
          validate = 0;
          addClass('#password1', 'red-border');
          showToast('error', 'Password must be at least 8 characters, must contain at least 1 uppercase, 1 lowercase and 1 number');
          return false;
        } else {
          if ($('#password2').val() === password) { console.log('Passwords match'); } else {
            validate = 0;
            addClass('#password2', 'red-border');
            showToast('error', 'Passwords do not match');
            return false;
          }
        }
      }
      if (register_form.value.register_phone == '') {
        validate = 0;
        addClass('#Phone', 'red-border');
        showToast('error', 'Phone number is required1');
        return false;
      } else if (register_form.value.register_phone.length < 10) {
        validate = 0;
        addClass('#Phone', 'red-border');
        showToast('error', 'Phone number is invalid2');
        return false;
      } else if (!isPhoneValid(register_form.value.register_phone)) {
        validate = 0;
        addClass('#Phone', 'red-border');
        showToast('error', 'Phone number is invalid3');
        return false;
      }
      if (validate == 1) {
        window.showHideMainLoader(true);
        let emailExistsFormData = new FormData();
        emailExistsFormData.append('email', register_form.value.register_email);
        await axios
          .post(
            axios.defaults.baseUrl + "register/email_check",
            emailExistsFormData,
            {
              headers: {},
            }
          )
          .then((response) => {
            // console.log(response.data.status);
            window.toast.fire({
              icon: response.data.status,
              title: response.data.message,
            });
            let registerFormData = new FormData();
            registerFormData.append('first_name', register_form.value.register_first_name);
            registerFormData.append('last_name', register_form.value.register_last_name);
            registerFormData.append('business_name', register_form.value.register_business);
            registerFormData.append('email', register_form.value.register_email);
            registerFormData.append('phone', register_form.value.register_phone);
            registerFormData.append('zip', register_form.value.register_zip);
            registerFormData.append('password', register_form.value.register_password);
            // console.log(teamuser_image);
            // window.showHideMainLoader(true);
            axios
              .post(
                axios.defaults.baseUrl + "register",
                registerFormData,
                {
                  headers: {},
                }
              )
              .then((response) => {
                window.toast.fire({
                  icon: response.data.status,
                  title: response.data.message,
                });
                closeModal();
                window.showHideMainLoader(false);
                // console.log(response.data.data);
                register_form.value = '';
                localStorage.setItem("auth_token", response.data.data.access_token);
                localStorage.setItem("user_id", response.data.data.id);
                localStorage.setItem("first_name", response.data.data.first_name);
                localStorage.setItem("last_name", response.data.data.last_name);
                localStorage.setItem("business_name", response.data.data.business_name);
                localStorage.setItem("business_email", response.data.data.email);
                localStorage.setItem("business_phone", response.data.data.dealer_phone_number);
                localStorage.setItem("user_type", response.data.data.user_type);
                localStorage.setItem("dealer_id", response.data.data.dealer_id);
                // localStorage.setItem("location_id", response.data.data.location_id);
                // console.log(localStorage);
                window.location.href = '/register/wizard';
              })
              .catch((error) => {
                window.showHideMainLoader(false);
                showToast(error.response.data.status, error.response.data.message);
                return false;
              });
          })
          .catch((error) => {
            window.showHideMainLoader(false);
            addClass('#email', 'red-border');
            showToast(error.response.data.status, error.response.data.message);
            return false;
          });
      }
    }
    const isPasswordValid = (email) => {
      let regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
      return regex.test(email);
    }
    const isPhoneValid = (phone) => {
      let regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      return regex.test(phone);
    }
    const addClass = (elementName, className) => {
      $(elementName).addClass(className);
    }

    const removeClass = (elementName, className) => {
      $(elementName).removeClass(className);
    }
    const showToast = (toastIcon, toastMessage) => {
      window.toast.fire({
        icon: toastIcon,
        title: toastMessage,
      });
    }
    // Open Modal
    const openModal = () => {
    };

    // Close Modal
    const closeModal = () => {
      $('#exampleModal2').modal('hide');
    };

    onMounted(() => {
      window.showHideMainLoader(false);
      if (localStorage.getItem("auth_token")) {
        router.push("/dashboard");
      }
    });

    return {
      openModal,
      closeModal,
      base_url,
      register_form,
      register,
      isPasswordValid,
      isPhoneValid,
      removeClass,
      addClass,
      showToast,
    };
  },
};
</script>
