<template>
<!-- search-top -->
<div class="search-top">
    <div class="row">
        <div class="col-md-7 col-lg-7">
            <div class="search-box appinment-top" style="display: none">
                <form>
                    <div class="form-group">
                        <input type="search" class="form-control" placeholder="Search" aria-label="search" />
                        <img class="img-fluid sea-icon" src="@/assets/img/search.svg" alt="image title" />
                    </div>
                </form>

                <div class="todayselect">
                    <select class="form-control" aria-label="agents">
                        <option>Select agents</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                    </select>
                </div>
                <div class="todayselect">
                    <select class="form-control" aria-label="Team">
                        <option>Select Team</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                    </select>
                </div>
                <div class="todayselect">
                    <select class="form-control" aria-label="Status">
                        <option>Select Status</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-5 col-lg-5">
            <div class="export-right">
                <div class="export">
                    <a href="javascript:;" @click="createAppointment" data-toggle="modal" data-target="#exampleModal4">
                        Create appointment</a>
                </div>
                <div class="todayselect" style="display: none">
                    <div class="reportrange">
                        This Month <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- search-top -->

<!-- review-table -->
<div class="review-table">
    <table class="display" id="dataTable">
        <thead>
            <tr>
                <th>ID</th>
                <th>Appt Title</th>
                <th>Appt Date & Time</th>
                <th>Customer Name</th>
                <th>Appointment Type:</th>
                <th>Creation By</th>
                <th>Creation Date</th>
                <th>Status</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="Appointment in AllAppointments" :key="Appointment">
                <td>{{ Appointment.id }}</td>
                <td>{{ Appointment.title }}</td>
                <td>{{ Appointment.datetime }}</td>
                <td>
                    <a class="manname" href="javascript:;">
                        {{ Appointment.customer_name }}
                    </a>
                </td>
                <td>
                    <span class="appointment-type" v-if="Appointment.type == 0"><img class="img-fluid"  src="@/assets/img/walk.svg" alt="In-Person" /> In-Person</span>
                    <span class="appointment-type" v-else><img class="img-fluid" src="@/assets/img/Virtual.png" alt="Virtual" /> Virtual</span>
                </td>
                <td>{{ Appointment.created_by }}</td>
                <td>{{ Appointment.created }}</td>
                <td>
                    <a v-if="Appointment.status == 0" class="Shown bg-warning" href="javascript:;">Pending</a>
                    <a v-if="Appointment.status == 1" class="Shown" href="javascript:;">Completed</a>
                    <a v-if="Appointment.status == 2" class="Shown bg-error" href="javascript:;">No Show</a>
                </td>
                <td>
                    <div class="navbar btn-group">
                        <button type="button" class="dropbtn dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                            Action
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <a v-if="Appointment.status != 0" class="dropdown-item" href="javascript:;" @click="changeAppointment(Appointment.id, 0)"> Pending</a>
                            <a v-if="Appointment.status != 1" class="dropdown-item" href="javascript:;" @click="changeAppointment(Appointment.id, 1)"> Completed</a>
                            <a v-if="Appointment.status != 2" class="dropdown-item" href="javascript:;" @click="changeAppointment(Appointment.id, 2)"> No Show</a>
                            <a class="dropdown-item" href="javascript:;" @click="deleteAppointment(Appointment.id)"> Delete Appointment</a>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<!-- review-table -->
<!-- invite-Modal -->
<div class="modal fade" id="create-appointment" tabindex="-1" aria-labelledby="exampleModalLabel4" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel4">
                    Create an Appointment
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="img-fluid" src="@/assets/img/close.png" alt="image title" />
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-8">
                        <!-- create-meetings -->
                        <div class="create-meetings">
                            <div class="add-title">
                                <input v-model="newAppointment.title" type="text" class="form-control" placeholder="Title of appointment" />
                            </div>
                            <div class="add-title" style="position: relative;">
                                <input v-model="newAppointment.customer_name" type="text" class="form-control" placeholder="Search Customers..." id="appointment-customers-input" @click="openCustomerListBox" @keyup="searchCustomerListBox()" />
                                <!-- <select class="input select2" v-model="newAppointment.customer_id" required>
                                    <option v-for="Customer in AllCustomers" v-bind:key="Customer.id" v-bind:value="Customer.id">
                                        {{ Customer.first_name }} {{ Customer.last_name }}
                                    </option>
                                </select> -->
                                <input type="hidden" v-model="newAppointment.customer_id" />
                                <div class="appointment-customers-list-box">
                                    <ul class="appointment-customers-list">
                                        <li class="appointment-customers-list-item" v-for="Customer in AllCustomers" v-bind:key="Customer.id" v-bind:value="Customer.id">
                                            <a class="appointment-customers-item" href="javascript:;" :data-id="Customer.id" :data-name="Customer.first_name+' '+Customer.last_name" @click="customerSelectId(Customer.first_name+' '+Customer.last_name, Customer.id)">{{ Customer.first_name+' '+Customer.last_name }}</a>
                                        </li>
                                    </ul>
                                    <span class="appointment-customers-searching" style="display: none;">Searching...</span>
                                    <span class="appointment-customers-notfound" style="display: none;">No Customer found.</span>
                                </div>
                            </div>

                            <div class="one-group">
                                <p>Type of conference</p>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <label class="containers active"><span class="label">In Person</span>
                                                <img class="img-fluid" src="@/assets/img/one.svg" alt="image title" />
                                                <input type="radio" name="radio" v-model="newAppointment.type" checked value="0" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <label class="containers"><span class="label">Virtual</span>
                                                <img class="img-fluid" src="@/assets/img/Virtual.svg" alt="image title" />
                                                <input type="radio" name="radio" v-model="newAppointment.type" value="1" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="calanders">
                                <p>Select a date</p>
                                <div class="calendar calendar-first" id="calendar_first">
                                    <div class="calendar_header">
                                        <h2></h2>
                                        <button class="switch-month switch-right">
                                            <img class="img-fluid" src="@/assets/img/arrowright.svg" alt="image title" />
                                        </button>
                                        <button class="switch-month switch-left">
                                            <img class="img-fluid" src="@/assets/img/arrowleft.svg" alt="image title" />
                                        </button>
                                    </div>
                                    <div class="add-title">
                                        <span style="position: absolute; z-index: 100; right: 8px; font-size: 20px; top: 8px; opacity: 0.8;"><i class="fa fa-calendar"></i></span>
                                        <input v-model="newAppointment.appointment_date" type="date" class="form-control datepicker" placeholder="Date of Appointment" min="1997-01-01" max="2030-12-31" />
                                    </div>
                                    <!--<div class="calendar_weekdays"></div>
                    <div class="calendar_content"></div>-->
                                </div>

                                <!-- time-box -->
                                <div class="time-box mobile-view">
                                    <div class="times">
                                        <h4>Select time</h4>
                                        <h6>Time you’re available</h6>
                                        <h6>Current Time {{ getCurrentTime('time') }}</h6>
                                    </div>

                                    <div class="custom-control custom-radio custom-control-inline">
                                        <label class="containers active"><span class="label">Now ({{ getCurrentTime('now') }})</span>
                                            <input type="radio" name="radio1" checked />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>

                                    <div class="custom-control custom-radio custom-control-inline">
                                        <label class="containers"><span class="label">Custom time</span>
                                            <input type="radio" name="radio1" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <form>
                                        <div class="form-row">
                                            <div class="col">
                                                <select class="form-control">
                                                    <option>01</option>
                                                    <option>02</option>
                                                    <option>03</option>
                                                    <option>04</option>
                                                    <option>05</option>
                                                    <option>06</option>
                                                    <option>07</option>
                                                    <option>08</option>
                                                    <option>09</option>
                                                    <option>10</option>
                                                    <option>11</option>
                                                    <option>12</option>
                                                </select>
                                            </div>
                                            <div class="col">
                                                <select class="form-control">
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                            <div class="col">
                                                <select class="form-control">
                                                    <option>AM</option>
                                                    <option>PM</option>
                                                </select>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <!-- time-box -->
                            </div>
                            <!--
                <div class="duration">
                  <p>Duration schedule</p>
                  <ul>
                    <li>
                      <a class="act" href="#"
                        >15 <span class="mins">mins</span></a
                      >
                    </li>
                    <li>
                      <a href="#">30 <span class="mins">mins</span></a>
                    </li>
                    <li>
                      <a href="#">45 <span class="mins">mins</span></a>
                    </li>
                    <li>
                      <a href="#"
                        ><input
                          type="text"
                          class="form-control"
                          placeholder="--"
                        /><span class="mins">custom mins</span></a
                      >
                    </li>
                  </ul>
                </div>

                <div class="person">
                  <p>Participants</p>
                  <a href="#"><span class="show">Add</span></a>
                </div>
-->
                        </div>

                        <!-- create-btms -->

                        <div class="create-btn">
                            <a href="#" data-dismiss="modal" aria-label="Close">Close</a>
                            <a class="create" href="javascript:;" @click="addAppointmentRequest">create</a>
                        </div>
                        <!-- create-btms -->
                    </div>

                    <div class="col-lg-4 desktop-view">
                        <!-- time-box -->
                        <div class="time-box">
                            <div class="times">
                                <h4>Select time</h4>
                                <h6>Time you’re available</h6>
                                <h6>Current Time {{ getCurrentTime('time') }}</h6>
                            </div>

                            <div class="custom-control custom-radio custom-control-inline">
                                <label class="containers active"><span class="label">Now ({{ getCurrentTime('now') }})</span>
                                    <input type="radio" name="radio1" checked v-model="newAppointment.timeType" value="now" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>

                            <div class="custom-control custom-radio custom-control-inline">
                                <label class="containers"><span class="label">Custom time</span>
                                    <input type="radio" name="radio1" v-model="newAppointment.timeType" value="custom" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <form>
                                <div class="form-row">
                                    <div class="col">
                                        <span style="position: absolute; z-index: 100; right: 8px; font-size: 20px; top: 3px; opacity: 0.8;"><i class="fa fa-angle-down"></i></span>
                                        <select class="form-control" v-model="newAppointment.customTimeHours">
                                            <option value="" selected>Hrs</option>
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                            <option value="04">04</option>
                                            <option value="05">05</option>
                                            <option value="06">06</option>
                                            <option value="07">07</option>
                                            <option value="08">08</option>
                                            <option value="09">09</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <span style="position: absolute; z-index: 100; right: 8px; font-size: 20px; top: 3px; opacity: 0.8;"><i class="fa fa-angle-down"></i></span>
                                        <select class="form-control" v-model="newAppointment.customTimeMinutes">
                                            <!-- <option v-for="i in 59" :key="i">{{ i+15 }}</option> -->
                                            <option value="" selected>Min</option>
                                            <option value="00">00</option>
                                            <option value="15">15</option>
                                            <option value="30">30</option>
                                            <option value="45">45</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <span style="position: absolute; z-index: 100; right: 8px; font-size: 20px; top: 3px; opacity: 0.8;"><i class="fa fa-angle-down"></i></span>
                                        <select class="form-control" v-model="newAppointment.customTimeAmPm">
                                            <option value="AM" >AM</option>
                                            <option value="PM">PM</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                            <!--
                <ul>
                  <li>
                    <a class="act" href="#"><span></span> 08:00 am</a>
                  </li>
                  <li>
                    <a href="#"><span></span> 08:00 am</a>
                  </li>
                  <li>
                    <a href="#"><span></span> 08:00 am</a>
                  </li>
                  <li>
                    <a href="#"><span></span> 08:00 am</a>
                  </li>
                </ul>
-->
                        </div>
                        <!-- time-box -->
                    </div>
                </div>

                <!-- create-meetings -->
            </div>
        </div>
    </div>
</div>
</template>

<style>
.appointment-type {
    /*display: flex;
    align-items: center;*/
}
.appointment-type img {
    vertical-align: middle;
    margin-right: 10px;
}
.appointment-customers-list-box {
    display: none;
    position: absolute;
    top: 46px;
    left: 0;
    width: 60%;
    max-height: 200px;
    background: #fff;
    z-index: 9;
    border: 1px solid #999;
    border-radius: 8px;
    overflow-y: auto;
}

.appointment-customers-list {
    list-style-type: none;
}

.appointment-customers-list-item {
    display: inline-block;
    width: 100%;
}

.appointment-customers-item {
    text-decoration: none;
    padding: 8px 15px;
    display: block;
    color: #666;
    font-weight: 400;
    border-bottom: 1px solid #ccc;
    background: #fff;
    font-size: 13px;
    text-transform: capitalize;
}

.appointment-customers-item:hover {
    text-decoration: none;
    color: #fff;
    background: #baafff;
}

.appointment-customers-searching,
.appointment-customers-notfound {
    display: block;
    text-align: center;
    padding: 15px;
    font-size: 13px;
    font-weight: 400;
}

.calendar_header {
    display: none !important;
}

.datepicker {
    border: none;
    box-sizing: border-box;
    outline: 0;
    padding: .75rem;
    position: relative;
    width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
</style>

<script>
import $ from "jquery";
import {
    ref,
    onMounted
} from "vue";
import axios from "axios";

export default {
    components: {},
    setup() {
        let base_url = "http://api.streamly.com/uploads/";
        let editMode = ref(false);
        let AllCustomers = ref([]);
        let AllAppointments = ref([]);
        let newAppointment = ref({
            Id: 0,
            title: '',
            type: '',
            customer_id: '',
            customer_name: '',
            appointment_date: '',
            appointment_time: '',
            notes: '',
            timeType: '',
            customTimeHours: '',
            customTimeMinutes: '',
            customTimeAmPm: '',
        });
        const createAppointment = () => {
            editMode.value = false;
            newAppointment.value.Id = null;
            newAppointment.value.title = null;
            newAppointment.value.type = null;
            newAppointment.value.customer_id = null;
            newAppointment.value.appointment_date = null;
            newAppointment.value.timeType = null;
            newAppointment.value.customTimeHours = "";
            newAppointment.value.customTimeMinutes = "";
            newAppointment.value.customTimeAmPm = "";
            newAppointment.value.notes = null;
            $("#create-appointment").modal("show");
        };
        const getCurrentTime = (timetype = null) => {
            var todayDate = new Date();
            if (timetype == 'now') {
                var hours = todayDate.getHours();
                var minutes = todayDate.getMinutes();
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0' + minutes : minutes;
                var ampm = todayDate.getHours() >= 12 ? 'pm' : 'am';
                return hours + ':' + minutes + ' ' + ampm;
            } else {
                return todayDate.getHours() + ":" + todayDate.getMinutes() + ":" + todayDate.getSeconds();
            }
        };
        // Open Modal
        const openModal = () => {
            $("#featureNotAvailable").modal("show");
        };

        // Close Modal
        const closeModal = () => {
            $("#featureNotAvailable").modal("hide");
            $("#create-appointment").modal("hide");
        };
        const showToast = (toastIcon, toastMessage) => {
            window.toast.fire({
                icon: toastIcon,
                title: toastMessage,
            });
        }
        const datatableInit = async () => {
            if ($.fn.DataTable.isDataTable("#dataTable")) {
                $("#dataTable").DataTable().clear().destroy();
            }
            $("#dataTable")
                .addClass("nowrap")
                .dataTable({
                    order: [],
                    columnDefs: [{
                        targets: [1],
                        orderable: false
                    }],
                });
        };
        const getAppointmentList = async () => {
            if ($.fn.DataTable.isDataTable("#dataTable")) {
                $("#dataTable").DataTable().clear().destroy();
            }
            $('#dataTable').DataTable({
                "processing": true,
                "serverSide": true,
                language: {
                    emptyTable: "No matching records found",
                    lengthMenu: "Show _MENU_ records per page",
                    zeroRecords: "",
                    info: "Showing _START_ to _END_ of _TOTAL_ records",
                    infoEmpty: "No records available",
                    infoFiltered: "(filtered from _MAX_ total records)",
                },
                "ajax": {
                    "url": axios.defaults.baseUrl + "appointments/dt",
                    "type": "POST", // Assuming your API endpoint expects a POST request
                    "headers": {
                        Authorization: "Bearer " + localStorage.getItem('auth_token'),
                    },
                    "data": function (d) {
                        // You can add additional parameters or modify the request data here
                        return $.extend({}, d, {
                            'dealer_id': localStorage.getItem('dealer_id'),
                            'location_id': localStorage.getItem('location_id'),
                        });
                    },
                    dataSrc: function (response) {
                        // Transform the data here before DataTables processes it
                        AllAppointments.value = response.data;
                        return [];
                    },
                },
                "searching": true,
                "order": [],
                "columns": [],
            });
            $(".display").addClass("nowrap").dataTable();
        }
        /*const getAppointmentList = async () => {
            window.showHideMainLoader(true);
            const getFormData = new FormData();
            getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            getFormData.append('location_id', localStorage.getItem('location_id'));
            await axios
                .post(
                    axios.defaults.baseUrl + "appointments",
                    getFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    window.showHideMainLoader(false);
                    AllAppointments.value = response.data.data.allAppointments;
                    // datatableInit();
                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }*/
        const addAppointmentRequest = async () => {
            window.showHideMainLoader(true);
            let addFormData = new FormData()
            addFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            addFormData.append('location_id', localStorage.getItem('location_id'));
            addFormData.append('title', newAppointment.value.title);
            addFormData.append('type', newAppointment.value.type);
            addFormData.append('customer_id', newAppointment.value.customer_id);
            addFormData.append('appointment_date', newAppointment.value.appointment_date);
            addFormData.append('timeType', newAppointment.value.timeType);
            addFormData.append('customTimeHours', newAppointment.value.customTimeHours);
            addFormData.append('customTimeMinutes', newAppointment.value.customTimeMinutes);
            addFormData.append('customTimeAmPm', newAppointment.value.customTimeAmPm);
            addFormData.append('notes', newAppointment.value.notes);
            // console.log(teamuser_image);
            await axios
                .post(
                    axios.defaults.baseUrl + "appointments/add",
                    addFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
                .then((response) => {
                    window.toast.fire({
                        icon: response.data.status,
                        title: response.data.message,
                    });
                    getAppointmentList();
                    closeModal();
                    newAppointment.value.Id = 0;
                    newAppointment.value.title = '';
                    newAppointment.value.type = '';
                    newAppointment.value.customer_id = '';
                    newAppointment.value.customer_name = '';
                    newAppointment.value.appointment_date = '';
                    newAppointment.value.appointment_time = '';
                    newAppointment.value.notes = '';
                    newAppointment.value.timeType = '';
                    newAppointment.value.customTimeHours = '';
                    newAppointment.value.customTimeMinutes = '';
                    newAppointment.value.customTimeAmPm = '';
                    window.showHideMainLoader(false);
                    // location.reload(); //-----remove later
                });
        }
        const deleteAppointment = async (Id = null) => {
            window.showHideMainLoader(true);
            await axios
                .delete(axios.defaults.baseUrl + "appointments/delete/" + Id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem('auth_token'),
                    },
                })
                .then((response) => {
                    window.showHideMainLoader(false);
                    window.toast.fire({
                        icon: response.data.status,
                        title: response.data.message,
                    });
                    getAppointmentList();

                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        const changeAppointment = async (Id = null, status = null) => {
            window.showHideMainLoader(true);
            await axios
                .get(axios.defaults.baseUrl + "appointments/update/" + Id + "/" + status, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem('auth_token'),
                    },
                })
                .then((response) => {
                    window.showHideMainLoader(false);
                    window.toast.fire({
                        icon: response.data.status,
                        title: response.data.message,
                    });
                    getAppointmentList();

                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        const getCustomers = async () => {
            const getFormData = new FormData();
            // getFormData.append('list_id', list_id);
            getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            getFormData.append('location_id', localStorage.getItem('location_id'));
            await axios
                .post(axios.defaults.baseUrl + "customers/appointment",
                    getFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    AllCustomers.value = response.data.data.allCustomers;
                })
        }
        const customerSelectId = async (selected_customer_name = null, selected_customer_id = null) => {
            newAppointment.value.customer_name = selected_customer_name;
            newAppointment.value.customer_id = selected_customer_id;
            $('.appointment-customers-list-box').hide();
        }
        const openCustomerListBox = async () => {
            if (!$('.appointment-customers-list-box').is(':visible')) {
                $('.appointment-customers-list-box').show();
            } else {
                $('.appointment-customers-list-box').hide();
            }
        }
        const searchCustomerListBox = async () => {
            let search_param = newAppointment.value.customer_name;
            if (search_param.length >= 2) {
                $('.appointment-customers-notfound').hide();
                $('.appointment-customers-list').hide();
                $('.appointment-customers-searching').show();
                const getFormData = new FormData();
                getFormData.append('search_param', search_param);
                getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
                getFormData.append('location_id', localStorage.getItem('location_id'));
                await axios
                    .post(axios.defaults.baseUrl + "customers/appointment",
                        getFormData, {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem('auth_token'),
                            },
                        }
                    )
                    .then((response) => {
                        AllCustomers.value = response.data.data.allCustomers;
                        // console.log(response.data.data.allCustomers);
                        $('.appointment-customers-searching').hide();
                        if (!response.data.data.no_customers) {
                            $('.appointment-customers-list').show();
                        } else {
                            $('.appointment-customers-notfound').show();
                        }
                    })
            }
        }
        onMounted(() => {
            datatableInit();
            window.showHideMainLoader(false);
            getAppointmentList();
            getCustomers();
        });

        return {
            openModal,
            closeModal,
            base_url,
            showToast,
            createAppointment,
            AllAppointments,
            newAppointment,
            getCurrentTime,
            addAppointmentRequest,
            getCustomers,
            AllCustomers,
            openCustomerListBox,
            searchCustomerListBox,
            customerSelectId,
            deleteAppointment,
            changeAppointment
        };
    },
};
</script>
