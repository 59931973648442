<template>
<!-- <a href="javascript:;" @click="testSMS">SMS Test</a> -->
<!-- search-top -->
<div class="search-top">
    <div class="row">
        <div class="col-md-6 col-lg-4">
            <div class="search-box">
                <form>
                    <div class="form-group">
                        <input type="search" class="form-control" placeholder="Search" aria-label="search">
                        <img class="img-fluid sea-icon" src="@/assets/img/search.svg" alt="image title">
                    </div>
                </form>
            </div>
        </div>
        <div class="col-md-6 col-lg-8">
            <div class="create-meeting">
                <router-link :to="{ name: 'AddEmailCampaign' }"><img class="img-fluid" src="@/assets/img/pl.svg" alt="image title"> Add New
                    Campaign</router-link>
            </div>
        </div>
    </div>
</div>
<!-- search-top -->

<!-- review-table -->
<div class="review-table">
    <table class="display" id="dataTable">
        <thead>
            <tr>
                <th>#</th>
                <th>Title</th>
                <th>Sending To</th>
                <th>Total Recepients</th>
                <th>Sent at</th>
                <th>Status</th>
                <th>Created</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(Campaign, key) in AllEmailCampaigns" :key="Campaign">
                <td>{{ key+ 1 }}</td>
                <td>
                    <router-link :to="{ name: 'EmailCampaignDetail', params: { id: Campaign.id } }">{{
              Campaign.title
            }}</router-link>
                </td>
                <td v-if="Campaign.campaign_recipient_type == 'list'">
                    <a class="capitalized" href="javascript:" @click="viewCampaignListDetailModal(Campaign.id)">{{ Campaign.campaign_recipient_type }}</a>
                </td>
                <td v-if="Campaign.campaign_recipient_type == 'individual'">
                    <router-link class="capitalized" :to="{ name: 'TextCampaignDetail', params: { id: Campaign.id } }">{{ Campaign.campaign_recipient_type }}</router-link>
                </td>
                <td>{{ Campaign.total_recepients }}</td>
                <td>{{ Campaign.sent_at }}</td>
                <td><a class="invites capitalized" href="javascript:;"> {{ Campaign.campaign_status }}</a></td>
                <td>{{ Campaign.created_on }}</td>
                <td>
                    <ul class="actionbtn">
                        <li><a href="javascript:;" @click="viewMessageModal(Campaign.campaign_template)"> View Message</a></li>
                        <li><a href="javascript:;" @click="viewStatisticsModal(Campaign.stats, Campaign.title)"> View Stats</a></li>
                    </ul>
                </td>
            </tr>

        </tbody>
    </table>
</div>
<!-- review-table -->
<!-- view-Modal -->
<div class="modal fade" id="viewmodal" tabindex="-1" aria-labelledby="viewmodal1" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="viewmodal1"> Campaign</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="img-fluid" src="@/assets/img/close.png" alt="image title">
                </button>
            </div>
            <div class="modal-body">
                <div class="view-campain">
                    <h3>Message</h3>
                    <div class="view-templates" v-html="CampaignMessage" style="min-height: 350px; margin: 15px; padding: 25px; line-height: 35px;">

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- view-Modal -->
<!-- view-Stats-Modal -->
<div class="modal fade" id="viewStatsModal" tabindex="-1" aria-labelledby="viewmodal1" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="viewmodal1"> Campaign Analytics</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="img-fluid" src="@/assets/img/close.png" alt="image title">
                </button>
            </div>
            <div class="modal-body">
                <div class="view-campain">
                    <h3>{{ CampaignStats.campaign_name }}</h3>
                    <ul class="campaign-analytics-list">
                        <li class="campaign-analytics-list-item">
                            <span class="campaign-analytics-list-name">Campaign Date</span>
                            <span class="campaign-analytics-list-stats">{{ CampaignStats.campaign_date }}</span>
                        </li>
                        <li class="campaign-analytics-list-item">
                            <span class="campaign-analytics-list-name">Audience</span>
                            <span class="campaign-analytics-list-stats">{{ CampaignStats.audience }}</span>
                        </li>
                        <li class="campaign-analytics-list-item">
                            <span class="campaign-analytics-list-name">Delivered</span>
                            <span class="campaign-analytics-list-stats">{{ CampaignStats.delivered }}%</span>
                        </li>
                        <li class="campaign-analytics-list-item">
                            <span class="campaign-analytics-list-name">Opened</span>
                            <span class="campaign-analytics-list-stats">{{ CampaignStats.opened }}%</span>
                        </li>
                        <li class="campaign-analytics-list-item">
                            <span class="campaign-analytics-list-name">Clicked</span>
                            <span class="campaign-analytics-list-stats">{{ CampaignStats.clicked }}%</span>
                        </li>
                        <li class="campaign-analytics-list-item">
                            <span class="campaign-analytics-list-name">Bounces</span>
                            <span class="campaign-analytics-list-stats">{{ CampaignStats.bounces }}%</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- view-Stats-Modal -->
<!-- view-List-Modal -->
<div class="modal fade" id="viewCampaignListModal" tabindex="-1" aria-labelledby="viewmodal1" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="viewmodal1"> Campaign Lists</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="img-fluid" src="@/assets/img/close.png" alt="image title">
                </button>
            </div>
            <div class="modal-body">
                <div class="view-campain">
                    <table class="table table-striped">
                        <tr v-for="(List, key) in AllEmailCampaignsListDetail" :key="key">
                            <td>
                              <a :href="'/customers/'+List.customer_list.id">
                                    {{ List.customer_list.title }}
                              </a>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- view-List-Modal -->
</template>
<style>
.campaign-analytics-list-item {
    background: #ccc;
    margin: 8px 0;
    padding: 10px 10px;
    border-radius: 8px;
    display: block;
    clear: both;
    height: 43px;
}
.campaign-analytics-list-name {
    float: left;
    color: #6046fe;
    font-size: 16px;
    line-height: 23px;
    font-weight: 500;
}
.campaign-analytics-list-stats {
    float: right;
    color: #fff;
    font-size: 16px;
    line-height: 23px;
    font-weight: 400;
}
.capitalized {
    text-transform: capitalize;
}
</style>
<script>
import $ from "jquery";
import {
    ref,
    onMounted
} from "vue";
import axios from "axios";

export default {
    components: {

    },
    setup() {
        let base_url = "http://api.streamly.com/uploads/";
        let AllEmailCampaigns = ref([]);
        let AllEmailCampaignsListDetail = ref([]);
        let CampaignMessage = ref('');
        let CampaignStats = ref({
            campaign_name:'',
            campaign_date:'',
            audience:0,
            delivered:0,
            opened:0,
            clicked:0,
            bounces:0
        });

        const getCampaignList = async () => {
            const getFormData = new FormData();
            getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            getFormData.append('location_id', localStorage.getItem('location_id'));
            await axios
                .post(axios.defaults.baseUrl + "campaigns/email",
                    getFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    AllEmailCampaigns.value = response.data.data.allCampaigns;
                    // datatableInit();
                })
        }

        const viewMessageModal = async (message) => {
            // console.log(message);
            CampaignMessage.value = message;
            $('#viewmodal').modal('show');
        }
        const viewStatisticsModal = async (stats = null, campaign_name = null) => {
            // console.log(stats);
            CampaignStats.value.campaign_name = campaign_name;
            CampaignStats.value.campaign_date = stats.campaign_date;
            CampaignStats.value.audience = stats.audience;
            CampaignStats.value.delivered = stats.delivered;
            CampaignStats.value.opened = stats.opened;
            CampaignStats.value.clicked = stats.clicked;
            CampaignStats.value.bounces = stats.bounces;
            $('#viewStatsModal').modal('show');
        }
        const viewCampaignListDetailModal = async (Id = null) => {
            await axios
                .get(axios.defaults.baseUrl + "campaigns/email/list-detail/" + Id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem('auth_token'),
                    },
                })
                .then((response) => {
                    AllEmailCampaignsListDetail.value = response.data.data.allCampaignsListDetail;
                })
            $('#viewCampaignListModal').modal('show');
        }
        // Open Modal
        const openModal = () => {
            $("#featureNotAvailable").modal("show");
        };

        // Close Modal
        const closeModal = () => {
            $("#featureNotAvailable").modal("hide");
        };
        /*
            const datatableInit = async () => {
              if ($.fn.DataTable.isDataTable("#dataTable")) {
                $("#dataTable").DataTable().clear().destroy();
              }
              $("#dataTable").addClass("nowrap").dataTable({
                order: [],
                columnDefs: [
                  { targets: [1], orderable: false }
                ]
              });
            }
        */
        const getAllCampaigns = async () => {
            if ($.fn.DataTable.isDataTable("#dataTable")) {
                $("#dataTable").DataTable().clear().destroy();
            }
            $('#dataTable').DataTable({
                "processing": true,
                "serverSide": true,
                language: {
                    emptyTable: "No matching records found",
                    lengthMenu: "Show _MENU_ records per page",
                    zeroRecords: "",
                    info: "Showing _START_ to _END_ of _TOTAL_ records",
                    infoEmpty: "No records available",
                    infoFiltered: "(filtered from _MAX_ total records)",
                },
                "ajax": {
                    "url": axios.defaults.baseUrl + "campaigns/email/dt",
                    "type": "POST", // Assuming your API endpoint expects a POST request
                    "headers": {
                        Authorization: "Bearer " + localStorage.getItem('auth_token'),
                    },
                    "data": function (d) {
                        // You can add additional parameters or modify the request data here
                        return $.extend({}, d, {
                            'dealer_id': localStorage.getItem('dealer_id'),
                            'location_id': localStorage.getItem('location_id'),
                            // 'list_id' : list_id.value,
                        });
                    },
                    dataSrc: function (response) {
                        // Transform the data here before DataTables processes it
                        AllEmailCampaigns.value = response.data;
                        window.showHideMainLoader(false);
                        return [];
                    },
                },
                "searching": true,
                "order": [],
                "columns": [],
            });
            $(".display").addClass("nowrap").dataTable();
        }
        onMounted(() => {
            getAllCampaigns();
            // getCampaignList();
            // datatableInit();
            // window.showHideMainLoader(false);
        });

        return {
            openModal,
            closeModal,
            base_url,
            AllEmailCampaigns,
            getCampaignList,
            viewMessageModal,
            viewStatisticsModal,
            CampaignStats,
            CampaignMessage,
            viewCampaignListDetailModal,
            AllEmailCampaignsListDetail,
        };
    },
};
</script>
