<template>
  <div>


    <!-- search-top -->
    <div class="search-top">
      <div class="row">
        <div class="col-md-8 col-lg-8">
          <div class="search-box appinment-top">
            <form>
              <div class="form-group">
                <input type="search" class="form-control" placeholder="Search" aria-label="search">
                <img class="img-fluid sea-icon" src="@/assets/img/search.svg" alt="image title">
              </div>
            </form>

            <div class="todayselect">
              <select class="form-control" aria-label="agents">
                <option>Select agents</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
            <div class="todayselect">
              <select class="form-control" aria-label="Team">
                <option>Select Team</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>

          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="export-right">
            <div class="export">
              <a href="#">Refresh</a>
            </div>
            <div class="todayselect">
              <div class="reportrange">
                This Month <i class="fa fa-angle-down" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- search-top -->

    <!-- review-table -->
    <div class="review-table">
      <table class="display" id="dataTable">
        <thead>
        <tr>
          <th>Task ID</th>
          <th>Task Date</th>
          <th>Customer Name</th>
          <th>Sales Rep</th>
          <th>BDC Rep</th>
          <th>Task Assigned To</th>
          <th>Task Descr</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>

        <tr>
          <td><a href="#">3806</a></td>
          <td>Mar 16, 2021 11:50am</td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>DealerGear Mohshin</td>

          <td>Khairul Bashar</td>
          <td>Mar 16, 2021</td>
          <td>Testing 2</td>
          <td><a class="Shown" href="#">Completed</a></td>
        </tr>

        <tr>
          <td><a href="#">3806</a></td>
          <td>Mar 16, 2021 11:50am</td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shone Hala
            </a>
          </td>
          <td>DealerGear Mohshin</td>

          <td>Jamil Haldar</td>
          <td>Mar 16, 2021</td>
          <td>Test 1</td>
          <td><a class="Pending" href="#">Pending</a></td>
        </tr>

        <tr>
          <td><a href="#">3806</a></td>
          <td>Mar 16, 2021 11:50am</td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>DealerGear Mohshin</td>

          <td>Khairul Bashar</td>
          <td>Mar 16, 2021</td>
          <td>Testing 2</td>
          <td><a class="Shown" href="#">Completed</a></td>
        </tr>

        <tr>
          <td><a href="#">3806</a></td>
          <td>Mar 16, 2021 11:50am</td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shone Hala
            </a>
          </td>
          <td>DealerGear Mohshin</td>

          <td>Jamil Haldar</td>
          <td>Mar 16, 2021</td>
          <td>Test 1</td>
          <td><a class="Pending" href="#">Pending</a></td>
        </tr>

        <tr>
          <td><a href="#">3806</a></td>
          <td>Mar 16, 2021 11:50am</td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>DealerGear Mohshin</td>

          <td>Khairul Bashar</td>
          <td>Mar 16, 2021</td>
          <td>Testing 2</td>
          <td><a class="Shown" href="#">Completed</a></td>
        </tr>

        <tr>
          <td><a href="#">3806</a></td>
          <td>Mar 16, 2021 11:50am</td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shone Hala
            </a>
          </td>
          <td>DealerGear Mohshin</td>

          <td>Jamil Haldar</td>
          <td>Mar 16, 2021</td>
          <td>Test 1</td>
          <td><a class="Pending" href="#">Pending</a></td>
        </tr>

        <tr>
          <td><a href="#">3806</a></td>
          <td>Mar 16, 2021 11:50am</td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>DealerGear Mohshin</td>

          <td>Khairul Bashar</td>
          <td>Mar 16, 2021</td>
          <td>Testing 2</td>
          <td><a class="Shown" href="#">Completed</a></td>
        </tr>

        <tr>
          <td><a href="#">3806</a></td>
          <td>Mar 16, 2021 11:50am</td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shone Hala
            </a>
          </td>
          <td>DealerGear Mohshin</td>

          <td>Jamil Haldar</td>
          <td>Mar 16, 2021</td>
          <td>Test 1</td>
          <td><a class="Pending" href="#">Pending</a></td>
        </tr>

        <tr>
          <td><a href="#">3806</a></td>
          <td>Mar 16, 2021 11:50am</td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>DealerGear Mohshin</td>

          <td>Khairul Bashar</td>
          <td>Mar 16, 2021</td>
          <td>Testing 2</td>
          <td><a class="Shown" href="#">Completed</a></td>
        </tr>

        <tr>
          <td><a href="#">3806</a></td>
          <td>Mar 16, 2021 11:50am</td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shone Hala
            </a>
          </td>
          <td>DealerGear Mohshin</td>

          <td>Jamil Haldar</td>
          <td>Mar 16, 2021</td>
          <td>Test 1</td>
          <td><a class="Pending" href="#">Pending</a></td>
        </tr>

        <tr>
          <td><a href="#">3806</a></td>
          <td>Mar 16, 2021 11:50am</td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shane
              Hawkins </a>
          </td>
          <td>DealerGear Mohshin</td>

          <td>Khairul Bashar</td>
          <td>Mar 16, 2021</td>
          <td>Testing 2</td>
          <td><a class="Shown" href="#">Completed</a></td>
        </tr>

        <tr>
          <td><a href="#">3806</a></td>
          <td>Mar 16, 2021 11:50am</td>
          <td>
            <a class="manname" href="#"><img class="img-fluid" src="@/assets/img/man.png" alt="image title"> Shone Hala
            </a>
          </td>
          <td>DealerGear Mohshin</td>

          <td>Jamil Haldar</td>
          <td>Mar 16, 2021</td>
          <td>Test 1</td>
          <td><a class="Pending" href="#">Pending</a></td>
        </tr>


        </tbody>
      </table>
    </div>
    <!-- review-table -->
  </div>
</template>
<script>
import $ from "jquery";
import {onMounted} from "vue";

export default {
  components: {},
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    // Open Modal
    const openModal = () => {
      $("#featureNotAvailable").modal("show");
    };

    // Close Modal
    const closeModal = () => {
      $("#featureNotAvailable").modal("hide");
    };
    const datatableInit = async () => {
      if ($.fn.DataTable.isDataTable("#dataTable")) {
        $("#dataTable").DataTable().clear().destroy();
      }
      $("#dataTable").addClass("nowrap").dataTable({
        order: [],
        columnDefs: [
          {targets: [1], orderable: false}
        ]
      });
    }
    onMounted(() => {
      datatableInit();
      window.showHideMainLoader(false);
    });

    return {openModal, closeModal, base_url};
  },
};
</script>