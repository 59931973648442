<template>

    <!-- today-meeting -->
    <div class="today-meeting">
      <div class="row">
        <div class="col-sm-6">
          <div class="sorting">
            <div class="form-group">
              <div class="reportrange">
                This Month <i class="fa fa-angle-down" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="create-meeting">
            <a href="#"><img class="img-fluid" src="@/assets/img/pl.svg" alt="image title"> Create Virtual Meeting</a>
          </div>
        </div>
      </div>

      <!-- all-meeting -->
      <div class="all-meeting row">
        <div class="col-md-6 col-lg-6 col-xl-4">
          <div class="single-meeting">
            <h3 class="pink-bar">UI/UX Design
              <a href="#"><img class="img-fluid" src="@/assets/img/setting2.svg" alt="image title"></a>
            </h3>
            <p>11:15 AM - 12:30 PM</p>
            <p>Join</p>
            <div class="person">
              <ul>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval1.svg" alt="image title"></a>
                </li>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval2.svg" alt="image title"></a>
                </li>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval3.svg" alt="image title"></a>
                </li>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval4.svg" alt="image title"></a>
                </li>
                <li><a href="#"><span class="more-person">+9</span></a></li>
              </ul>
            </div>

            <div class="start">
              <a class="copy-link" href="#"><img class="img-fluid" src="@/assets/img/copy.svg" alt="image title"> Copy
                Link</a>
              <a class="start" href="video2.html" data-toggle="modal" data-target="#metting-modal">Start</a>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <div class="single-meeting">
            <h3 class="org-bar">Sale Meeting
              <a href="#"><img class="img-fluid" src="@/assets/img/setting2.svg" alt="image title"></a>
            </h3>
            <p>11:15 AM - 12:30 PM</p>
            <p>Join</p>
            <div class="person">
              <ul>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval1.svg" alt="image title"></a>
                </li>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval2.svg" alt="image title"></a>
                </li>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval3.svg" alt="image title"></a>
                </li>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval4.svg" alt="image title"></a>
                </li>
                <li><a href="#"><span class="more-person">+9</span></a></li>
              </ul>
            </div>

            <div class="start">
              <a class="copy-link" href="#"><img class="img-fluid" src="@/assets/img/copy.svg" alt="image title"> Copy
                Link</a>
              <a class="start" href="video.html" data-toggle="modal" data-target="#metting-modal">Start</a>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <div class="single-meeting">
            <h3 class="grn-bar">Review Web App
              <a href="#"><img class="img-fluid" src="@/assets/img/setting2.svg" alt="image title"></a>
            </h3>
            <p>11:15 AM - 12:30 PM</p>
            <p>Join</p>
            <div class="person">
              <ul>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval1.svg" alt="image title"></a>
                </li>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval2.svg" alt="image title"></a>
                </li>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval3.svg" alt="image title"></a>
                </li>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval4.svg" alt="image title"></a>
                </li>
                <li><a href="#"><span class="more-person">+9</span></a></li>
              </ul>
            </div>

            <div class="start">
              <a class="copy-link" href="#"><img class="img-fluid" src="@/assets/img/copy.svg" alt="image title"> Copy
                Link</a>
              <a class="start" href="video2.html" data-toggle="modal" data-target="#metting-modal">Start</a>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-6 col-xl-4">
          <div class="single-meeting">
            <h3 class="blue-bar">Meeting with ninja…
              <a href="#"><img class="img-fluid" src="@/assets/img/setting2.svg" alt="image title"></a>
            </h3>
            <p>11:15 AM - 12:30 PM</p>
            <p>Join</p>
            <div class="person">
              <ul>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval1.svg" alt="image title"></a>
                </li>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval2.svg" alt="image title"></a>
                </li>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval3.svg" alt="image title"></a>
                </li>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval4.svg" alt="image title"></a>
                </li>
                <li><a href="#"><span class="more-person">+9</span></a></li>
              </ul>
            </div>

            <div class="start">
              <a class="copy-link" href="#"><img class="img-fluid" src="@/assets/img/copy.svg" alt="image title"> Copy
                Link</a>
              <a class="start" href="video.html" data-toggle="modal" data-target="#metting-modal">Start</a>
            </div>
          </div>
        </div>


        <div class="col-md-6 col-lg-6 col-xl-4">
          <div class="single-meeting">
            <h3 class="grn-bar">Review Web App
              <a href="#"><img class="img-fluid" src="@/assets/img/setting2.svg" alt="image title"></a>
            </h3>
            <p>11:15 AM - 12:30 PM</p>
            <p>Join</p>
            <div class="person">
              <ul>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval1.svg" alt="image title"></a>
                </li>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval2.svg" alt="image title"></a>
                </li>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval3.svg" alt="image title"></a>
                </li>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval4.svg" alt="image title"></a>
                </li>
                <li><a href="#"><span class="more-person">+9</span></a></li>
              </ul>
            </div>

            <div class="start">
              <a class="copy-link" href="#"><img class="img-fluid" src="@/assets/img/copy.svg" alt="image title"> Copy
                Link</a>
              <a class="start" href="video2.html" data-toggle="modal" data-target="#metting-modal">Start</a>
            </div>
          </div>
        </div>


        <div class="col-md-6 col-lg-6 col-xl-4">
          <div class="single-meeting">
            <h3 class="org-bar">Sale Meeting
              <a href="#"><img class="img-fluid" src="@/assets/img/setting2.svg" alt="image title"></a>
            </h3>
            <p>11:15 AM - 12:30 PM</p>
            <p>Join</p>
            <div class="person">
              <ul>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval1.svg" alt="image title"></a>
                </li>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval2.svg" alt="image title"></a>
                </li>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval3.svg" alt="image title"></a>
                </li>
                <li>
                  <a href="#"><img class="img-fluid" src="@/assets/img/Oval4.svg" alt="image title"></a>
                </li>
                <li><a href="#"><span class="more-person">+9</span></a></li>
              </ul>
            </div>

            <div class="start">
              <a class="copy-link" href="#"><img class="img-fluid" src="@/assets/img/copy.svg" alt="image title"> Copy
                Link</a>
              <a class="start" href="video.html" data-toggle="modal" data-target="#metting-modal">Start</a>
            </div>
          </div>
        </div>


        <!-- <div class="col-md-6 col-lg-6 col-xl-4">
<div class="single-meeting add-meetingss">
<a href="create-meeting.html"><img class="img-fluid" src="@/assets/img/add.svg" alt="image title"></a>
</div>
</div> -->


      </div>
      <!-- all-meeting -->
    </div>
    <!-- today-meeting -->

</template>
<script>
import $ from "jquery";
import {onMounted} from "vue";

export default {
  components: {

  },
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    // Open Modal
    const openModal = () => {
      $("#featureNotAvailable").modal("show");
    };

    // Close Modal
    const closeModal = () => {
      $("#featureNotAvailable").modal("hide");
    };

    onMounted(() => {
      window.showHideMainLoader(false);
    });

    return {openModal, closeModal, base_url};
  },
};
</script>