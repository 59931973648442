<template>

    <!-- today-meeting -->
    <div class="today-meeting">
      <div class="row">
        <div class="col-sm-6">
          <div class="sorting">
            <div class="reportrange">
              This Month <i class="fa fa-angle-down" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="create-meeting">
            <a href="create-meeting.html"><img class="img-fluid" src="@/assets/img/pl.svg" alt="image title"> Create
              Virtual Meeting</a>
          </div>
        </div>
      </div>

      <!-- new-scheduler -->
      <div class="new-scheduler">

        <!-- schedulers -->
        <div class="schedulers table-responsive">
          <table id="demo-table" class="table">
            <thead>
            <tr>
              <th>08:00 AM</th>
              <th>09:00 AM</th>
              <th>10:00 AM</th>
              <th>11:00 AM</th>
              <th>12:00 PM</th>
            </tr>
            </thead>
            <tbody>
            <td id='column-header-1'>
              <div id='column-header-1-sizer'>
                <div class="scheduler-box">
                  <ul class="schedubox connectedSortable">
                    <li class="ui-state-default">
                      <a data-toggle="modal" data-target="#exampleModal4" href="#" class="appogrid">
                        <h3 class="pink-bar">UI/UX Design</h3>
                        <p>Thomas Merlin</p>
                        <h5>08:00</h5>
                      </a>
                    </li>
                    <li class="ui-state-default">
                      <a data-toggle="modal" data-target="#exampleModal4" href="#" class="appogrid">
                        <h3 class="org-bar">Sale Meeting</h3>
                        <p>Thomas Merlin</p>
                        <h5>08:30</h5>
                      </a>
                    </li>
                    <li class="ui-state-default">
                      <a data-toggle="modal" data-target="#exampleModal4" href="#" class="appogrid">
                        <h3 class="grn-bar">Review Web App</h3>
                        <p>Thomas Merlin</p>
                        <h5>08:45</h5>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
            <td id='column-header-2'>
              <div id='column-header-2-sizer'>
                <div class="scheduler-box">
                  <ul class="schedubox connectedSortable">
                    <li class="ui-state-default">
                      <a data-toggle="modal" data-target="#exampleModal4" href="#" class="appogrid">
                        <h3 class="blue-bar">Meeting With Ninja… </h3>
                        <p>Thomas Merlin</p>
                        <h5>09:00</h5>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
            <td id='column-header-3'>
              <div id='column-header-3-sizer'>

                <div class="scheduler-box">
                  <ul class="schedubox connectedSortable">
                    <li class="ui-state-default">
                      <a data-toggle="modal" data-target="#exampleModal4" href="#" class="appogrid">
                        <h3 class="pink-bar">UI/UX Design</h3>
                        <p>Thomas Merlin</p>
                        <h5>08:00</h5>
                      </a>
                    </li>
                    <li class="ui-state-default">
                      <a data-toggle="modal" data-target="#exampleModal4" href="#" class="appogrid">
                        <h3 class="grn-bar">Review Web App</h3>
                        <p>Thomas Merlin</p>
                        <h5>08:45</h5>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
            <td id='column-header-4'>
              <div id='column-header-4-sizer'>

                <div class="scheduler-box">
                  <ul class="schedubox connectedSortable">
                    <li class="ui-state-default">
                      <a data-toggle="modal" data-target="#exampleModal4" href="#" class="appogrid">
                        <h3 class="pink-bar">UI/UX Design</h3>
                        <p>Thomas Merlin</p>
                        <h5>08:00</h5>
                      </a>
                    </li>
                    <li class="ui-state-default">
                      <a data-toggle="modal" data-target="#exampleModal4" href="#" class="appogrid">
                        <h3 class="org-bar">Sale Meeting</h3>
                        <p>Thomas Merlin</p>
                        <h5>08:30</h5>
                      </a>
                    </li>
                    <li class="ui-state-default">
                      <a data-toggle="modal" data-target="#exampleModal4" href="#" class="appogrid">
                        <h3 class="grn-bar">Review Web App</h3>
                        <p>Thomas Merlin</p>
                        <h5>08:45</h5>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
            <td id='column-header-5'>
              <div id='column-header-5-sizer'>

                <div class="scheduler-box">
                  <ul class="schedubox connectedSortable">
                    <li class="ui-state-default">
                      <a data-toggle="modal" data-target="#exampleModal4" href="#" class="appogrid">
                        <h3 class="pink-bar">UI/UX Design</h3>
                        <p>Thomas Merlin</p>
                        <h5>08:00</h5>
                      </a>
                    </li>
                    <li class="ui-state-default">
                      <a data-toggle="modal" data-target="#exampleModal4" href="#" class="appogrid">
                        <h3 class="org-bar">Sale Meeting</h3>
                        <p>Thomas Merlin</p>
                        <h5>08:30</h5>
                      </a>
                    </li>
                    <li class="ui-state-default">
                      <a data-toggle="modal" data-target="#exampleModal4" href="#" class="appogrid">
                        <h3 class="grn-bar">Review Web App</h3>
                        <p>Thomas Merlin</p>
                        <h5>08:45</h5>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </td>

            </tbody>
          </table>

        </div>
        <!-- schedulers -->

      </div>
      <!-- new-scheduler -->

    </div>
    <!-- today-meeting -->

</template>
<script>
import $ from "jquery";
 import {onMounted} from "vue";

export default {
  components: {

  },
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    // Open Modal
    const openModal = () => {
      $("#featureNotAvailable").modal("show");
    };

    // Close Modal
    const closeModal = () => {
      $("#featureNotAvailable").modal("hide");
    };

    onMounted(() => {
      window.showHideMainLoader(false);
    });

    return {openModal, closeModal, base_url};
  },
};
</script>