<template>
<div class="homepage-streamly">
    <!-- loader -->
    <Loader />
    <!-- loader -->
    <!-- header -->
    <header class="headers-area">
        <div class="containers-landingpage">
            <div class="row">

                <div class="navigation">
                    <nav class="navbar navbar-expand-xl">
                        <div class="container-fluid p-0">
                            <router-link to="/" class="navbar-brand">
                                <img class="img-fluid fixdlogo" src="@/assets/landing/img/logo.png" alt="Logo">
                                <img class="img-fluid scrlogo" src="@/assets/landing/img/logo-blue.png" alt="Logo">
                            </router-link>
                            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"><i class="bi bi-text-right"></i></span>
                          </button>
                            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li class="nav-item dropdown">
                                        <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false" @click="removeLoader">
                                            Solutions
                                        </a>
                                        <div class="dropdown-menu mega-menu">
                                            <ul class="drop-list">
                                                <li>
                                                    <router-link to="/services/crm" @click="removeLoader">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <img src="@/assets/landing/img/icon1.png" alt="icon">
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h4>CRM</h4>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li>
                                                <li>
                                                    <router-link to="/services/email-marketing" @click="removeLoader">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <img src="@/assets/landing/img/icon2.png" alt="icon">
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h4>Email Marketing</h4>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li>
                                                <li>
                                                    <router-link to="/services/text-marketing" @click="removeLoader">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <img src="@/assets/landing/img/icon3.png" alt="icon">
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h4>Text Marketing</h4>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li>

                                            </ul>
                                            <ul class="drop-list">
                                                <li>
                                                    <router-link to="/services/reputation-management" @click="removeLoader">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <img src="@/assets/landing/img/icon4.png" alt="icon">
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h4>Reputation Management</h4>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li>
                                                <li>
                                                    <router-link to="/services/marketing-integrations" @click="removeLoader">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0">
                                                                <img src="@/assets/landing/img/icon5.png" alt="icon">
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h4>Marketing Integrations</h4>
                                                            </div>
                                                        </div>
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#about" @click="removeLoader">About</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#faq" @click="removeLoader">Faq</a>
                                    </li>
                                    <li class="nav-item">
                                        <router-link class="nav-link login" :to="{ name: 'Login' }" @click="removeLoader">Login</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>
    <!-- header -->

   <!-- main-content -->
   <main class="main-content">
        <!-- hero-banner -->
        <section class="hero-banner">
            <div class="containers-landingpage">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="title">
                            <h1>Elevate Your Business with Streamly Solutions</h1>
                            <p>Track and optimize routes in real-time </p>
                            <div class="btns">
                                <a href="#" @click="removeLoader">Request a Demo</a>
                                <a href="#" @click="removeLoader"><i class="bi bi-play-circle-fill"></i> Watch the video</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="banner-img">
                            <img class="img-fluid" src="@/assets/landing/img/hero-banner.png" alt="banner">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- hero-banner -->

        <slot />

    </main>
    <!-- main-content -->


    <!-- footer -->
    <footer class="footer-area">
        <div class="containers-landingpage">
            <div class="row">
                <div class="col-lg-5">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="footer-grid">
                                <img class="img-fluid" src="@/assets/landing/img/logo-blue.png" alt="logo-blue">
                                <ul>
                                    <li><a href="tel: 949-923-0190" @click="removeLoader"><i class="bi bi-telephone-fill"></i> 949-923-0190</a></li>
                                    <li><a href="#" @click="removeLoader"><i class="bi bi-envelope-fill"></i> info@dealersgear.com</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-5 fgrids">
                            <div class="footer-grid">
                                <h3>Product</h3>
                                <ul>
                                    <li><a href="#" @click="removeLoader">Features</a></li>
                                    <li><a href="#" @click="removeLoader">API</a></li>
                                    <li><a href="#" @click="removeLoader">Solutions</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="row">
                        <div class="col-md-4 fgrids">
                            <div class="footer-grid">
                                <h3>Company</h3>
                                <ul>
                                    <li><a href="#" @click="removeLoader">About Us</a></li>
                                    <li><a href="#" @click="removeLoader">Blog</a></li>
                                    <li><a href="#" @click="removeLoader">Contact us </a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4 fgrids">
                            <div class="footer-grid">
                                <h3>Support</h3>
                                <ul>
                                    <li><a href="#" @click="removeLoader">Support center</a></li>
                                    <li><a href="#" @click="removeLoader">System status </a></li>
                                    <li><a href="#" @click="removeLoader">Languages </a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-4 fgrids">
                            <div class="footer-grid">
                                <h3>Follow us</h3>
                                <ul class="social">
                                    <li><a href="#" @click="removeLoader"><i class="bi bi-facebook"></i></a></li>
                                    <li><a href="#" @click="removeLoader"><i class="bi bi-linkedin"></i></a></li>
                                    <li><a href="#" @click="removeLoader"><i class="bi bi-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="footer-bottom">
                    <div class="row">
                        <div class="col-md-6">
                            <ul>
                                <li><a href="#" @click="removeLoader">Privacy Policy</a></li>
                                <li><a href="#" @click="removeLoader">Our Terms</a></li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <div class="copy">
                                <p>Copyright © 2024 DealersGear</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <a href="#" class="scrolltotop"><i class="bi bi-arrow-up-circle"></i></a>
        </div>
    </footer>
    <!-- footer -->
</div>
</template>

<style scoped>
@import "../assets/landing/css/style.css";
@import "../assets/landing/css/responsive.css";
</style>

<script>
import $ from "jquery";
import {
    onMounted
} from "vue";
// import axios from "axios";
// import { useRoute } from "vue-router";
import Loader from "@/components/Loader";
export default {
    components: {
        Loader
    },
    setup() {
        // let base_url = "http://api.streamly.com/uploads/";

        $(document).ready(function () {
            // loader
            setTimeout(function () {
                $('.loaders').fadeOut();
            }, 100);

            // scroll-Top
            $(window).scroll(function () {
                if ($(this).scrollTop() > 500) {
                    $('.scrolltotop').fadeIn();
                } else {
                    $('.scrolltotop').fadeOut();
                }

            });

            $('.scrolltotop').click(function () {
                $('html,body').animate({
                    scrollTop: 0
                }, 1000);
                return false;
            });

            // fixedtop
            $(window).scroll(function () {
                var headerTopHeight = $(".headers-area").outerHeight();
                var totalHeight = headerTopHeight;
                var utd = $(window).scrollTop();

                if (utd > totalHeight) {
                    $(".headers-area").addClass("shadows");
                } else {
                    $(".headers-area").removeClass("shadows");
                }

                return false;
            });
        });
        const removeLoader = () => {
            window.showHideMainLoader(false);
        };
        onMounted(() => {
            window.showHideMainLoader(false);
        });

        return {
            removeLoader,
        };
    },
};
</script>
