<template>
  <div class="dealership-form">
    <form class="myform">
      <div class="modal-body">
        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="trackingNumberFromDB">
              <p class="label-txt label-active" style="z-index: 1;">Twilio Phone Number</p>
              <input readonly v-if="editMode" id="trackingNumberFromDB" type="text" class="input"
              placeholder="(xxx) xxx-xxxx" />
              <SimpleTypeahead v-else placeholder="Enter area code e.g 909" id="typeahead_id" class="input"
              :items="searchedTrackingNumbers" :minInputLength="3" @selectItem="selectedTrackingNumber"
              @onInput="searchTrackingNumber">
            </SimpleTypeahead>
            </label>
          </div>
          <div class="form-group col-md-6" style="display:none;">
            <label>Redirect to</label>
            <input type="text" class="form-control" placeholder="(xxx) xxx-xxxx" v-model="redirectTo" />
          </div>
          <div class="form-group col-md-6" v-if="editMode">
            <label>Send Email</label>
            <select id="sendEmail" class="form-control select2 tags2" v-model="sendEmail" required>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div class="form-group col-md-6" v-if="editMode">
            <label>Recording Consent</label>
            <select id="recordingConsent" class="form-control select2 tags2" v-model="recordingConsent" required>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>
<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import SimpleTypeahead from "vue3-simple-typeahead";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";
export default {
  components: {
    SimpleTypeahead,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    console.log(route.params);
    const dealerId = route.params.id;
    // const type = route.params.id;
    let searchedTrackingNumbers = ref([]);
    // let trackingNumberFromDB = ref("");
    let redirectTo = ref("");
    let sendEmail = ref("yes");
    let recordingConsent = ref(1);
    // let numberId = ref("");
    let editMode = ref(false);

    const searchTrackingNumber = async (number) => {
      if (number.input.length >= 3) {
        number.dealer_id = dealerId;
        window.showHideMainLoader(true);
        await axios
          .post(
            axios.defaults.baseUrl + "admin/search_number_for_client",
            number,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("auth_token"),
              },
            })
          .then((response) => {
            window.showHideMainLoader(false);
            if (response.data.status == 'success') {
              searchedTrackingNumbers.value = response.data.data;
            } else {
              showToast(response.data.status, response.data.message);
            }
          })
          .catch((error) => {
            window.showHideMainLoader(false);
            showToast(error.response.data.status, error.response.data.message);
            return false;
          });
        window.showHideMainLoader(false);
      }
    };

    const selectedTrackingNumber = async (selectedNumber) => {
      window.showHideMainLoader(true);
      await axios
        .post(
          axios.defaults.baseUrl + "admin/buy_number_for_client", {
          selectedNumber,
          dealerId
        }, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          window.showHideMainLoader(false);
          console.log(response);
          showToast(response.data.status, response.data.message);
          // getTwilioTrackingNumber();
          router.push("/admin/clients");
        })
        .catch((error) => {
          window.showHideMainLoader(false);
          showToast(error.response.data.status, error.response.data.message);
          return false;
        });
    }
    /*
        const getTwilioTrackingNumber = async () => {
          window.showHideMainLoader(true);
          await axios
            .post(axios.defaults.baseUrl + "getTwilioTrackingNumber", {
              type,
              dealerId
            }, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("auth_token"),
              },
            })
            .then((response) => {
              if (response.data.status == 'success') {
                editMode.value = true;
                numberId.value = response.data.data.id;
                redirectTo.value = response.data.data.redirect_to;
                sendEmail.value = response.data.data.send_email;
                recordingConsent.value = response.data.data.recording_consent;
                trackingNumberFromDB.value = response.data.data.twilio_phone_friendly;
                setTimeout(() => {
                  $("#trackingNumberFromDB").val(response.data.data.twilio_phone_friendly);
                });
                $("#sendEmail")
                  .select2()
                  .val(sendEmail.value)
                  .trigger("change");
                $("#recordingConsent")
                  .select2()
                  .val(recordingConsent.value)
                  .trigger("change");
              } else {
                editMode.value = false;
              }
            })
            .catch((error) => {
              if (error) {
                window.toast.fire({
                  icon: "error",
                  title: "Something went wrong, Please try again.",
                });
              }
            });
          window.showHideMainLoader(false);
        }
    */
    /*
     const updateTrackingNumber = async () => {
       window.showHideMainLoader(true);
       let redirectToNumber = redirectTo.value;
       let sendEmailCheck = sendEmail.value;
       let recordingConsentCheck = recordingConsent.value;
       let id = numberId.value;
       await axios
         .post(axios.defaults.baseUrl + "updateTrackingNumber", {
           redirectToNumber,
           type,
           dealerId,
           sendEmailCheck,
           id,
           recordingConsentCheck
         }, {
           headers: {
             Authorization: "Bearer " + localStorage.getItem("auth_token"),
           },
         })
         .then((response) => {
           console.log(response);
           window.toast.fire({
             icon: "success",
             title: "Tracking number updated successfully",
           });
         })
         .catch((error) => {
           if (error) {
             window.toast.fire({
               icon: "error",
               title: "Something went wrong, Please try again.",
             });
           }
         });
       window.showHideMainLoader(false);
     }
 */
    const addClass = (elementName, className) => {
      $(elementName).addClass(className);
    }

    const removeClass = (elementName, className) => {
      $(elementName).removeClass(className);
    }
    const showToast = (toastIcon, toastMessage) => {
      window.toast.fire({
        icon: toastIcon,
        title: toastMessage,
      });
    }

    onMounted(() => {
      // getTwilioTrackingNumber();
      $(".select2").select2({
        placeholder: "Select",
        allowClear: true,
      });
      window.showHideMainLoader(false);
    });
    return {
      searchTrackingNumber,
      searchedTrackingNumbers,
      selectedTrackingNumber,
      redirectTo,
      sendEmail,
      recordingConsent,
      editMode,
      // updateTrackingNumber,
      removeClass,
      addClass,
      showToast,
    };
  },
};
</script>