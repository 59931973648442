<template>
  <!-- review-table -->
  <div class="review-table">
    <table class="display" id="dataTable">
      <thead>
        <tr>
            <th>Campaign</th>
            <th>Customer</th>
            <th>Sent At</th>
            <th>Status</th>
            <th>Message</th>
          </tr>
      </thead>
      <tbody>
        <tr v-for="Message in AllMessages" :key="Message">
            <td>{{ Message.campaign_name }}</td>
            <td>{{ Message.customer_name }}</td>
            <td>{{ Message.sent_at }}</td>
            <td>{{ Message.message_status }} </td>
            <td>{{ Message.message }}</td>
          </tr>
      </tbody>
    </table>
  </div>
  <!-- review-table -->
</template>
<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import axios from "axios";
// import { useRoute } from "vue-router";
// import vueMask from 'vue-jquery-mask';
// console.log(localStorage);
export default {
  components: {},
  setup() {
    let base_url = "http://api.streamly.com/uploads/";
    // const Params = useRoute();
    // let AllCustomersLists = ref([]);
    let AllMessages = ref([]);
    // console.log(localStorage.getItem('auth_token'));

    const getMessages = async () => {
      await axios
        .get(
          axios.defaults.baseUrl + "admin/messages",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem('auth_token'),
            },
          }
        )
        .then((response) => {
          AllMessages.value = response.data.data.allMessages;
          // datatableInit();
        })
        .catch((error) => {
          window.showHideMainLoader(false);
          showToast(error.response.data.status, error.response.data.message);
          return false;
        });
    }
    const addClass = (elementName, className) => {
      $(elementName).addClass(className);
    }

    const removeClass = (elementName, className) => {
      $(elementName).removeClass(className);
    }
    const showToast = (toastIcon, toastMessage) => {
      window.toast.fire({
        icon: toastIcon,
        title: toastMessage,
      });
    }

    // Open Modal
    const openModal = () => {

    };

    // Close Modal
    const closeModal = () => {

    };
    const datatableInit = async () => {
      if ($.fn.DataTable.isDataTable("#dataTable")) {
        $("#dataTable").DataTable().clear().destroy();
      }
      $("#dataTable").addClass("nowrap").dataTable({
        order: [],
        columnDefs: [
          { targets: [0], orderable: false },
          { targets: [1], orderable: false },
          { targets: [2], orderable: false },
          { targets: [3], orderable: false },
          { targets: [4], orderable: false, "width": "20%" },
          { targets: [5], orderable: false }
        ]
      });
      $(".display").addClass("nowrap").dataTable();

    }
    onMounted(() => {

      // console.log(Params);
      datatableInit();
      getMessages();
      window.showHideMainLoader(false);
    });

    return {
      openModal,
      closeModal,
      base_url,
      AllMessages,
      removeClass,
      addClass,
      showToast,
    };
  },
};
</script>