<template>
<!-- search-top -->
<div class="search-top">
    <div class="row">
        <div class="col-md-12">
            <div class="export-right">
                <div class="export">
                    <router-link :to="{ name: 'AdminTagsAdd' }"><img class="img-fluid" src="@/assets/img/pl.svg" alt="image title"> Add New Tag</router-link>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- search-top -->
<!-- review-table -->
<div class="review-table">
    <table class="display" id="dataTable">
        <thead>
            <tr>
                <th>Tag Name</th>
                <th>Customers</th>
                <th>Status</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="Tag in AllTags" :key="Tag">
                <td>{{ Tag.tag }}</td>
                <td>{{ Tag.total_customers }}</td>
                <td>{{ Tag.status }}</td>
                <td>
                    <a href="javascript:;" class="invites" @click="DeleteTag(Tag)">Delete Tag</a>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<!-- review-table -->
</template>

<script>
import $ from "jquery";
import {
    ref,
    onMounted
} from "vue";
import axios from "axios";
// import vueMask from 'vue-jquery-mask';
// console.log(localStorage);
export default {
    components: {},
    setup() {
        let base_url = "http://api.getstreamly.com/uploads/";
        let AllTags = ref([]);
        let filterBy = ref('');
        const addClass = (elementName, className) => {
            $(elementName).addClass(className);
        }
        const removeClass = (elementName, className) => {
            $(elementName).removeClass(className);
        }
        const showToast = (toastIcon, toastMessage) => {
            window.toast.fire({
                icon: toastIcon,
                title: toastMessage,
            });
        }
        // Open Modal
        const openModal = () => {
            $("#featureNotAvailable").modal("show");
        };
        // Close Modal
        const closeModal = () => {
            $("#create_contact").modal("hide");
            $("#upload_contacts").modal("hide");
            $("#disply_csv_uploaded_contact").modal("hide");
        };
        const getAllTags = async (filter_by = '') => {
            if ($.fn.DataTable.isDataTable("#dataTable")) {
                $("#dataTable").DataTable().clear().destroy();
            }
            $('#dataTable').DataTable({
                "processing": true,
                "serverSide": true,
                language: {
                    emptyTable: "No matching records found",
                    lengthMenu: "Show _MENU_ records per page",
                    zeroRecords: "",
                    info: "Showing _START_ to _END_ of _TOTAL_ records",
                    infoEmpty: "No records available",
                    infoFiltered: "(filtered from _MAX_ total records)",
                },
                "ajax": {
                    "url": axios.defaults.baseUrl + "admin/tags/dt",
                    "type": "POST", // Assuming your API endpoint expects a POST request
                    "headers": {
                        Authorization: "Bearer " + localStorage.getItem('auth_token'),
                    },
                    "data": function (d) {
                        // You can add additional parameters or modify the request data here
                        return $.extend({}, d, {
                            'filterBy': filter_by,
                        });
                    },
                    dataSrc: function (response) {
                        // Transform the data here before DataTables processes it
                        AllTags.value = response.data;
                        return [];
                    },
                },
                "searching": true,
                "order": [],
                "columns": [],
            });
            $(".display").addClass("nowrap").dataTable();
        }
        const DeleteTag = async (TagData = null) => {
            // console.log(passwordChange.value);
            const tagFormData = new FormData();
            tagFormData.append('id', TagData.id);
            await axios
                .post(
                    axios.defaults.baseUrl + "admin/tags/delete",
                    tagFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    showToast(response.data.status, response.data.message);
                    getAllTags();
                    // location.reload(); //----remove later
                    // AllCustomersLists.value = response.data.data.allCustomersLists;
                    // datatableInit();
                })
                .catch((error) => {
                    // window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        onMounted(() => {
            getAllTags();
            window.showHideMainLoader(false);
        });

        return {
            openModal,
            closeModal,
            base_url,
            getAllTags,
            AllTags,
            DeleteTag,
            removeClass,
            addClass,
            showToast,
            filterBy,
        };
    },
};
</script>
