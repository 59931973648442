<template>
    <div>
        <!-- loader -->
        <Loader />
        <!-- loader -->
        <!-- header -->
        <header class="simple-header">
            <div class="containerss">
                <div class="row">
                    <div class="col-6">
                        <div class="logos">
                            <router-link to="/dashboard">
                                <img class="img-fluid small-logo" src="@/assets/img/logo-s.svg" alt="logo">
                                <img class="img-fluid big-logo" src="@/assets/img/logo.svg" alt="logo">
                            </router-link>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="top-close">
                            <ul>
                                <li>
                                    <a href="javascript:;"><img class="img-fluid" src="@/assets/register/img/rotate.png"
                                            alt="rotate"></a>
                                </li>
                                <li>
                                    <a href="javascript:;"><img class="img-fluid" src="@/assets/register/img/close.png"
                                            alt="close"></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <!-- header -->
        <!-- main-content -->
        <main class="main-content">

            <!-- simple-area -->
            <section class="simple-area">
                <div class="containerss">
                    <div class="row">

                        <div class="col-md-10 col-lg-10 offset-md-1 offset-lg-1">
                            <div class="simple-steps">
                                <h1>It’s As Simple As</h1>
                                <form id="msform">
                                    <!-- progressbar -->
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100">
                                        </div>
                                    </div> <br>


                                    <!-- fieldsets -->

                                    <fieldset>
                                        <h2 class="steps"> 1… 2… 3 </h2>
                                        <div class="form-card">
                                            <h2>Business Address</h2>
                                            <div class="row justify-content-md-center">
                                                <div class="col-lg-5">
                                                    <div class="row g-3">
                                                        <div class="col-md-12">
                                                            <div class="form-floating">
                                                                <input type="text" class="form-control" id="location_title"
                                                                    placeholder="Location Title"
                                                                    v-model="wizard_form.location_title"
                                                                    @click="removeClass('#location_title', 'red-border')">
                                                                <label for="location_title">Location Title</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-floating">
                                                                <input type="text" class="form-control"
                                                                    id="street_address_1" placeholder="Street Address 1"
                                                                    v-model="wizard_form.street_address_1"
                                                                    @click="removeClass('#street_address_1', 'red-border')">
                                                                <label for="street_address_1">Street Address 1</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-floating">
                                                                <input type="text" class="form-control"
                                                                    id="street_address_2" placeholder="Street Address 2"
                                                                    v-model="wizard_form.street_address_2">
                                                                <label for="street_address_2">Street Address 2</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-floating">
                                                                <input type="text" class="form-control" id="city"
                                                                    placeholder="City" v-model="wizard_form.city"
                                                                    @click="removeClass('#city', 'red-border')">
                                                                <label for="city">City</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-floating">
                                                                <input type="text" class="form-control" id="state"
                                                                    placeholder="State" v-model="wizard_form.state"
                                                                    @click="removeClass('#state', 'red-border')">
                                                                <label for="state">State</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-floating">
                                                                <input type="text" class="form-control" id="country"
                                                                    placeholder="Country" value="US" readonly>
                                                                <label for="country">Country</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-floating">
                                                                <input type="text" class="form-control" id="postal_code"
                                                                    placeholder="Postal Code"
                                                                    v-model="wizard_form.postal_code"
                                                                    @click="removeClass('#postal_code', 'red-border')">
                                                                <label for="postal_code">Postal Code</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-5">
                                                    <iframe
                                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3320.0999365972375!2d-117.8583778849317!3d33.68047638070877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dccb1b8d3bb587%3A0x757863673f3b87a3!2sDealersGear!5e0!3m2!1sen!2s!4v1679824241057!5m2!1sen!2s"
                                                        width="100%" height="350" style="border:0;" allowfullscreen=""
                                                        loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="btnsfrom">
                                            <button type="button" name="next" class="next action-button"
                                                data-step="1">Next</button>
                                        </div>


                                    </fieldset>
                                    <fieldset>
                                        <h2 class="steps"> 2 </h2>
                                        <div class="form-card">
                                            <h2>Choose Your Industry</h2>
                                            <div class="industry industry-list">
                                                <ul>
                                                    <li v-for="Industry in AllIndustries" :key="Industry">
                                                        <a href="javascript:;" @click="changeIndustry"
                                                            :data-industry_id="Industry.id">
                                                            <div class="name">
                                                                <img class="img-fluid"
                                                                    src="@/assets/register/img/customer-relations.png"
                                                                    :alt="Industry.slug">
                                                                <p>{{ Industry.title }}</p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="btnsfrom">
                                            <button type="button" name="previous" class="previous action-button-previous"
                                                data-step="3"><i class="fa fa-angle-left" aria-hidden="true"></i>
                                                Back</button>
                                            <button type="button" name="next" class="next action-button"
                                                data-step="2">Next</button>
                                        </div>

                                    </fieldset>

                                    <fieldset>
                                        <h2 class="steps"> 3 </h2>
                                        <div class="form-card">
                                            <h2>Choose Your Feature</h2>
                                            <div class="industry feature-list">
                                                <ul>
                                                    <li v-for="Feature in AllFeatures" :key="Feature">
                                                        <a href="javascript:;" @click="changeFeature"
                                                            :data-feature_id="Feature.id">
                                                            <div class="name">
                                                                <img class="img-fluid"
                                                                    src="@/assets/register/img/customer-relations.png"
                                                                    :alt="Feature.slug">
                                                                <!-- :src="'@/assets/register/img/' + Feature.icon" -->
                                                                <p>{{ Feature.title }}</p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="btnsfrom">
                                            <button type="button" name="previous" class="previous action-button-previous"
                                                data-step="3"><i class="fa fa-angle-left" aria-hidden="true"></i>
                                                Back</button>
                                            <button type="button" name="next" class="next action-button"
                                                data-step="3">Next</button>
                                        </div>
                                    </fieldset>

                                    <fieldset>
                                        <h2 class="steps"> 3 </h2>
                                        <div class="form-card">
                                            <h2>Free Trial</h2>
                                            <div class="row justify-content-md-center">
                                                <div class="col-lg-6">
                                                    <div class="row g-3">
                                                        <p>You will be given 30 days free trial. You will be able to use all
                                                            features until the trial ends. </p>
                                                        <!-- <div class="col-md-12">
                                                            <div class="form-floating">
                                                                <input type="text" class="form-control" id="Card"
                                                                    placeholder="Card number">
                                                                <label for="Card">Card number</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-floating">
                                                                <input type="date" class="form-control" id="date">
                                                                <label for="date">Expiry date</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-floating">
                                                                <input type="text" class="form-control" id="CVC"
                                                                    placeholder="CVC">
                                                                <label for="CVC">CVC/CVV</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-floating">
                                                                <input type="text" class="form-control" id="holder"
                                                                    placeholder="Card holder name">
                                                                <label for="holder">Card holder name</label>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="btnsfrom">
                                            <button type="button" name="previous" class="previous action-button-previous"><i
                                                    class="fa fa-angle-left" aria-hidden="true"></i> Back</button>
                                            <button type="button" name="next" class="next action-button"
                                                @click="RegisterUser">Start Free Trial</button>
                                        </div>


                                    </fieldset>
                                    <fieldset>
                                        <div class="form-card">
                                            <h2>Done!</h2>
                                            <h3>Thank You</h3>
                                            <p class="text-center">Your will be redirected to your dashboard shortly.</p>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- simple-area -->
        </main>
        <!-- main-content -->
        <!-- footer -->
        <footer class="footer-area">
            <!-- <div class="footer">
            <img class="img-fluid lazy" data-src="assets/img/footer-bg.png" alt="footer bg">
        </div> -->
            <div class="col-12">
                <a href="javascript:;" class="scrolltotop"><i class="fa fa-angle-up"></i></a>
            </div>
        </footer>
        <!-- footer -->
    </div>
</template>
<script>
import $ from "jquery";
import { ref, onMounted } from "vue";
import axios from "axios";
// import { useRoute } from "vue-router";
import Loader from "@/components/Loader";
export default {
    components: { Loader },
    setup() {
        let base_url = "http://api.streamly.com/uploads/";
        // const Params = useRoute();
        // console.log(Params);
        let AllIndustries = ref([]);
        let AllFeatures = ref([]);
        let wizard_form = ref({
            industry_id: 0,
            feature_id: 0,
            location_title: '',
            street_address_1: '',
            street_address_2: '',
            city: '',
            state: '',
            country: '',
            postal_code: ''
        });

        const getIndustries = async () => {
            await axios
                .get(axios.defaults.baseUrl + "industries",
                    {
                        headers: {
                            // Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    AllIndustries.value = response.data.data.allIndustries;
                })
        }
        const getFeatures = async () => {
            await axios
                .get(axios.defaults.baseUrl + "features",
                    {
                        headers: {
                            // Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    AllFeatures.value = response.data.data.allFeatures;
                })
        }
        const RegisterUser = async () => {
            let registerFormData = new FormData();
            registerFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            registerFormData.append('location_id', 0);
            registerFormData.append('location', wizard_form.value.location_title);
            registerFormData.append('industry_id', wizard_form.value.industry_id);
            registerFormData.append('feature_id', wizard_form.value.feature_id);
            registerFormData.append('title', wizard_form.value.location_title);
            registerFormData.append('address', wizard_form.value.street_address_1);
            registerFormData.append('address_2', wizard_form.value.street_address_2);
            registerFormData.append('city', wizard_form.value.city);
            registerFormData.append('state', wizard_form.value.state);
            registerFormData.append('country', wizard_form.value.country);
            registerFormData.append('postal_code', wizard_form.value.postal_code);
            window.showHideMainLoader(true);
            await axios
                .post(
                    axios.defaults.baseUrl + "register-complete",
                    registerFormData,
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    window.showHideMainLoader(false);
                    showToast(response.data.status, response.data.message);
                    wizard_form.value = '';

                    localStorage.setItem("location_id", response.data.data.location_id);
                    localStorage.setItem("location", response.data.data.location_title);
                    localStorage.setItem("location_address", response.data.data.location_address);
                    localStorage.setItem("business_name", response.data.data.business_name);
                    setTimeout(function () {
                        window.location.href = '/dashboard';
                    }, 5000);
                })
                .catch((error) => {
                    window.showHideMainLoader(false);
                    showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }

        const changeIndustry = (event) => {
            let $this = $(event.target);
            // $('.wizard-form-alert1').hide();
            window.showHideMainLoader(false);
            $('.industry-list li a').removeClass("active");
            $this.addClass("active");
            let this_industry_id = $this.data('industry_id');
            // console.log(this_industry_id);
            wizard_form.value.industry_id = this_industry_id;
            return false;
        };

        const changeFeature = (event) => {
            let $this = $(event.target);
            // $('.wizard-form-alert2').hide();
            window.showHideMainLoader(false);
            $('.feature-list li a').removeClass("active");
            $this.addClass("active");
            let this_feature_id = $this.data('feature_id');
            // console.log(this_industry_id);
            wizard_form.value.feature_id = this_feature_id;
            return false;
        };



        $(function () {

            var current_fs, next_fs, previous_fs; //fieldsets
            var opacity;
            var current = 1;
            var steps = $("fieldset").length;

            setProgressBar(current);

            $(".next").click(function (event) {
                let $step_no = $(event.target).data('step');
                removeClass('.form-control', 'red-border');
                // console.log(wizard_form.value);
                // console.log($step_no);
                let validate = 1;
                console.log(validate);
                if ($step_no == 1) {
                    if (wizard_form.value.location_title == '') {
                        validate = 0;
                        addClass('#location_title', 'red-border');
                        showToast('error', 'Location title is required');
                        return false;
                    }
                    if (wizard_form.value.street_address_1 == '') {
                        validate = 0;
                        addClass('#street_address_1', 'red-border');
                        showToast('error', 'Street Address is required');
                        return false;
                    }
                    if (wizard_form.value.city == '') {
                        validate = 0;
                        addClass('#city', 'red-border');
                        showToast('error', 'City is required');
                        return false;
                    }
                    if (wizard_form.value.state == '') {
                        validate = 0;
                        addClass('#state', 'red-border');
                        showToast('error', 'State is required');
                        return false;
                    }
                    if (wizard_form.value.postal_code == '') {
                        validate = 0;
                        addClass('#postal_code', 'red-border');
                        showToast('error', 'Postal Code is required');
                        return false;
                    }
                }

                if ($step_no == 2) {
                    if (wizard_form.value.industry_id == 0) {
                        validate = 0;
                        showToast('error', 'Choose an industry');
                        return false;
                    }
                }

                if ($step_no == 3) {
                    if (wizard_form.value.feature_id == 0) {
                        validate = 0;
                        showToast('error', 'Choose a feature');
                        return false;
                    }
                }

                current_fs = $(this).parent().parent();
                next_fs = $(this).parent().parent().next();

                //Add Class Active
                $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

                //show the next fieldset
                next_fs.show();
                //hide the current fieldset with style
                current_fs.animate({ opacity: 0 }, {
                    step: function (now) {
                        // for making fielset appear animation
                        opacity = 1 - now;

                        current_fs.css({
                            'display': 'none',
                            'position': 'relative'
                        });
                        next_fs.css({ 'opacity': opacity });
                    },
                    duration: 500
                });
                setProgressBar(++current);

            });

            $(".previous").click(function () {

                current_fs = $(this).parent().parent();
                previous_fs = $(this).parent().parent().prev();

                //Remove class active
                $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

                //show the previous fieldset
                previous_fs.show();

                //hide the current fieldset with style
                current_fs.animate({ opacity: 0 }, {
                    step: function (now) {
                        // for making fielset appear animation
                        opacity = 1 - now;

                        current_fs.css({
                            'display': 'none',
                            'position': 'relative'
                        });
                        previous_fs.css({ 'opacity': opacity });
                    },
                    duration: 500
                });
                setProgressBar(--current);
            });

            function setProgressBar(curStep) {
                var percent = parseFloat(100 / steps) * curStep;
                percent = percent.toFixed();
                $(".progress-bar")
                    .css("width", percent + "%")
            }

            $(".submit").click(function () {
                return false;
            })

        });
        const addClass = (elementName, className) => {
            $(elementName).addClass(className);
        }

        const removeClass = (elementName, className) => {
            $(elementName).removeClass(className);
        }
        const showToast = (toastIcon, toastMessage) => {
            window.toast.fire({
                icon: toastIcon,
                title: toastMessage,
            });
        }
        // Open Modal
        const openModal = () => {
        };

        // Close Modal
        const closeModal = () => {
        };

        onMounted(() => {
            $('.wizard-form-alert').hide();
            window.showHideMainLoader(false);
            getIndustries();
            getFeatures();
        });

        return {
            openModal,
            closeModal,
            base_url,
            getIndustries,
            getFeatures,
            wizard_form,
            // register,
            AllIndustries,
            AllFeatures,
            changeIndustry,
            changeFeature,
            RegisterUser,
            addClass,
            removeClass,
            showToast
        };
    },
};
</script>
