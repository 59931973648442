import { createApp } from 'vue'
import App from './App'
import routes from './routes'
const app = createApp(App)
import axios from 'axios'
import { createPinia } from 'pinia'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import {messaging , getToken} from  './firebase';

app.config.globalProperties.$messaging = messaging;
app.config.globalProperties.$getToken = getToken;
// import DataTable from 'datatables.net-vue3'
// import DataTablesLib from 'datatables.net';
// axios.defaults.baseUrl = "http://127.0.0.1:8000/api/"
axios.defaults.baseUrl = "https://api.getstreamly.com/api/"
// const imageUrl = process.env.VUE_APP_BASE_URL
import Swal from 'sweetalert2'
import $ from "jquery";
window.swal = Swal

window.$ = $;
library.add(faSpinner)
// initialize toast alert notification globally
window.toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});
window.confirm = Swal.mixin();

window.userRoleType = localStorage.getItem("user_type");




const pinia = createPinia()
// DataTable.use(DataTablesLib);
app.component('font-awesome-icon', FontAwesomeIcon)
 app.use(pinia).use(routes).mount('#app')
window.AppName = "Streamly"
document.title=window.AppName


app.config.globalProperties.$filters = {
  str_limit(value, size) {
    if (!value) return '';
    value = value.toString();

    if (value.length <= size) {
      return value;
    }
    return value.substr(0, size) + '...';
  }
}

window.showHideMainLoader = async (element) => {
  if(element) {
    $('.loaders').show();
  }else {
    setTimeout(function (){
      $('.loaders').fadeOut();
    },300)

  }
}





routes.beforeEach((to, from, next) => {
  document.title = window.AppName + ' - ' + to.name;
   window.showHideMainLoader(true)
  next();



})